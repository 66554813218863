import React from "react";
import { Box, IconButton } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { STORAGE_BUCKET, IMG_PLACEHOLDER } from "./constants";

const navBtnStyle = {
  position: "absolute",
  zIndex: 2,
  top: "50%",
  transform: "translateY(-50%)",
  color: "#000",
  backgroundColor: "#fff",
  borderColor: "#ebebeb",
  opacity: ".7",
  padding: "0"
};

const navBtnPrevStyle = Object.assign({ left: "8px" }, navBtnStyle);
const navBtnNextStyle = Object.assign({ right: "8px" }, navBtnStyle);

export default function ImageCarousel(props) {
  let style = { width: "100%", aspectRatio: "1", objectFit: "cover", borderRadius: "4px" };

  let images = props.images.filter((item) => item) || [];
  if (!images || images.length === 0) {
    images.push(`${STORAGE_BUCKET}${IMG_PLACEHOLDER}`);
  }

  return (
    <Box id="images" style={{ cursor: "pointer", borderRadius: "3px" }} align="center">
      <Carousel
        interval={5000}
        transitionTime={1000}
        showArrows={true}
        showIndicators={props.images?.length > 1}
        showStatus={props.images?.length > 1}
        showThumbs={false}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <IconButton size="small" onClick={onClickHandler} title={label} style={navBtnPrevStyle}>
              <ChevronLeftIcon />
            </IconButton>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <IconButton size="small" onClick={onClickHandler} title={label} style={navBtnNextStyle}>
              <ChevronRightIcon />
            </IconButton>
          )
        }
      >
        {images.map((pic, idx) => {
          return <img alt="" src={pic} key={idx} style={style} />;
        })}
      </Carousel>
    </Box>
  );
}
