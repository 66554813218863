import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { Avatar, Box, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { auth } from "../../../firebase";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link as RouterLink } from "react-router-dom";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import ConfigContext from "../context/ConfigContext";
import { BAKER_PIC_FOLDER } from "../constants";

const menuId = "user-menu";
export default function UserLoggedInButton(props) {
  const navigate = useNavigate();
  const ctx = useContext(ConfigContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAnchorEl(null);
    auth
      .signOut()
      .then(() => {
        //dispatch({ type: "USER", payload: { settings: { showMenu: true }, user: null } });
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  let avatar = ctx.profile.pic;

  return (
    <span>
      <Avatar src={avatar} onClick={handleProfileMenuOpen} style={{ cursor: "pointer", marginLeft: "7px" }}></Avatar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </span>
  );
}
