import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, DialogContent, DialogActions, LinearProgress, Link, DialogTitle } from "@mui/material";
import ProductTagsAutocomplete from "./ProductTagsAutocomplete";
import bkstApi from "../../common/component/api";
import SelectablePhoto from "./SelectablePhoto";

export default function AttachBakerPhotos(props) {
  const [open, setOpen] = useState(false);

  const [tag, setTag] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const [selectedPics, setSelectedPics] = useState(props.pics || []);

  useEffect(() => {
    setResults([]);
    search();
  }, [tag]);

  const search = (e) => {
    setLoading(true);
    bkstApi(`/product/?bakerId=${props.bakerId}&tag=${tag || ""}`).then((res) => {
      setLoading(false);
      setResults(res.data.products);
    });
  };

  const onChange = (src, selected) => {
    if (selected) {
      const list = [...selectedPics, src];
      setSelectedPics(list);
    } else {
      var list = selectedPics.filter((e) => e !== src);
      setSelectedPics(list);
    }
  };

  const onSubmit = () => {
    setOpen(false);
    props.onSubmit(selectedPics);
  };

  const onClose = () => {
    setOpen(false);
    setTag("");
    setSelectedPics([]);
  };

  return (
    <Box>
      <Box align="center">
        <Button disableElevation color="secondary" variant="contained" onClick={() => setOpen(true)}>
          Attach photos
        </Button>
      </Box>
      <Dialog fullWidth open={open} onClose={onClose}>
        {loading && <LinearProgress />}
        <DialogTitle>Select Photos</DialogTitle>
        <DialogContent style={{ height: "90vh" }}>
          <Box>
            <form>
              <Box mb={3} align="center">
                <ProductTagsAutocomplete tags={tag} onChange={(val) => setTag(val)} />
              </Box>
            </form>
          </Box>
          <Box style={{ display: "flex", flexWrap: "wrap" }}>
            {results.map((value, index) => {
              return <SelectablePhoto key={index} src={value.img} selected={selectedPics.indexOf(value.img) > -1} onChange={onChange} />;
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={onClose} style={{ marginRight: "1rem" }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
