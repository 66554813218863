import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import Images from "../../common/component/Images";
import TextWithReadMore from "../../common/component/TextWithReadMore";
import moment from "moment";

export default function RequestHeader(props) {
  return (
    <Paper elevation={0} style={{ backgroundColor: "#ECECEC" }}>
      <Box fontSize={16} px={2} py={2}>
        <Grid container spacing={2} style={{ flexWrap: "noWrap" }}>
          <Grid item sm={3} xs={3}>
            <Images
              pics={props.request.pics || [props.request.pic1, props.request.pic2, props.request.pic3].filter((i) => i)}
              showThumbs={true}
            />
          </Grid>
          <Grid item sm={9} xs={9}>
            <Typography variant="body1" gutterBottom>
              {props.request.title}
            </Typography>
            <Typography variant="overline" display="block" color="textSecondary">
              {moment(props.request.date).format("ddd, MMM DD, YYYY")} {props.request.time && `[${props.request.time}]`} &#9679;{" "}
              {props.request.location}
            </Typography>
            <Typography variant="overline" display="block" color="textSecondary" gutterBottom>
              {props.request.servings} Servings &#9679; {props.request.budget && `$${props.request.budget} budget`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <Box mt={1}>
              <TextWithReadMore data={props.request.details} limit={140} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
