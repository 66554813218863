import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Slide,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/component/api";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { BAKER_PIC_FOLDER, QUOTE_TAGS } from "../../common/component/constants";
import QuoteSearch from "../component/QuoteSearch";
import AttachBakerPhotos from "../../product/component/AttachBakerPhotos";
import RequestHeader from "../../request/component/RequestHeader";
import ProductAttributes from "../../product/component/ProductAttributes";
import currency from "currency.js";
import { getAmountWithTxnFee, getOriginalAmount } from "../../common/component/util";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import BDateTimePicker from "../../common/component/BDateTimePicker";

export default function Quote(props) {
  const { reqId, quoteId } = useParams();

  const navigate = useNavigate();

  const [status, setStatus] = useState();
  const [quote, setQuote] = useState("");
  const [exdate, setExdate] = useState("");
  const [option, setOption] = useState("");
  const [delivery, setDelivery] = useState("");
  const [deliveryBy, setDeliveryBy] = useState("");

  const [product, setProduct] = useState("");
  const [tags, setTags] = useState([]);
  const [note, setNote] = useState("");
  const [pics, setPics] = useState([]);
  const [includeFees, setIncludeFees] = useState(true);

  const [templates, setTemplates] = useState([]);
  const [qdata, setQdata] = useState();
  const [request, setRequest] = useState();
  const [baker, setBaker] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (status === "active" || status === "rbook") {
      if (!exdate) {
        getDefaultExpirationDate();
      }

      if (request) {
        if (!(tags && tags.length > 0)) {
          setTags(request.tags || []);
        }
      }
    }
  }, [status]);

  useEffect(() => {
    if (!loading) {
      if (option === "no") {
        setDelivery("");
        setDeliveryBy("");
      } else if (option === "free") {
        setDelivery(0);
        setDeliveryBy("baker");
      } else {
        setDelivery(delivery);
        setDeliveryBy("baker");
      }
    }
  }, [option]);

  const handleSubmit = (e) => {
    e.preventDefault();

    var payload = {
      bakerId: quoteId,
      status,
      quote,
      option,
      delivery,
      deliveryBy,
      exdate,
      ...product,
      tags,
      note,
      pics,
      includeFees
    };

    if (includeFees) {
      payload.quote = getAmountWithTxnFee(quote);
      payload.delivery = getAmountWithTxnFee(delivery);
    }

    bkstApi.put(`/requests/${reqId}/quotes/${quoteId}`, payload).then((res) => {
      navigate(`/request/${reqId}`);
    });
  };

  const deleteQuote = () => {
    bkstApi.delete(`/requests/${reqId}/quotes/${quoteId}`).then((res) => {
      navigate(`/request/${reqId}`);
    });
  };

  useEffect(() => {
    bkstApi(`/requests/${reqId}/quotes/${quoteId}`).then((res) => {
      const q = res.data.quote;
      const r = res.data.request;

      setQuote(getOriginalAmount(q.quote, q.includeFees));
      setExdate(q.exdate || "");
      setDelivery(getOriginalAmount(q.delivery, q.includeFees));
      setOption(q.option || "");
      setTags(res.data.quote.tags || []);
      setNote(res.data.quote.note || "");
      setPics(res.data.quote.pics || []);
      setStatus(res.data.quote.status);

      setQdata(res.data.quote);
      setRequest(res.data.request);
      setBaker(res.data.quote.baker);

      if (q.status !== "new" && q.status !== "pending") {
        setIncludeFees(res.data.quote.includeFees);
      }

      if (!q.productType && !q.shape && !q.size && !q.servings && !q.frosting) {
        setProduct(r.product || "");
      } else {
        setProduct({
          productType: q.productType || "",
          shape: q.shape || "",
          size: q.size || "",
          servings: q.servings || "",
          frosting: q.frosting || ""
        });
      }

      getTemplates();
      setLoading(false);
    });
  }, []);

  const getTemplates = () => {
    bkstApi(`/admin/template/${quoteId}`).then((res) => {
      setTemplates(res.data.sort((o1, o2) => (o1.name > o2.name ? 1 : -1)));
    });
  };

  const getDefaultExpirationDate = () => {
    bkstApi(`/request/${reqId}/get-default-expiration`).then((res) => {
      if (!exdate) {
        setExdate(res.data.expiration);
      }
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    const isOffboardBaker = baker.status === "offboard";
    const isPendingBaker = baker.status === "pending";
    const isQuoteBaker = baker.status === "quote";

    return (
      <Container maxWidth="sm">
        <Box my={4}>
          <RequestHeader request={request} />
        </Box>
        <Box mb={1}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} sm={6} align="left">
              <Typography variant="overline" color="textSecondary">
                EDIT QUOTE
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} align="right">
              {!qdata.reqTs && (
                <Button color="secondary" variant="outlined" size="small" onClick={deleteQuote}>
                  Delete
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
          <Paper elevation={3}>
            <Box p={2}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Box align="center">
                      <Avatar
                        src={`${BAKER_PIC_FOLDER}/${quoteId}/photo400.webp`}
                        style={{ width: "40px", height: "40px" }}
                        component="a"
                        href={`/baker/${quoteId}`}
                      />
                      <Typography variant="overline" color="textSecondary">
                        {baker.bizName}
                      </Typography>
                      <QuoteSearch q={quoteId} />
                    </Box>
                  </Grid>
                  {baker.notes && (
                    <Grid item sm={12}>
                      <Alert severity="info">{baker.notes}</Alert>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        value={status}
                        labelId="status-label"
                        label="Status"
                        id="status"
                        name="status"
                        onChange={(e) => setStatus(e.target.value)}
                        required
                      >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="review" disabled={isOffboardBaker}>
                          Review
                        </MenuItem>
                        <MenuItem value="unavailable">Unavailable</MenuItem>
                        <MenuItem value="active" disabled={isOffboardBaker || isPendingBaker || isQuoteBaker}>
                          Active
                        </MenuItem>
                        <MenuItem value="paid" disabled={true}>
                          Paid
                        </MenuItem>
                        <MenuItem value="expired" disabled={isOffboardBaker}>
                          Expired
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      name="quote"
                      value={quote}
                      variant="outlined"
                      fullWidth
                      id="quote"
                      label="Quote"
                      onChange={(e) => setQuote(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      disabled={status !== "active" && status !== "rbook"}
                      required={status === "active" || status === "rbook"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                      <BDateTimePicker
                        value={exdate || null}
                        label="Expiration Date"
                        onChange={(d) => setExdate(d)}
                        disablePast
                        disabled={status !== "active" && status !== "rbook"}
                        required={status === "active" || status === "rbook"}
                        autoOk
                        maxDateTime={(request.date && moment(request.date).endOf("day").toDate()) || null}
                      />
                      <FormHelperText>{`Quote expires ${moment(`${exdate}`).fromNow()}`}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <FormControl variant="outlined" required={status === "active" || status === "rbook"} fullWidth>
                      <InputLabel id="delivery-option-label">Delivery option</InputLabel>
                      <Select
                        value={option}
                        labelId="option-label"
                        label="Delivery options"
                        id="option"
                        name="option"
                        onChange={(e) => setOption(e.target.value)}
                        disabled={status !== "active" && status !== "rbook"}
                      >
                        <MenuItem value="free">Free delivery</MenuItem>
                        <MenuItem value="fee">In-house Delivery</MenuItem>
                        <MenuItem value="metrobi">Metrobi</MenuItem>
                        <MenuItem value="no">Pickup Only</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      name="delivery"
                      value={delivery}
                      variant="outlined"
                      fullWidth
                      id="delivery"
                      label="Delivery"
                      onChange={(e) => setDelivery(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      required={option === "fee"}
                      disabled={option !== "fee"}
                    />
                    {request.eventLocation && baker.location && (
                      <Box>
                        <Link
                          component="a"
                          underline="hover"
                          variant="caption"
                          target="_blank"
                          href={`https://www.uber.com/global/en/price-estimate/`}
                        >
                          Uber
                        </Link>
                        {` | `}
                        <Link
                          component="a"
                          underline="hover"
                          variant="caption"
                          target="_blank"
                          href={`https://www.lyft.com/api/costs?start_lat=${baker.location.coord?.lat}&start_lng=${baker.location.coord?.lng}&end_lat=${request.eventLocation.coord?.lat}&end_lng=${request.eventLocation.coord?.lng}`}
                        >
                          {`Lyft`}
                        </Link>
                        <Typography color="textSecondary" variant="caption">{` [${request.eventLocation.zip} - ${baker.location.zip}]`}</Typography>
                      </Box>
                    )}
                  </Grid>
                  {(status === "active" || status === "review") && (
                    <Grid item xs={12} sm={12}>
                      <ProductAttributes value={product} onChange={(o) => setProduct(o)} required={status === "active" || status === "rbook"} />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      value={tags}
                      onChange={(e, val) => setTags(val)}
                      options={QUOTE_TAGS}
                      filterSelectedOptions
                      renderInput={(params) => <TextField variant="outlined" {...params} label="Tags" />}
                      multiple
                      freeSolo
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      value={note}
                      variant="outlined"
                      fullWidth
                      id="note"
                      label="Note"
                      name="note"
                      multiline
                      rows={3}
                      onChange={(e) => setNote(e.target.value)}
                    />
                    <Box mt={1}>
                      {templates.map((t) => (
                        <Chip key={t.name} size="small" label={t.name} component="a" clickable onClick={() => setNote(note.concat(` ${t.text}`))} />
                      ))}{" "}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <AttachBakerPhotos pics={pics} bakerId={quoteId} onSubmit={(arr) => setPics(arr)} />
                    {pics && (
                      <Box mt={1} align="center">
                        {pics.map((p, idx) => (
                          <img key={idx} src={p} style={{ width: "60px", height: "60px", objectFit: "cover", marginRight: "10px" }} />
                        ))}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        className="includeFees"
                        control={
                          <Checkbox checked={includeFees} onChange={(e) => setIncludeFees(e.target.checked)} name="consent" color="secondary" />
                        }
                        label="Include transaction fees"
                      />
                      {includeFees && +quote > 0 && (
                        <Grid item xs={12}>
                          <Box p={1} sx={{ backgroundColor: "#e5f6fd", color: "#2980b9" }}>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography variant="h6">
                                  {currency(quote).format()} &#8674; {currency(getAmountWithTxnFee(quote)).format()}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} align="right">
                                {option === "fee" && (
                                  <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em", justifyContent: "flex-end" }}>
                                    <LocalShippingOutlinedIcon style={{ fontSize: "1.1rem", marginRight: "3px" }} />
                                    {currency(delivery).format()} &#8674; {currency(getAmountWithTxnFee(delivery)).format()}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} style={{ display: "flex", justifyContent: "flex-start" }}>
                      <Images
                        pics={this.state.pics}
                        edit={true}
                        onChange={this.handlePicChange}
                        folder={`img/r/${this.state.reqId}/${this.state.quoteId}`}
                        style={{ height: "60px" }}
                      />
                          </Grid> */}
                </Grid>
                <Box mt={4} align="right">
                  <Button variant="text" component={RouterLink} to={`/request/${reqId}`} style={{ marginRight: "2rem" }}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit" style={{ marginRight: "20px" }}>
                    Save
                  </Button>
                </Box>
              </form>
            </Box>
          </Paper>
        </Slide>
      </Container>
    );
  }
}
