import React from "react";
import { Alert, Box, Divider, Paper, Typography } from "@mui/material";
import IconText from "../../common/component/IconText";
import moment from "moment";
import currency from "currency.js";
import { CheckOutlined } from "@mui/icons-material/";
import CloseIcon from "@mui/icons-material/Close";
import LineItemV2 from "../../common/component/lineitem/LineItemV2";
import Chat from "./Chat";

export default function SaasQuote(props) {
  const { quote, request } = props;

  let { status, exdate } = props.quote;
  quote.expiration = exdate && exdate.includes("T") ? exdate : `${exdate}T23:59`;

  if (status === "active" && moment(quote.expiration).diff(moment(), "minutes") < 0) {
    quote.status = "expired";
  } else if (status === "block") {
    quote.status = "unavailable";
  }

  if (quote.items) {
    return (
      <Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: "4px", alignItems: "center", flexGrow: "1", justifyContent: "flex-end" }}>
            <Chat request={request} quote={quote} status={status} color="primary" variant="text" />
            {/* quote.status !== "paid" && (
              <IconButton color="secondary" component={RouterLink} to={`/quote/${request.id}/edit`}>
                <EditOutlinedIcon />
              </IconButton>
            )*/}
          </Box>
        </Box>
        <Paper elevation={0}>
          <Box p={2}>
            <Box>
              {quote.items.map((item, idx) => (
                <Box key={idx} py={1}>
                  <LineItemV2 item={item} />
                </Box>
              ))}
            </Box>
            <Divider sx={{ marginTop: "15px", marginBottom: "15px" }} />
            <Box>
              <Box>
                <Typography variant="body1">
                  {moment(quote.fulfillment.date).format("ddd, MMM D, YYYY")} {quote.fulfillment.time ? ` [${quote.fulfillment.time}]` : ""}
                </Typography>
              </Box>
              <Box pb={2}>
                <Box py={0.5}>
                  <IconText icon={CheckOutlined} text={"Store Pickup"} variant="body2" />
                </Box>
                {quote.fulfillment.option.delivery.type === "in-house" && (
                  <IconText
                    icon={CheckOutlined}
                    text={`In-house Delivery: ${currency(quote.fulfillment.option.delivery.total).format()} to Zip Code (${
                      quote.fulfillment.option.delivery.location.zip
                    })`}
                    variant="body2"
                  />
                )}
                {quote.fulfillment.option.delivery.type === "metrobi" && <IconText icon={CheckOutlined} text={"Metrobi Delivery"} variant="body2" />}
                {quote.fulfillment.option.delivery.type === "no" && <IconText icon={CloseIcon} text={"Delivery"} variant="body2" />}
              </Box>
            </Box>
            {(status === "active" || status === "expired") && (
              <Alert severity={status === "active" ? "info" : "error"}>{`This quote expire${status === "active" ? "s" : "d"} ${moment(
                quote.expiration
              ).fromNow()}. `}</Alert>
            )}
          </Box>
        </Paper>
      </Box>
    );
  }
}
