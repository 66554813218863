import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  IconButton,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/component/api";
import ChatCanvas from "../../common/component/chat/ChatCanvas";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import DialerSipIcon from "@mui/icons-material/DialerSip";

export default function OrderProxyNumberAdmin(props) {
  const [open, setOpen] = useState(false);
  const [proxy, setProxy] = useState(props.proxy);
  const [sessionStatus, setSessionStatus] = useState("");
  const [conversation, setConversation] = useState([]);
  const [msgToBaker, setMsgToBaker] = useState("");
  const [msgToCust, setMsgToCust] = useState("");
  const [loading, setLoading] = useState(false);

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (open) {
      setLoading(true);
      bkstApi(`/admin/order/${props.orderId}/proxy-session`).then((res) => {
        setSessionStatus(res.data.sessionStatus);
        setConversation(res.data.conversation);

        setMsgToBaker("");
        setMsgToCust("");
        setLoading(false);
      });
      getTemplates();
    } else {
      setSessionStatus("");
      setConversation([]);
      setMsgToBaker("");
      setMsgToCust("");
    }
  }, [open]);

  const generateProxySession = () => {
    setLoading(true);
    bkstApi.put(`/admin/order/${props.orderId}/generate-proxy-session`).then((res) => {
      setProxy(res.data.number);
      setLoading(false);
    });
  };

  const toggleSessionStatus = (e) => {
    setSessionStatus("");
    const newStatus = e.target.checked ? "in-progress" : "closed";
    bkstApi.put(`/admin/order/${props.orderId}/proxy-session`, { sessionStatus: newStatus }).then((res) => {
      setSessionStatus(res.data.sessionStatus);
    });
  };

  const getTemplates = () => {
    bkstApi(`/admin/order/${props.orderId}/template/ochat`).then((res) => {
      setTemplates(res.data.sort((o1, o2) => (o1.name > o2.name ? 1 : -1)));
    });
  };

  const getChatIntro = () => {
    bkstApi.get(`/admin/order/${props.orderId}/get-chat-intro`).then((res) => {
      setMsgToBaker(res.data.msgToBaker);
      setMsgToCust(res.data.msgToCust);
    });
  };

  const sendChatMsg = () => {
    bkstApi.put(`/admin/order/${props.orderId}/send-chat-msg`, { msgToBaker: msgToBaker.trim(), msgToCust: msgToCust.trim() }).then((res) => {
      setOpen(false);
    });
  };

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={() => setOpen(true)}>
        {props.proxy ? <DialerSipIcon /> : <PermPhoneMsgOutlinedIcon />}
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>Order Chat {proxy}</DialogTitle>
        {loading && <LinearProgress />}
        <DialogContent>
          {!proxy && (
            <Box py={3} align="center">
              <Typography variant="subtitle1" color="textSecondary">
                Proxy number does not exist.
              </Typography>
            </Box>
          )}
          {proxy && (
            <Box>
              <ChatCanvas chat={conversation} uid="" />
              <Box align="right">
                <IconButton variant="outlined" size="small" onClick={getChatIntro}>
                  <CachedOutlinedIcon />
                </IconButton>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="msgToBaker"
                      value={msgToBaker}
                      variant="outlined"
                      required
                      fullWidth
                      id="msgToBaker"
                      label="Msg to Baker"
                      multiline
                      rows={msgToBaker.length / 80 > 4 ? 4 : msgToBaker.length / 40 + 1}
                      onChange={(e) => setMsgToBaker(e.target.value)}
                      disabled={sessionStatus !== "open" && sessionStatus !== "in-progress"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="msgToCust"
                      value={msgToCust}
                      variant="outlined"
                      required
                      fullWidth
                      id="msgToCust"
                      label="Msg to Cust"
                      multiline
                      rows={msgToCust.length / 80 > 4 ? 4 : msgToCust.length / 40 + 1}
                      onChange={(e) => setMsgToCust(e.target.value)}
                      disabled={sessionStatus !== "open" && sessionStatus !== "in-progress"}
                    />
                    <Box mt={1}>
                      {templates.map((t) => (
                        <Chip key={t.name} size="small" label={t.name} component="a" onClick={() => setMsgToCust(t.text)} clickable />
                      ))}{" "}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={sessionStatus === "open" || sessionStatus === "in-progress" ? true : false}
                    onChange={toggleSessionStatus}
                    name="sessionStatus"
                    disabled={!sessionStatus}
                  />
                }
                label={`session ${sessionStatus}`}
              />
            </Grid>
            <Grid item xs={6}>
              <Box align="right">
                <Button onClick={() => setOpen(false)} color="primary" style={{ marginRight: "20px" }}>
                  Close
                </Button>
                {!proxy && (
                  <Button variant="contained" color="primary" onClick={generateProxySession}>
                    Generate
                  </Button>
                )}
                {proxy && (
                  <Button variant="contained" color="primary" onClick={sendChatMsg}>
                    Send
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
