import React from "react";
import { Box, Button, Chip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import RequestToBook from "./RequestToBook";

export default function QuoteFooterAction(props) {
  let { status, paymentDueFactor } = props;
  const amount = Math.ceil(+props.quote.quote * +paymentDueFactor);

  if (status === "active" && props.quote.quote) {
    return (
      <Box align="center">
        <Button color="primary" variant="contained" component={RouterLink} to={`/request/${props.reqId}/quote/${props.quote.bakerId}/checkout`}>
          Book for ${amount}
        </Button>
        <Box mt={0.5}>
          {paymentDueFactor < 1 && (
            <Typography variant="caption" color="textSecondary" display="block">
              Pay part now, part later
            </Typography>
          )}
        </Box>
      </Box>
    );
  } else if (status === "rbook") {
    return (
      <Box p={0.5}>
        <RequestToBook reqId={props.reqId} quote={props.quote} />
      </Box>
    );
  } else if (status === "expired") {
    return (
      <Box p={0.5}>
        <RequestToBook reqId={props.reqId} quote={props.quote} />
      </Box>
    );
  } else if (status === "paid") {
    return (
      <Box align="center" p={0.5}>
        <Chip size="medium" label="paid" />
      </Box>
    );
  } else {
    return <Box />;
  }
}
