import React from "react";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import Images from "../../common/component/Images";
import IconText from "../../common/component/IconText";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import { getDisplayName } from "../../common/component/util";
import moment from "moment";
import { bakeStreetSquareLogo } from "../../common/component/constants";
import { Link as RouterLink } from "react-router-dom";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import currency from "currency.js";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

export default function QuoteSummary(props) {
  const { request } = props;

  let status = "";
  if (request.quote.quote) {
    status = "sent";
  } else if (request.quote.status === "block" || request.status === "closed") {
    status = "expired";
  } else if (!request.quote.resTs) {
    status = "pending";
  }

  return (
    <Paper key={request.id} elevation={0}>
      <Box fontSize={16} px={2} pt={2} pb={1} mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={4}>
            <Images pics={request.pics || [request.pic1, request.pic2, request.pic3].filter((i) => i)} />
          </Grid>
          <Grid item xs={8} sm={8}>
            <Box>
              <Typography display="inline">{request.title}</Typography>
            </Box>
            <Box mt={1} style={{ color: "#777" }}>
              <IconText icon={PersonOutlineIcon} text={getDisplayName(request.firstName, request.lastName, request.name)} />
              { request.date && <IconText icon={EventOutlinedIcon} text={`${moment(request.date).format("MMM DD")}`} /> }
              <IconText icon={CakeOutlinedIcon} text={`${request.servings} servings`} />
            </Box>
          </Grid>
        </Grid>
        <Grid mt={1} container alignItems="center">
          <Grid item xs={6} sm={4}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {status === "pending" && request.quote.reqTs && (
                <IconText icon={AccessTimeIcon} text={moment(request.quote.reqTs._seconds * 1000).fromNow()} />
              )}
              {status === "sent" && request.quote.resTs && (
                <IconText icon={SendOutlinedIcon} text={moment(request.quote.reqTs._seconds * 1000).fromNow()} />
              )}
              {status === "expired" && <IconText icon={ThumbDownAltOutlinedIcon} text={request.nogo ? request.nogo : `Expired`} />}
            </Box>
          </Grid>
          <Grid item sm={8} xs={6}>
            <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
              {request.quote?.status === "paid" && (
                <IconButton size="small" component={RouterLink} to={`/order/${request.orderId}`}>
                  <LocalMallOutlinedIcon />
                </IconButton>
              )}
              {request.quote?.quote && !request.quote.resTs && <AutoAwesomeOutlinedIcon sx={{ color: "#999" }} />}
              {request.src === "mkt" && <img src={bakeStreetSquareLogo} style={{ width: "24px", height: "24px" }} />}
              {status === "sent" && (
                <Typography variant="h5">
                  {`${request.quote.quote ? `${currency(request.quote.quote, { precision: 0 }).format()}` : "unavailable"}`}{" "}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
