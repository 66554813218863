import React from "react";
import { Typography } from "@mui/material";

export default function IconText(props) {
  const Icon = props.icon;
  const iconSize = props.iconSize || "1.2rem";
  const fontSize = props.fontSize || ".8rem";

  return (
    <Typography my={.3} variant={props.variant || "overline"} style={{ display: "flex", alignItems: "center", fontSize, color: "#888" }}>
      <Icon style={{ fontSize: iconSize, color: "#888", marginRight: "5px" }} />
      {props.text}
    </Typography>
  );
}
