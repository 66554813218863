import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { PRODUCT_TYPES } from "../../common/component/constants";

export default function ProductTypeAutocomplete(props) {
  const { value } = props;
  const [options, setOptions] = useState(PRODUCT_TYPES);

  const onChange = (e, v) => {
    props.onChange(v);
  };

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      options={options}
      renderInput={(params) => <TextField required={props.required} variant="outlined" {...params} label={props.label} />}
      disableClearable
      freeSolo
      autoSelect
      blurOnSelect={true}
    />
  );
}
