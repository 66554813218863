import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, InputAdornment, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import ItemCustomizationEntry from "./ItemCustomizationEntry";
import QuillRTE from "../QuillRTE";
import currency from "currency.js";
import Price from "../Price";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import ItemVariantEntryV2 from "./ItemVariantEntryV2";
import Images from "../Images";
import { getAmountWithTxnFee } from "../util";
import { QUOTE_TAGS, PRODUCT_TYPES_V2 } from "../constants";
import TextWithReadMore from "../TextWithReadMore";
import { ExpandMoreOutlined } from "@mui/icons-material";

export default function LineItemEntryV2(props) {
  const { item, immutablePrice } = props;

  const updateTotal = (price, quantity, includeFees) => {
    const subTotal = currency(price).multiply(quantity);
    const total = includeFees ? getAmountWithTxnFee(subTotal) : subTotal;
    props.onChange({ ...item, price, quantity, includeFees, total });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item sm={5} xs={4}>
          <Images edit={true} pics={item.images} onChange={(images) => props.onChange({ ...item, images })} folder={`tmp`} />
        </Grid>
        <Grid item sm={7} xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                value={item.type || ""}
                onChange={(e, type) => {
                  let title = "";
                  if (type !== "Other") {
                    title = type;
                  }
                  props.onChange({ images: item.images, title, type, price: "", quantity: 1, includeFees: item.includeFees });
                }}
                options={PRODUCT_TYPES_V2}
                renderInput={(params) => <TextField required={true} variant="outlined" {...params} label={"Item Type"} />}
                disableClearable
                autoSelect
                blurOnSelect={true}
                disabled={immutablePrice}
              />
            </Grid>
            {item.type === "Other" && (
              <Grid item sm={12} xs={12}>
                <TextField
                  id="title"
                  value={item.title}
                  label="Title"
                  name="title"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => props.onChange({ ...item, title: e.target.value })}
                  required
                  helperText="What's the item?"
                  disabled={immutablePrice}
                />
              </Grid>
            )}
            <Grid item sm={8} xs={12}>
              <TextField
                id="price"
                type="number"
                value={item.price}
                label={"Item Price"}
                name="price"
                variant="outlined"
                fullWidth
                required
                onChange={(e) => {
                  updateTotal(e.target.value, item.quantity, item.includeFees);
                }}
                onWheel={(e) => {
                  e.target && e.target.blur();
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyOutlinedIcon />
                    </InputAdornment>
                  )
                }}
                disabled={immutablePrice}
              />
            </Grid>
            <Grid item sm={4} xs={6}>
              <TextField
                id="quantity"
                type="number"
                value={item.quantity}
                label="Quantity"
                name="quantity"
                variant="outlined"
                fullWidth
                required
                onChange={(e) => {
                  updateTotal(item.price, e.target.value, item.includeFees);
                }}
                inputProps={{ maxLength: 3 }}
                disabled={immutablePrice}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Price
                label={"Total"}
                disableAmount={true}
                disableFees={immutablePrice}
                value={{ amount: currency(item.price).multiply(item.quantity).toString(), includeFees: item.includeFees }}
                onChange={(o) => {
                  updateTotal(item.price, item.quantity, o.includeFees);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Accordion elevation={0} sx={{ border: "1px solid #DEDEDE" }}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />} sx={{ fontSize: ".9rem", backgroundColor: "#ECECEC" }}>
              Customization & Notes
            </AccordionSummary>
            <AccordionDetails>
              <Box py={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ItemVariantEntryV2
                      productType={item.type}
                      variant={item.variant || ""}
                      onChange={(variant) => props.onChange({ ...item, variant })}
                    />
                  </Grid>

                  {item.type === "Cake" && (
                    <Grid item xs={12}>
                      <Box my={3}>
                        <ItemCustomizationEntry
                          value={item.customization || ""}
                          type={item.type}
                          onChange={(customization) => props.onChange({ ...item, customization })}
                        />
                      </Box>
                      <Box my={3}>
                        <Autocomplete
                          value={item.tags || []}
                          onChange={(e, tags) => props.onChange({ ...item, tags })}
                          options={QUOTE_TAGS}
                          filterSelectedOptions
                          renderInput={(params) => <TextField variant="outlined" {...params} label="Tags" />}
                          multiple
                          freeSolo
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <QuillRTE value={item.description || ""} onChange={(description) => props.onChange({ ...item, description })} />
                    <Box py={0.4}>
                      <TextWithReadMore
                        data={`These notes are shown to customer. ${
                          item.type === "Cake"
                            ? `Use it to describe decor (edible image, ruffles, sugar flowers..), toppers (edible 3D
                      fondant, 2D cardstock) & more. Propose alternative options when sending quotes.`
                            : ""
                        }`}
                        limit={140}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
}
