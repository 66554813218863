import React, { useState } from "react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import Review from "./Review";
import ReviewEntry from "./ReviewEntry";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";

export default function ReviewAdmin(props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(props.data);

  return (
    <Box mb={3}>
      <Grid container alignItems="center">
        <Grid item xs={9}>
          <Typography variant="caption">REVIEWED ({moment(data.ts._seconds * 1000).fromNow()})</Typography>
        </Grid>
        <Grid item xs={3} align="right">
          <Tooltip title="Edit Review">
            <IconButton
              size="small"
              onClick={() => {
                setOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Review data={data} baker={props.baker} />
      <ReviewEntry
        data={data}
        baker={props.baker}
        open={open}
        setOpen={setOpen}
        onDone={(d) => {
          setData(d);
        }}
      />
    </Box>
  );
}
