import React, { useEffect, useState } from "react";
import { Avatar, Box, Container, Link, LinearProgress, Grid, Paper, Typography, Chip } from "@mui/material";
import bkstApi from "../../common/component/api";
import currency from "currency.js";
import { Link as RouterLink } from "react-router-dom";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";
import moment from "moment";

export default function Loans(props) {
  const [active, setActive] = useState([]);
  const [offers, setOffers] = useState([]);
  const [closed, setClosed] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi.get(`/loan`).then((res) => {
      setActive(res.data.data.filter((o) => o.status === "active").sort((a, b) => (a.balance > b.balance ? -1 : 1)));
      setOffers(
        res.data.data
          .filter((o) => o.status === "offer" || o.status === "review" || o.status === "approved")
          .sort((a, b) => (a.ts._seconds > b.ts._seconds ? -1 : 1))
      );
      setClosed(res.data.data.filter((o) => o.status === "closed").sort((a, b) => (a.ts._seconds > b.ts._seconds ? -1 : 1)));

      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box my={4} textAlign={"center"}>
          <Typography variant="h3" gutterBottom>
            Loans
          </Typography>
        </Box>
        <Box my={2}>
          <Typography variant="h5">Active Loans</Typography>
        </Box>
        {active.map((loan, idx) => {
          const repaid = +loan.total - +loan.balance;

          return (
            <Box mb={4} key={idx}>
              <Link to={`/loan/${loan.id}`} component={RouterLink} underline="none">
                <Paper elevation={0}>
                  <Box px={2} py={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Avatar src={`${BAKER_PIC_FOLDER}/${loan.uid}/photo400.webp`} style={{ width: "50px", height: "auto" }} />
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="overline" color="textSecondary">
                          DUE
                        </Typography>
                        <Typography variant="body1">{currency(loan.total, { precision: 0 }).format()}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="overline" color="textSecondary">
                          REPAID
                        </Typography>
                        <Typography variant="body1">{currency(repaid, { precision: 0 }).format()}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="overline" color="textSecondary">
                          BALANCE
                        </Typography>
                        <Typography variant="body1">{currency(loan.balance, { precision: 0 }).format()}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                <LinearProgress color="secondary" variant="determinate" value={(repaid * 100) / loan.total} />
                <Typography variant="caption" color="textSecondary">
                  {moment(loan.ts._seconds * 1000).format("ll")}
                </Typography>
              </Link>
            </Box>
          );
        })}
        <Box my={6}>
          <Box my={2}>
            <Typography variant="h5">Loan Offers</Typography>
          </Box>
          {offers.map((loan, idx) => {
            return (
              <Box mb={4} key={idx}>
                <Link to={`/loan/${loan.id}`} component={RouterLink} underline="none">
                  <Paper elevation={0}>
                    <Box p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <Avatar src={`${BAKER_PIC_FOLDER}/${loan.uid}/photo400.webp`} style={{ width: "50px", height: "auto" }} />
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="subtitle1">{currency(loan.loanAmount, { precision: 0 }).format()}</Typography>
                          <Box>
                            <Typography variant="overline">
                              {currency(loan.fee, { precision: 0 }).format()} [{loan.repaymentRate}%]
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          {loan.status === "review" && <Chip size="small" color="info" label={loan.status}></Chip>}
                          {loan.status === "approved" && <Chip size="small" color="success" label={loan.status}></Chip>}
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                  <Typography variant="caption" color="textSecondary">
                    {moment(loan.ts._seconds * 1000).format("ll")}
                  </Typography>
                </Link>
              </Box>
            );
          })}
        </Box>
        <Box my={6}>
          <Box my={2}>
            <Typography variant="h5">Past Loans</Typography>
          </Box>
          <Grid container spacing={2}>
            {closed.map((loan, idx) => {
              return (
                <Grid item xs={4} align="center" key={idx}>
                  <Paper elevation={0}>
                    <Box p={2}>
                      <Link to={`/loan/${loan.id}`} component={RouterLink} underline="none">
                        <Box mb={1}>
                          <Avatar src={`${BAKER_PIC_FOLDER}/${loan.uid}/photo400.webp`} style={{ width: "50px", height: "auto" }} />
                        </Box>
                        <Typography variant="body2">{currency(loan.total, { precision: 0 }).format()}</Typography>
                        <Typography variant="caption" color="textSecondary">
                          {moment(loan.ts._seconds * 1000).format("ll")}
                        </Typography>
                      </Link>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    );
  }
}
