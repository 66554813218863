import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import Images from "../../common/component/Images";
import bkstApi from "../../common/component/api";
import { useNavigate } from "react-router";
import { QUOTE_TAGS } from "../../common/component/constants";
import ProductAttributes from "../../product/component/ProductAttributes";
import { useParams } from "react-router-dom";

export default function OrderDetailsEntry(props) {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const [order, setOrder] = useState("");

  const [title, setTitle] = useState("");
  const [pics, setPics] = useState("");
  const [product, setProduct] = useState("");
  const [flavor, setFlavor] = useState("");
  const [filling, setFilling] = useState("");
  const [tags, setTags] = useState([]);
  const [decor, setDecor] = useState("");
  const [inscription, setInscription] = useState("");
  const [status, setStatus] = useState();
  const [consent, setConsent] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/admin/order/${orderId}`).then((res) => {
      setOrder(res.data);

      const details = res.data.details;
      setTitle(details.title);
      setPics(details.pics);
      setFlavor(details.flavor);
      setFilling(details.filling);
      setTags(details.tags || []);
      setDecor(details.decor);
      setInscription(details.inscription);
      setStatus(res.data.status);
      setProduct({
        productType: details.productType || "",
        shape: details.shape || "",
        size: details.size || "",
        servings: details.servings || "",
        frosting: details.frosting || ""
      });
      setLoading(false);
    });
  }, [orderId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const details = {
      title,
      pics,
      ...product,
      flavor,
      filling,
      tags,
      decor,
      inscription
    };
    bkstApi.put(`/my/order/${orderId}/details`, { details, consent }).then((res) => {
      navigate(-1);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box pt={3}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="overline">EDIT ORDER</Typography>
            </Grid>
          </Grid>
          <Paper>
            {order.src === "direct" && <Alert severity="error">Updating this order will mark the print status stale</Alert>}
            <Box px={3} pt={3} pb={3}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item sm={12} xs={6}>
                    <Box mb={2}>
                      <Images edit={true} pics={pics} onChange={(pics) => setPics(pics)} folder={`img/o/${orderId}`} />
                    </Box>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      id="title"
                      value={title}
                      label="Title"
                      name="title"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ProductAttributes value={product} onChange={(o) => setProduct(o)} required={true} />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="flavor"
                      value={flavor}
                      label="Flavor"
                      name="flavor"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setFlavor(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="filling"
                      value={filling}
                      label="Filling"
                      name="filling"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setFilling(e.target.value)}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      id="inscription"
                      value={inscription}
                      label="Inscription"
                      name="inscription"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setInscription(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      value={tags}
                      onChange={(e, val) => setTags(val)}
                      options={QUOTE_TAGS}
                      filterSelectedOptions
                      renderInput={(params) => <TextField variant="outlined" {...params} label="Tags" />}
                      multiple
                      freeSolo
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      id="decor"
                      value={decor}
                      label="Notes"
                      name="decor"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={decor.length / 50 > 5 ? 5 : decor.length / 50 + 1}
                      onChange={(e) => setDecor(e.target.value)}
                    />
                  </Grid>
                  {order.paymentType != "offline" && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        className="consent"
                        control={<Checkbox checked={consent} onChange={(e) => setConsent(e.target.checked)} name="consent" color="secondary" />}
                        label="I've discussed these changes with the customer and we're in agreement"
                      />
                      {consent && <Alert severity="info">Customer will be notified when you save these changes</Alert>}
                    </Grid>
                  )}
                  <Grid item xs={12} align="right">
                    <Button variant="text" color="secondary" style={{ marginRight: "15px" }} onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }
}
