import React from "react";
import { Box, FormControlLabel, Radio, Typography } from "@mui/material";
import moment from "moment";

export default function NotifyOption(props) {
  let disabled = props.disabled || (props.trail && Object.keys(props.trail).length >= 3);
  return (
    <Box pb={1}>
      <FormControlLabel value={props.code} control={<Radio />} label={props.label} disabled={disabled} />
      {props.trail &&
        Object.entries(props.trail).map(([key, value]) => {
          return (
            <Typography variant="caption" color="textSecondary" key={key} display="block">
              {`sent ${moment(value).format("ddd, MMM DD, YYYY h:mm a")} (${moment(value).fromNow()})`}
            </Typography>
          );
        })}
    </Box>
  );
}
