import React, { useState } from "react";
import { Box, Chip, Grid, Link, Typography } from "@mui/material";
import currency from "currency.js";
import { generateSizeSummary } from "../util";
import Images from "../Images";
import LineItemDetails from "./LineItemDetails";

export default function LineItem(props) {
  const { item } = props;

  const [showMore, setShowMore] = useState(Boolean(props.showMore));

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Images pics={item.images} allowDownload={props.allowDownload} />
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="body1">{item.title}</Typography>
            {props.view === "min" ? (
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {generateSizeSummary(item.variant.size)}
                </Typography>
                <Link sx={{ cursor: "pointer", fontSize: ".9rem" }} onClick={() => setShowMore(!showMore)}>
                  {showMore ? "hide" : "show"} details
                </Link>
                {showMore && (
                  <Box sx={{ borderLeft: "2px solid #ECECEC", paddingLeft: "1rem" }}>
                    <LineItemDetails item={item} />
                  </Box>
                )}
              </Box>
            ) : (
              <LineItemDetails item={item} />
            )}
          </Grid>
          <Grid item xs={2} align="right">
            <Chip size="small" label={item.quantity} />
          </Grid>
          <Grid item xs={2} align="right">
            <Typography variant="overline">{currency(item.total || item.price).format()}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
