import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/component/api";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import ChatBubble from "../../common/component/chat/ChatBubble";

export default function SmsBakerButton(props) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");
  const [msg, setMsg] = useState("");
  const [includeLink, setIncludeLink] = useState(true);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    setMsg(`${content} ${includeLink ? props.link : ""}`);
  }, [content, includeLink]);

  const sendSms = () => {
    setSent(true);
    bkstApi.post(`/baker/${props.bakerId}/send-sms`, { msg }).then(() => {
      setOpen(false);
      setSent(false);
      setContent("");
    });
  };

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={() => setOpen(true)} disabled={props.disabled}>
        <SmsOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          <Box mx={2} my={1}>
            <Typography variant="overline">Sms to Baker</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="content"
            value={content}
            name="content"
            variant="outlined"
            multiline
            rows={3}
            fullWidth
            required
            autoFocus
            placeholder="Type a message"
            onChange={(e) => setContent(e.target.value)}
            inputProps={{
              maxLength: 200
            }}
          />
          <FormControlLabel
            control={<Checkbox checked={includeLink} onChange={(e) => setIncludeLink(e.target.checked)} name="includeLink" />}
            label="Include Link?"
          />
          <Box mt={1}>
            <Typography variant="overline" color="textSecondary">
              Your Final message
            </Typography>
            <ChatBubble chat={{ msg }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" style={{ marginRight: "15px" }}>
            Close
          </Button>
          <Button variant="contained" onClick={sendSms} color="primary" disabled={sent || content.length < 10}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
