import React, { useEffect, useState, useRef } from "react";
import { Autocomplete, Badge, Box, CircularProgress, Grid, Link, InputAdornment, TextField, Typography, Avatar } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import moment from "moment";
import bkstApi from "../api";
import { Link as RouterLink } from "react-router-dom";
import { BAKER_PIC_FOLDER, placeholderImg } from "../constants";
import { formatPhoneNumber } from "../util";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import LocalMallIcon from "@mui/icons-material/LocalMall";

export default function QuickSearch() {
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    useEffect(() => {
      // Cleanup the previous timeout on re-render
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    const debouncedCallback = (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };

    return debouncedCallback;
  };

  const handleSearch = useDebounce((query) => {
    callApi(query);
  }, 1000);

  const callApi = () => {
    bkstApi(`/my/search?q=${q}`).then((res) => {
      const results = res.data;
      setOptions(results);
      setLoading(false);
    });
  };

  const handleClick = () => {
    setQ("");
  };

  useEffect(() => {
    if (q.length > 1) {
      setLoading(true);
      handleSearch(q);
    } else {
      setOptions([]);
    }
  }, [q]);

  const getListItem = (obj, option) => {
    let link = "";
    if (option.type === "order") {
      link = `/order/${option.objectID}`;
    } else if (option.type === "baker") {
      link = `/baker/${option.objectID}`;
    } else if (option.type === "request") {
      link = `/request/${option.objectID}`;
    } else if (option.type === "quote") {
      link = `/request/${option.objectID}`;
    }

    let description = option.desc;
    if (option.date) {
      description += `• ${moment(option.date).format("MMM DD")}`;
    }

    let summary = <Box />;
    if (option.type === "quote") {
      summary = (
        <Box>
          <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em" }}>
            <LocalAtmOutlinedIcon style={{ fontSize: "1.1em", color: "#999", marginRight: "3px" }} />
            {option.name} &bull; {option.date && moment(option.date).format("MMM DD")}
          </Typography>
          {option.title && (
            <Typography variant="caption" display="block" color="textSecondary">
              {option.title}
            </Typography>
          )}
          {option.phone && (
            <Typography variant="caption" display="block" color="textSecondary">
              {formatPhoneNumber(option.phone) || ""}
            </Typography>
          )}
        </Box>
      );
    } else if (option.type === "order") {
      summary = (
        <Box>
          <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em" }}>
            <LocalMallIcon style={{ fontSize: "1.1em", color: "#999", marginRight: "3px" }} />
            {option.name} &bull; {option.date && moment(option.date).format("MMM DD")}
          </Typography>
          {option.title && (
            <Typography variant="caption" display="block" color="textSecondary">
              {option.title}
            </Typography>
          )}
          {option.phone && (
            <Typography variant="caption" display="block" color="textSecondary">
              {formatPhoneNumber(option.phone) || ""}
            </Typography>
          )}
        </Box>
      );
    } else if (option.type === "baker") {
      summary = (
        <Box>
          <Typography variant="caption">{option.name}</Typography>
          {option.phone && (
            <Typography variant="caption" display="block" color="textSecondary">
              {formatPhoneNumber(option.phone) || ""}
            </Typography>
          )}
        </Box>
      );
    }

    return (
      <Link key={option.objectID} component={RouterLink} to={link} style={{ textDecoration: "none", color: "inherit" }} onClick={handleClick}>
        <Box p={1}>
          <Grid container spacing={1} wrap="nowrap">
            <Grid item align="center">
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                badgeContent={
                  option.uid && (
                    <img
                      src={`${BAKER_PIC_FOLDER}/${option.uid}/logo400.webp`}
                      style={{ width: "28px", height: "28px", borderRadius: "14px", backgroundColor: "#fff" }}
                    />
                  )
                }
              >
                <img src={option.pic || placeholderImg} style={{ width: "50px", height: "50px", objectFit: "cover" }} />
              </Badge>
            </Grid>
            <Grid item>{summary}</Grid>
          </Grid>
        </Box>
      </Link>
    );
  };

  return (
    <Autocomplete
      id="quick-search"
      freeSolo
      noOptionsText={"No matches found"}
      style={{ maxWidth: "250px", flexGrow: "1" }}
      filterOptions={(x) => x}
      renderOption={getListItem}
      getOptionLabel={(option) => option.title}
      options={options}
      disableClearable
      blurOnSelect={true}
      renderInput={(params) => (
        <TextField
          {...params}
          value={q}
          variant="outlined"
          size="small"
          onChange={(e) => setQ(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon style={{ color: "#888" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}
