import React, { useEffect, useState } from "react";
import { Box, Button, Container, Dialog, DialogContent, DialogTitle, LinearProgress, Grid, Link, TextField, Typography } from "@mui/material";
import bkstApi from "../../common/component/api";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import currency from "currency.js";

export default function DailyReport(props) {
  const [from, setFrom] = useState(moment().subtract(7, "days").format("YYYY-MM-DD"));
  const [to, setTo] = useState(moment().subtract(1, "day").format("YYYY-MM-DD"));

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [json, setJson] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    bkstApi.get(`/admin/dashboard?from=${from}&to=${to}`).then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    let mktGMV = 0,
      dtGMV = 0,
      mktRev = 0,
      dtRev = 0,
      mktOrders = 0,
      dtOrders = 0,
      mktReq = 0,
      dtReq = 0,
      mktQReq = 0,
      dtQReq = 0;
    let summary = {};

    for (const d of data) {
      mktGMV += d.orders.mkt.sales;
      dtGMV += d.orders.direct.sales;

      mktRev += d.orders.mkt.fees;
      dtRev += d.orders.direct.fees;

      mktOrders += d.orders.mkt.total;
      dtOrders += d.orders.direct.total;

      mktReq += d.req.mkt.total;
      dtReq += d.req.direct.total;

      mktQReq += d.req.mkt.quoted;
      dtQReq += d.req.direct.quoted;

      if (d.orders.utm) {
        for (const key in d.orders.utm) {
          let utmSummary = summary[key] || { sales: 0, total: 0, fees: 0 };
          utmSummary.sales = +utmSummary.sales + +d.orders.utm[key].sales;
          utmSummary.total = +utmSummary.total + +d.orders.utm[key].total;
          utmSummary.fees = +utmSummary.fees + +d.orders.utm[key].fees;

          summary[key] = utmSummary;
        }
      }
    }

    return (
      <Container maxWidth="md">
        <Box align="center" my={6}>
          <form onSubmit={getData}>
            <TextField
              value={from}
              variant="outlined"
              id="from"
              label="From"
              name="from"
              onChange={(e) => setFrom(e.target.value)}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              value={to}
              variant="outlined"
              id="to"
              label="To"
              name="to"
              onChange={(e) => setTo(e.target.value)}
              type="date"
              InputLabelProps={{ shrink: true }}
            />
            <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
              <SearchIcon />
            </Button>
          </form>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="overline" color="textSecondary">
                REQUESTS
              </Typography>
              <Box pt={3} pb={1} px={1} align="center" style={{ backgroundColor: "#DDD", height: "100%" }}>
                <Typography variant="h5">{mktReq}</Typography>
                <Typography variant="overline" display="block">
                  {`${mktQReq} QUOTED`}
                </Typography>
                <Typography variant="caption" color="textSecondary" display="block">
                  {`D: ${dtQReq}(R) > ${dtQReq}(Q)`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="overline" color="textSecondary">
                REVENUE
              </Typography>
              <Box pt={3} pb={1} px={1} align="center" style={{ backgroundColor: "#DDD", height: "100%" }}>
                <Typography variant="h5">{currency(mktRev, { precision: 0 }).format()}</Typography>
                <Typography variant="overline" display="block">
                  {`${currency(mktGMV, { precision: 0 }).format()} GMV`}
                </Typography>
                <Typography variant="caption" color="textSecondary" display="block">
                  {`D: ${currency(dtRev, { precision: 0 }).format()} (REV) ${currency(dtGMV, { precision: 0 }).format()} (GMV)`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="overline" color="textSecondary">
                CONVERSION
              </Typography>
              <Box pt={3} pb={1} px={1} align="center" style={{ backgroundColor: "#DDD", height: "100%" }}>
                <Typography variant="h5">{((+mktOrders * 100) / mktQReq).toFixed(0)}%</Typography>
                <Typography variant="overline" display="block">
                  {`${mktOrders} orders`}
                </Typography>
                <Typography variant="caption" color="textSecondary" display="block">
                  {`D: ${((+dtOrders * 100) / dtQReq).toFixed(0)}% Conv | ${dtOrders} orders`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={6}>
          <Grid container spacing={2} align="center">
            <Grid item xs={3}>
              <Typography variant="overline">DATE</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="overline">REVENUE</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="overline">CONVERSION</Typography>
            </Grid>
          </Grid>

          {data.map((d) => {
            return (
              <Box py={3} key={d.date} style={{ borderTop: "1px solid #DDD" }}>
                <Grid container spacing={2} align="center">
                  <Grid item xs={3}>
                    <Box>
                      <Typography variant="subtitle1">{moment(d.date, "YYYYMMDD").format(`DD MMM`)}</Typography>
                      <Typography variant="caption" display="block">
                        {`${d.req.mkt.total}(R) > ${d.req.mkt.quoted}(Q)`}
                      </Typography>
                      <Typography variant="caption" color="textSecondary" display="block">
                        {`D: ${d.req.direct.total}(R) > ${d.req.direct.quoted}(Q)`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">{`${currency(d.orders.mkt.fees, { precision: 0 }).format()}`}</Typography>
                    <Typography variant="overline" display="block">{`${currency(d.orders.mkt.sales, {
                      precision: 0
                    }).format()} GMV`}</Typography>
                    <Typography variant="caption" color="textSecondary">{`D: ${currency(d.orders.direct.fees, {
                      precision: 0
                    }).format()} (REV) ${currency(d.orders.direct.sales, { precision: 0 }).format()} (GMV)`}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1">{((d.orders.mkt.total * 100) / d.req.mkt.quoted).toFixed(0)}%</Typography>
                    <Typography variant="overline" display="block">
                      {`${d.orders.mkt.total} orders`}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" display="block">
                      {`D: ${((+d.orders.direct.total * 100) / d.req.direct.quoted).toFixed(0)}% Conv | ${d.orders.direct.total} orders`}
                    </Typography>
                    {d.orders.utm && (
                      <Link href="#" onClick={() => setJson(d.orders.utm)}>
                        utm
                      </Link>
                    )}
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          <Box my={5}>
            <Typography color="textSecondary" variant="overline">
              UTM SUMMARY
            </Typography>
            <Box p={2} style={{ backgroundColor: "#FFF" }}>
              <pre>{JSON.stringify(summary, null, 2)}</pre>
            </Box>
          </Box>
        </Box>
        <Dialog fullWidth maxWidth="sm" open={Boolean(json)} onClose={() => setJson("")}>
          <DialogTitle>JSON</DialogTitle>
          <DialogContent>
            <Box>
              <pre>{JSON.stringify(json, null, 2)}</pre>
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    );
  }
}
