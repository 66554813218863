import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Container, Grid, Link, LinearProgress, TextField, Typography } from "@mui/material";
import bkstApi from "../../common/component/api";
import currency from "currency.js";
import BakerOrderSummary from "../../order/component/OrderSummary";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { sortByOrderDateTime } from "../../common/component/util";

const FILTERS = ["recent", "open", "canceled", "completed"];

export default function BakerOrders(props) {
  const [filter, setFilter] = useState("recent");

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [orders, setOrders] = useState([]);
  const [summary, setSummary] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let from = "";
    let to = "";

    if (filter === "recent") {
      from = moment().subtract(7, "days").format("YYYY-MM-DD");
    } else if (filter === "open") {
    } else if (filter === "completed") {
    } else {
      from = moment().format("YYYY-MM-DD");
      to = moment().add(28, "days").endOf("isoweek").format("YYYY-MM-DD");
    }

    setFromDt(from);
    setToDt(to);

    search(from, to);
  }, [filter]);

  /*useEffect(() => {
    if (toDt < fromDt) {
      setToDt(fromDt);
    }
  }, [fromDt]);*/

  const runSearch = (e) => {
    e && e.preventDefault();
    search(fromDt, toDt);
  };

  const search = (from, to) => {
    console.log(from, to);
    setLoading(true);

    bkstApi.get(`/baker/${props.bakerId}/order?filter=${filter}&from=${from}&to=${to}`).then((res) => {
      setOrders(res.data.orders);
      if (filter === "recent") {
        setOrders(res.data.orders);
      } else {
        setOrders(res.data.orders.sort(sortByOrderDateTime));
      }

      let [onlineCount, onlineSales, onlineTips, offlineCount, offlineSales, offlineTips] = [0, 0, 0, 0, 0, 0];
      for (const o of res.data.orders) {
        if (o.paymentType === "offline") {
          offlineCount = +offlineCount + 1;
          offlineSales = +offlineSales + +o.payment.total;
          offlineTips = +offlineTips + +o.payment.tip;
        } else {
          onlineCount = +onlineCount + 1;
          onlineSales = +onlineSales + +o.payment.total;
          onlineTips = +onlineTips + +o.payment.tip;
        }
      }
      setSummary({
        online: { count: onlineCount, sales: onlineSales, tips: onlineTips },
        offline: { count: offlineCount, sales: offlineSales, tips: offlineTips }
      });

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box my={5}>
          {/* <form onSubmit={runSearch}>
            <Box py={2} align="center">
              <TextField
                value={fromDt}
                variant="outlined"
                id="fromDt"
                label="From Date"
                name="fromDt"
                onChange={(e) => setFromDt(e.target.value)}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                value={toDt}
                variant="outlined"
                type="date"
                id="toDt"
                label="To Date"
                name="toDt"
                onChange={(e) => setToDt(e.target.value)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: fromDt }}
              />
              <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
                <SearchIcon />
              </Button>
            </Box>
    </form> */}
          <Box my={6} align="center">
            {FILTERS.map((o, idx) => {
              return (
                <Chip
                  key={idx}
                  size="medium"
                  color={filter === o ? "primary" : "secondary"}
                  variant="outlined"
                  label={o}
                  clickable
                  component={Link}
                  onClick={() => setFilter(o)}
                />
              );
            })}
          </Box>
          {summary && (
            <Box my={2} p={2} style={{ backgroundColor: "#EEE" }} align="center">
              <Grid container>
                <Grid item xs={6} align="center">
                  <Typography variant="overline" color="textSecondary">
                    ONLINE
                  </Typography>
                  <Typography variant="h4">{currency(summary.online.sales, { precision: 0 }).format()}</Typography>
                  <Typography variant="overline">
                    {summary.online.count} ORDERS • {currency(summary.online.tips, { precision: 0 }).format()} TIP
                  </Typography>
                </Grid>
                <Grid item xs={6} align="center">
                  <Typography variant="overline" color="textSecondary">
                    OFFLINE
                  </Typography>
                  <Typography variant="h4">{currency(summary.offline.sales, { precision: 0 }).format()}</Typography>
                  <Typography variant="overline">
                    {summary.offline.count} ORDERS • {currency(summary.offline.tips, { precision: 0 }).format()} TIP
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          {orders.map((o) => {
            return (
              <Link key={o.id} underline="none" component={RouterLink} to={`/order/${o.id}`} href="#">
                <BakerOrderSummary order={o} />
              </Link>
            );
          })}
        </Box>
      </Container>
    );
  }
}
