import React from "react";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import currency from "currency.js";
import moment from "moment";
import Transfers from "./Transfers";
import { capitalize } from "../../common/component/util";
import ShowMore from "../../common/component/ShowMore";

export default function OrderPayment(props) {
  const { order } = props;

  let footer = <Box />;
  if (order.transfers) {
    footer = <Transfers data={order.transfers} />;
  } else if (order.payment.sent) {
    footer = (
      <Box>
        <Typography variant="overline" color="textSecondary" display="block">
          {currency(order.payment.sent).format()} transferred
        </Typography>
        <Typography variant="caption" color="textSecondary">
          Transaction fee: ({currency(order.payment.txnFee).format()}){" "}
          {order.payment.loanRepayment && ` Loan Repayment: (${currency(order.payment.loanRepayment).format()})`}
        </Typography>
      </Box>
    );
  }

  let dueDate = moment(order.fulfillment.date).subtract(7, "days");
  if (order.payment.dueDate) {
    dueDate = moment(order.payment.dueDate);
  }

  return (
    <Box>
      <Paper elevation={0}>
        <Box px={2} py={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Subtotal
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="overline" color="textSecondary">
                {currency(order.payment.subtotal).format()}
              </Typography>
            </Grid>
          </Grid>
          {order.payment.delivery > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Delivery
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="overline" color="textSecondary">
                  {currency(order.payment.delivery).format()}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Tip
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="overline" color="textSecondary">
                {currency(order.payment.tip).format()}
              </Typography>
            </Grid>
          </Grid>
          {order.payment.discount > 0 && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Discount
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="overline" color="textSecondary">
                  {currency(order.payment.discount).format()}
                </Typography>
              </Grid>
            </Grid>
          )}
          {order.payment.fee > 0 && (
            <Grid container spacing={2} my={0.2}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  Service Fee
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="overline" color="textSecondary">
                  {currency(order.payment.fee).format()}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                Total
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography variant="overline">{currency(order.payment.total).format()}</Typography>
            </Grid>
          </Grid>
          {order.payment.due > 0 && (
            <Box>
              <Box my={2}>
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="overline" color="textSecondary">
                    Amount Paid
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography variant="overline" color="textSecondary">
                    {currency(order.payment.paid).format()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="overline" color="textSecondary">
                    Balance Due {dueDate.format("MMM DD")}
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography variant="overline" color="textSecondary">
                    {currency(order.payment.due).format()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          {order.payment.refund && (
            <Box>
              <Box my={2}>
                <Divider />
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="overline" color="error" display="block">
                    Refund
                  </Typography>
                  <ShowMore openCta="show details" closeCta="hide details">
                    {order.payment.refund.map((o) => (
                      <Box>
                        <Typography variant="caption" display="block" color="textSecondary">
                          {moment(o.ts).format("LLL")}
                        </Typography>
                        <Typography variant="body1" display="block">
                          {currency(o.amount).format()} Refund
                        </Typography>
                        <Typography variant="body2" display="block" color="textSecondary">
                          Reason: {capitalize(o.reason)}. {o.note}
                        </Typography>
                      </Box>
                    ))}
                  </ShowMore>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography variant="overline" color="error">
                    {currency(
                      order.payment.refund.reduce((acc, obj) => {
                        return +acc + +obj.amount;
                      }, 0)
                    ).format()}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Paper>
      <Box my={2} align="center">
        {footer}
      </Box>
    </Box>
  );
}
