import React, { useState, useRef } from "react";
import { Badge, IconButton } from "@mui/material";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import WarningIcon from "@mui/icons-material/Warning";

export default function OrderPrint(props) {
  const [order, setOrder] = useState(props.order);
  const [printActivity, setPrintActivity] = useState(order?.activity?.print || []);

  const lastPrintTs = (printActivity && printActivity.length > 0 && printActivity[printActivity.length - 1].ts) || "";

  let badgeContent = 0;
  if (lastPrintTs) {
    //stale print
    if (order.updTs && order.updTs > lastPrintTs) {
      badgeContent = <WarningIcon sx={{ fontSize: "16px", color: "#e67e22" }} />;
    } else {
      // up-to-date print
      badgeContent = <CheckOutlinedIcon sx={{ fontSize: "16px", color: "#888" }} />;
    }
  }

  return (
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      <IconButton size="small">
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          badgeContent={badgeContent}
        >
          <PrintOutlinedIcon />
        </Badge>
      </IconButton>
    </span>
  );
}
