import React, { useState } from "react";
import { Badge, Box, Link } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function SelectablePhoto(props) {
  const [selected, setSelected] = useState(props.selected || false);

  const handleClick = () => {
    const newState = !selected;
    setSelected(newState);
    props.onChange(props.src, newState);
  };

  return (
    <Badge
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      badgeContent={selected ? <CheckCircleIcon style={{ color: "green" }} /> : ""}
      style={{ marginRight: "1rem", marginBottom: "1rem" }}
    >
      <Box p={1} style={{ width: "150px", height: "150px", border: "1px solid #DDD" }} align="center">
        <Link onClick={handleClick}>
          <img src={props.src} style={{ height: "100%", maxWidth: "100%", objectFit: "contain" }} />
        </Link>
      </Box>
    </Badge>
  );
}
