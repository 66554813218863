import React from "react";
import { Alert, Box, Grid, Typography } from "@mui/material";
import ExpandableImage from "../../common/component/ExpandableImage";
import { SentimentDissatisfied, Timelapse } from "@mui/icons-material/";
import TextWithReadMore from "../../common/component/TextWithReadMore";
import IconText from "../../common/component/IconText";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { createSnippets } from "../../common/component/util";
import HTMLViewer from "../../common/component/HTMLViewer";

export default function QuoteDescriptionV2(props) {
  const status = props.status;

  if (status === "pending") {
    return (
      <Box>
        <Alert severity="warning" icon={<Timelapse />}>
          We are reviewing your request & we'll send you a quote shortly
        </Alert>
      </Box>
    );
  } else if (status === "unavailable" || status === "block") {
    return (
      <Box>
        <Alert severity="error" icon={<SentimentDissatisfied />}>
          {props.quote.note || "Sorry, we are fully booked!"}
        </Alert>
      </Box>
    );
  } else {
    const pics = props.quote.pics || [props.quote.pic1, props.quote.pic2, props.quote.pic3].filter((i) => i);
    const snippets = createSnippets(props.quote.productType, props.quote.shape, props.quote.size, props.quote.servings);

    return (
      <Box my={2} style={{ color: "#777" }}>
        <Box mb={2}>
          <Typography variant="body2" gutterBottom>
            Your {props.request.title} includes
          </Typography>
        </Box>
        <Box my={1}>
          <Grid container>
            {snippets.map((e, idx) => (
              <Grid item sm={6} xs={12} key={idx}>
                <IconText icon={CheckOutlinedIcon} text={e} variant="body1" />
              </Grid>
            ))}
            <Grid item sm={6} xs={12}>
              <IconText icon={CheckOutlinedIcon} text={`${props.quote.frosting} frosting`} variant="body1" />
            </Grid>
            {props.quote.tags &&
              props.quote.tags.map((e, idx) => (
                <Grid item sm={6} xs={12} key={idx}>
                  <IconText icon={CheckOutlinedIcon} text={e} variant="body1" />
                </Grid>
              ))}
            <Grid item sm={6} xs={12}>
              <IconText icon={CheckOutlinedIcon} text={`Choice of flavor`} variant="body2" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <IconText icon={CheckOutlinedIcon} text={`Custom inscription`} variant="body2" />
            </Grid>
          </Grid>
        </Box>
        {props.quote.note && (
          <Box py={1}>
            <HTMLViewer content={props.quote.note} style={{ fontSize: ".85rem" }} />
          </Box>
        )}
        {pics &&
          pics.map((p, idx) => (
            <span key={idx} style={{ marginRight: "15px" }}>
              <ExpandableImage src={p} />
            </span>
          ))}
      </Box>
    );
  }
}
