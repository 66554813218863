import React from "react";
import { Avatar, Box, Chip, Grid, Paper, Typography } from "@mui/material";
import ImageCarousel from "../../common/component/ImageCarousel";
import IconText from "../../common/component/IconText";
import { EventOutlined, LocalShippingOutlined, LocalMallOutlined, Check, Cancel } from "@mui/icons-material";
import moment from "moment";
import { BAKER_PIC_FOLDER, STORAGE_BUCKET, bakeStreetSquareLogo } from "../../common/component/constants";
import currency from "currency.js";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import MoneyOutlinedIcon from "@mui/icons-material/MoneyOutlined";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import OrderProgress from "./OrderProgress";
import OrderPrint from "./OrderPrint";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";

export default function OrderSummary(props) {
  const { order } = props;

  const printed = order?.activity?.print;
  const lastPrintTs = printed && printed[printed.length - 1].ts;

  let images = order.details.pics || [];
  if (images.length === 0 && order.items) {
    for (const item of order.items) {
      images.push(...item.images);
    }
  }

  return (
    <Paper elevation={0} sx={{ marginBottom: "1rem" }}>
      <Box px={2} pt={2} pb={1}>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={3}>
            <ImageCarousel images={images} />
          </Grid>
          <Grid item sm={8} xs={9}>
            <Typography variant="h6" gutterBottom>
              {order.customer.firstName || order.customer.name} {order.customer.lastName}
              {order.test && <Chip color="info" label="test" size="small" />}
              {(order.status === "pending" || order.status === "pending_review") && <Chip color="error" label="pending" size="small" />}
            </Typography>
            <Box style={{ color: "#888" }}>
              {order.fulfillment && (
                <IconText
                  icon={EventOutlined}
                  text={`${moment(order.fulfillment.date).format("MMM DD")} ${order.fulfillment.time && `[${order.fulfillment.time}]`}`}
                />
              )}
              {order.payment && order.fulfillment.type && !order.fulfillment.provider ? (
                <IconText icon={order.fulfillment.type === "delivery" ? LocalShippingOutlined : LocalMallOutlined} text={order.fulfillment.type} />
              ) : (
                <Typography style={{ display: "flex", alignItems: "center", fontSize: ".8em", gap: "5px", textTransform: "uppercase" }}>
                  <Avatar
                    style={{ width: "20px", height: "20px" }}
                    src={`${STORAGE_BUCKET}/img/logo/${order.fulfillment.provider.name.toLowerCase()}.webp`}
                  ></Avatar>
                  {order.fulfillment.provider.name}
                  {order.fulfillment.provider.deliveryId ? <Check /> : <Cancel />}
                </Typography>
              )}
              <IconText icon={ReceiptLongOutlinedIcon} text={`${order.items?.length || "1"} items`} />
            </Box>
          </Grid>
        </Grid>
        <Box mt={1}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <Box sx={{ display: "flex", gap: "5px", justifyContent: "flex-start", alignItems: "center" }}>
                {order.paymentType === "offline" ? (
                  <MoneyOutlinedIcon sx={{ color: "#d63031" }} />
                ) : order.paymentType === "moto" ? (
                  <LocalPhoneIcon />
                ) : (
                  <CreditCardOutlinedIcon sx={{ color: "#00b894" }} />
                )}
                <Box>
                  <Box sx={{ fontSize: "12px" }}>{currency(order.payment.total, { precision: 0 }).format()} </Box>
                  <Box sx={{ fontSize: "9px", color: "#999" }}>({moment(order.ts._seconds * 1000).fromNow()})</Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end", alignItems: "center" }}>
                {order.progress && <OrderProgress progress={order.progress} orderId={order.id} />}
                {printed && lastPrintTs && <OrderPrint order={order} />}
                {order.kitchen && <StickyNote2OutlinedIcon sx={{ color: "#999" }} />}
                {order.autoQuote && <AutoAwesomeOutlinedIcon sx={{ color: "#999" }} />}
                {order.src === "mkt" && <img src={bakeStreetSquareLogo} style={{ width: "32px", height: "32px" }} />}
                <Avatar src={`${BAKER_PIC_FOLDER}/${order.baker.id}/logo400.webp`} style={{ width: "32px", height: "32px" }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
