import React, { useState, useEffect } from "react";
import { Backdrop, Box, CircularProgress, Container, Chip, Grid, LinearProgress, Typography, IconButton, Paper, Avatar } from "@mui/material";
import bkstApi from "../../common/component/api";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";
import ClickToCopy from "../../common/component/ClickToCopy";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

export default function Logins(props) {
  const [logins, setLogins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    bkstApi.get(`/admin/login/get-all-reps`).then((res) => {
      const sortedList = res.data.sort((o1, o2) => (o1.email < o2.email ? -1 : 1));
      setLogins(sortedList);
      setLoading(false);
      setProcessing(false);
    });
  };

  const disableLogin = (uid, disable) => {
    setProcessing(true);
    bkstApi.put(`/admin/login/${uid}?disabled=${disable}`).then((res) => {
      getData();
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box my={7}>
        <Container maxWidth="sm">
          <Box my={2}>
            <Typography variant="h4" gutterBottom>
              Rep Logins
            </Typography>
          </Box>
          {logins.map((o) => {
            return (
              <Paper elevation={0}>
                <Box p={2} mb={2} key={o.uid} sx={{ border: "1px solid #EEE" }}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={2}>
                      <Avatar src={`${BAKER_PIC_FOLDER}/${o.customClaims.acctId || o.uid}/logo400.webp`} />
                    </Grid>
                    <Grid item xs={4}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton onClick={() => disableLogin(o.uid, !o.disabled)}>
                          {o.disabled ? <LockPersonOutlinedIcon /> : <LockOpenOutlinedIcon sx={{ color: "#c0392b" }} />}
                        </IconButton>
                        <Chip size="small" label={o.customClaims.role} />
                      </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ color: "#888" }}>
                      <ClickToCopy icon={ContentCopyOutlinedIcon} text={o.email} />
                      {o.email}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            );
          })}
        </Container>
        {processing && (
          <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>
    );
  }
}
