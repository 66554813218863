import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@mui/material";
import PicUpload from "../../common/component/PicUpload";
import bkstApi from "../../common/component/api";
import { useParams, Link as RouterLink } from "react-router-dom";
import PhoneField from "../../common/component/PhoneField";
import { useNavigate } from "react-router";

export default function Baker(props) {
  const navigate = useNavigate();
  const { bakerId } = useParams();

  const [bizType, setBizType] = useState("");
  const [bio, setBio] = useState("");
  const [portfolio, setPortfolio] = useState("");
  const [website, setWebsite] = useState("");
  const [yelp, setYelp] = useState("");
  const [fb, setFb] = useState("");
  const [ig, setIg] = useState("");
  const [policy, setPolicy] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [phone, setPhone] = useState("");
  const [notes, setNotes] = useState("");
  const [plan, setPlan] = useState("");
  const [street, setStreet] = useState("");
  const [proxyPhone, setProxyPhone] = useState("");
  const [tollFreePhone, setTollFreePhone] = useState("");

  const [orderInstructions, setOrderInstructions] = useState("");
  const [tags, setTags] = useState("");
  const [photo, setPhoto] = useState("");
  const [logo, setLogo] = useState("");

  const [name, setName] = useState("");
  const [bizName, setBizName] = useState("");
  const [status, setStatus] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/admin/bakers/${bakerId}`).then((res) => {
      const { baker } = res.data;
      const pub = res.data.public;
      const pvt = res.data.private;

      setBizType(pub.bizType);
      setBio(pub.bio);
      setPortfolio(pub.portfolio);
      setWebsite(pub.website);
      setYelp(pub.yelp);
      setFb(pub.fb);
      setIg(pub.ig);
      setPolicy(pub.policy);

      setFirstName(pvt.firstName);
      setLastName(pvt.lastName);
      setEmail(pvt.email);
      setMobile(pvt.mobile);
      setPhone(pvt.phone);
      setNotes(pvt.notes);
      setPlan(pvt.plan);
      setStreet(pvt.street);
      setProxyPhone(pvt.proxyPhone);
      setTollFreePhone(pvt.tollFreePhone);
      setOrderInstructions(pvt.orderInstructions);
      setTags(pvt.tags);
      setPhoto(pvt.photo);
      setLogo(pvt.logo);

      setName(baker.name);
      setBizName(baker.bizName);
      setStatus(baker.status);
      setCity(baker.location?.city);
      setState(baker.location?.state);
      setZip(baker.location?.zip);

      setLoading(false);
    });
  }, [bakerId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const publicData = {
      bizType,
      bio,
      portfolio,
      website,
      yelp,
      fb,
      ig,
      policy
    };
    const privateData = {
      firstName,
      lastName,
      email,
      mobile,
      phone,
      notes,
      plan,
      street,
      proxyPhone,
      tollFreePhone,
      orderInstructions,
      tags,
      photo,
      logo
    };
    const baker = {
      name,
      bizName,
      status,
      location: {
        city,
        state,
        zip
      }
    };

    bkstApi({
      method: "PUT",
      url: `/admin/bakers/${bakerId}`,
      data: { baker, public: publicData, private: privateData }
    }).then((res) => {
      console.log("Success:", res.data);
      navigate(`/baker/${bakerId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <div>
        <Container maxWidth="md">
          <Box mb={1} mt={5}>
            <Typography variant="overline" gutterBottom>
              {bizName}
            </Typography>
            <Paper elevation={3} style={{ padding: "30px 50px", marginBottom: "50px" }}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">BakeStreet Profile</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} align="center">
                    <PicUpload id="photo" onChange={(url) => setPhoto(url)} folder={`img/b/${bakerId}/p`} width="100%" required src={photo} />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Box mb={3}>
                      <TextField
                        name="name"
                        value={name}
                        variant="outlined"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        autoFocus
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Box>
                    <TextField
                      id="bio"
                      value={bio}
                      label="Bio"
                      name="bio"
                      variant="outlined"
                      multiline
                      rows={3}
                      fullWidth
                      onChange={(e) => setBio(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Business Profile</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3} align="center">
                    <PicUpload id="logo" onChange={(url) => setLogo(url)} folder={`img/b/${bakerId}/l`} width="100%" required src={logo} />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Box mb={2}>
                      <TextField
                        name="bizName"
                        value={bizName}
                        variant="outlined"
                        required
                        fullWidth
                        id="bizName"
                        label="Business Name"
                        onChange={(e) => setBizName(e.target.value)}
                      />
                    </Box>
                    <Box mb={2}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="bizType-label">Business Type</InputLabel>
                        <Select
                          value={bizType}
                          labelId="bizType-label"
                          label="Business Type"
                          id="bizType"
                          name="bizType"
                          onChange={(e) => setBizType(e.target.value)}
                        >
                          <MenuItem value="bakery">Bakery</MenuItem>
                          <MenuItem value="ck">Commercial kitchen</MenuItem>
                          <MenuItem value="home">Home baker</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={website}
                      variant="outlined"
                      fullWidth
                      id="website"
                      label="Website"
                      name="website"
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      value={yelp}
                      variant="outlined"
                      fullWidth
                      id="yelp"
                      label="Yelp"
                      name="yelp"
                      onChange={(e) => setYelp(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField value={fb} variant="outlined" fullWidth id="fb" label="Facebook" name="fb" onChange={(e) => setFb(e.target.value)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField value={ig} variant="outlined" fullWidth id="ig" label="Instagram" name="ig" onChange={(e) => setIg(e.target.value)} />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle1">Address</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      value={street}
                      name="street"
                      variant="outlined"
                      fullWidth
                      id="street"
                      label="Street"
                      onChange={(e) => setStreet(e.target.value)}
                      disabled={status === "onboard"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      value={city}
                      name="city"
                      variant="outlined"
                      fullWidth
                      id="city"
                      label="City"
                      onChange={(e) => setCity(e.target.value)}
                      disabled={status === "onboard"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      value={state}
                      name="state"
                      variant="outlined"
                      fullWidth
                      id="state"
                      label="State"
                      onChange={(e) => setState(e.target.value)}
                      disabled={status === "onboard"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={zip}
                      name="zip"
                      variant="outlined"
                      fullWidth
                      id="zip"
                      label="Zip"
                      onChange={(e) => setZip(e.target.value)}
                      disabled={status === "onboard"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle1">Contact</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={firstName}
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      id="firstName"
                      label="First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      value={lastName}
                      variant="outlined"
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      value={email}
                      name="email"
                      variant="outlined"
                      fullWidth
                      id="email"
                      label="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} />
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth>
                      <PhoneField name="Phone" value={phone} onChange={(p) => setPhone(p)} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <PhoneField name="Mobile" value={mobile} onChange={(p) => setMobile(p)} />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle1">Order-related</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <PhoneField name="Proxy Phone" value={proxyPhone} onChange={(p) => setProxyPhone(p)} disabled={true} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <PhoneField name="Toll-free Phone" value={tollFreePhone} onChange={(p) => setTollFreePhone(p)} disabled={true} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={orderInstructions}
                        variant="outlined"
                        fullWidth
                        id="orderInstructions"
                        label="Order Instructions"
                        name="orderInstructions"
                        multiline
                        rows={2}
                        onChange={(e) => setOrderInstructions(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="subtitle1">Admin</Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl variant="outlined" fullWidth disabled={status === "onboard"}>
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        value={status}
                        labelId="status-label"
                        label="Status"
                        id="status"
                        name="status"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value="scout">Scout</MenuItem>
                        <MenuItem value="new">New</MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="quote">Quote</MenuItem>
                        <MenuItem value="onboard">Onboard</MenuItem>
                        <MenuItem value="offboard">Offboard</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="plan-label">Plan</InputLabel>
                      <Select value={plan} labelId="plan-label" label="plan" id="plan" name="plan" onChange={(e) => setPlan(e.target.value)}>
                        <MenuItem value="">Free</MenuItem>
                        <MenuItem value="starter">Starter</MenuItem>
                        <MenuItem value="professional">Professional</MenuItem>
                        <MenuItem value="premium">Premium</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      value={tags}
                      onChange={(e, v) => setTags(v)}
                      options={[
                        "adult",
                        "auto-quote",
                        "budget",
                        "buttercream",
                        "cookies",
                        "far-delivery",
                        "floral",
                        "fondant",
                        "kids",
                        "last-min",
                        "sculpted",
                        "wedding"
                      ]}
                      renderInput={(params) => <TextField {...params} variant="outlined" label="Tags" />}
                      filterSelectedOptions
                      disableClearable
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        value={notes}
                        variant="outlined"
                        fullWidth
                        id="notes"
                        label="Notes"
                        name="notes"
                        multiline
                        rows={3}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Box mt={4} align="right">
                  <Button variant="text" color="primary" size="large" component={RouterLink} to={`/baker/${bakerId}`}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" size="large" type="submit" style={{ marginLeft: "20px" }}>
                    Save
                  </Button>
                </Box>
              </form>
            </Paper>
          </Box>
        </Container>
      </div>
    );
  }
}
