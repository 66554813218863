import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, Radio, RadioGroup } from "@mui/material";
import bkstApi from "../../common/component/api";
import TrafficOutlinedIcon from "@mui/icons-material/TrafficOutlined";

export default function OrderStatus(props) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(props.status);

  const updateStatus = () => {
    bkstApi
      .put(`/admin/order/${props.orderId}/status`, { status })
      .then((res) => {
        setOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Failure");
      });
  };

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={() => setOpen(true)}>
        <TrafficOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <RadioGroup name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
            {/* <FormControlLabel value="pending" control={<Radio required={true} />} label="Pending" /> */}
            <FormControlLabel value="confirmed" control={<Radio required={true} />} label="Confirmed" />
            <FormControlLabel value="fulfilled" control={<Radio required={true} />} label="Fulfilled" />
            <FormControlLabel value="completed" control={<Radio required={true} />} label="Completed" />
            <FormControlLabel value="canceled" control={<Radio required={true} />} label="Canceled" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Box align="right">
            <Button onClick={() => setOpen(false)} color="primary">
              Close
            </Button>
            <Button variant="contained" onClick={updateStatus} color="primary" style={{ marginLeft: "2rem" }}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
