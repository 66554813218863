import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";

export default function SearchSummary(props) {
  const [unavailable, setUnavailable] = useState([]);
  const [pending, setPending] = useState([]);
  const [quotes, setQuotes] = useState([]);

  const request = props.request;

  useEffect(() => {
    if (props.request.quotes) {
      setUnavailable(props.request.quotes.filter((q) => ["unavailable", "block"].includes(q.status)));
      setQuotes(props.request.quotes.filter((q) => ["active", "rbook", "expired"].includes(q.status)));
      setPending(props.request.quotes.filter((q) => ["pending"].includes(q.status)));
    }
  }, []);

  return (
    <Box my={2} align="center">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box py={2} style={{ backgroundColor: "#ECECEC", borderRadius: "3px" }}>
            <Typography variant="h3">{quotes.length}</Typography>
            <Typography variant="overline">QUOTES</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box py={2} style={{ backgroundColor: "#ECECEC", borderRadius: "3px" }}>
            <Typography variant="h3">{unavailable.length}</Typography>
            <Typography variant="overline">UNAVAILABLE</Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box py={2} style={{ backgroundColor: "#ECECEC", borderRadius: "3px" }}>
            <Typography variant="h3">{request.quotes.length}</Typography>
            <Typography variant="overline">CONTACTED</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
