import React from "react";
import { Chip } from "@mui/material";

class BakerStatus extends React.Component {

  getColorByStatus(status){
    if(status === 'onboard'){
      return '#1abc9c';
    } else if(status === 'quote'){
      return '#f39c12';  
    } else if(status === 'pending'){
      return '#f1c40f';
    } else if(status === 'new'){
      return '#2980b9';
    } else if(status === 'scout'){
      return '#34495e';  
    } else if(status === 'offboard'){
      return '#e74c3c';  
    }
  }

  render() {
      return (
        <Chip label={this.props.status} size="small" style={{color: "#fff", backgroundColor: this.getColorByStatus(this.props.status)}}/>
      );
  }
}
export default BakerStatus;
