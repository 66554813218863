import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/messaging";

firebase.initializeApp({
  apiKey: "AIzaSyBITuslVfrmivsFNZ5639CIwQWmsEKai6o",
  authDomain: "bakestreet-f55a0.firebaseapp.com",
  databaseURL: "https://bakestreet-f55a0.firebaseio.com",
  projectId: "bakestreet-f55a0",
  storageBucket: "bakestreet-f55a0.appspot.com",
  messagingSenderId: "687010686765",
  appId: "1:687010686765:web:7abe8ce019d9e3f71ed5a4",
  measurementId: "G-XME74LCYJY"
});

export const auth = firebase.auth();

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export const upload = (file, folder) => {
  console.log("upload", file, folder);
  console.log(`${file.name} -> ${file.size}`);
  var storageRef = firebase.storage().ref();
  var uploadTask = storageRef.child(`${folder}/${Date.now()}-${file.name}`).put(file);
  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    function (snapshot) {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log("Upload is running");
          break;
        default:
          console.log("default");
      }
    },
    function (error) {
      console.log(error);
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          console.log("unauthorized");
          break;

        case "storage/canceled":
          // User canceled the upload
          console.log("canceled");
          break;

        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          console.log("unknown");
          break;
        default:
          break;
      }
    },
    function () {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        console.log("File available at", downloadURL);
        return downloadURL;
      });
    }
  );

  return uploadTask;
};

export const getFileUrlsFromFolder = async (folder) => {
  let urls = [];
  var storageRef = firebase.storage().ref(folder);
  const files = await storageRef.listAll();

  let promises = [];
  for (let i = 0; i < files.items.length; i++) {
    const imageRef = files.items[i];
    promises.push(imageRef.getDownloadURL());
  }

  urls = await Promise.all(promises);
  return urls;
};

export const getDeviceToken = async () => {
  console.log('inide getDeviceToken');
  if (firebase.messaging.isSupported()) {
    const token = await messaging.getToken({ vapidKey: "BKvvkjxXiN8mAVC3YIbrjIeAFT2kPepiBqOJnEpnLsckWdQx_TXGqMc4VT02Z7VhrvTf3Cznc4viYNkzdH3HR6s" });
    console.log('token', token);
    return token;
  }
};

export const handleFcmMessage = () => {
  if (firebase.messaging.isSupported()) {
    new Promise((resolve) => {
      messaging.onMessage((payload) => {
        console.log('------>', payload);
        resolve(payload);
      });
    });
  }
};
