import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { ALL_SIZES, SHAPE_SIZE_MAP } from "../../common/component/constants";

export default function ProductSizeAutocomplete(props) {
  const { productType, shape, value } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (productType === "Cake") {
      let arr = [];
      /*if (user.config?.sizeChart) {
        arr = (user.config.sizeChart[productType]?.[shape] || []).map((o) => o.size).sort(sortByNumericPrefix);
      }*/

      if (arr.length === 0) {
        arr = SHAPE_SIZE_MAP[shape] || ALL_SIZES;
      }
      setOptions(arr);
    } else if (productType === "Cake Jars") {
      setOptions(["Single (8oz Jar)", "Set of 6 (8oz Jar)", "Set of 12 (8oz Jar)"]);
    } else if (productType === "Pie") {
      setOptions(["Small", "Medium", "Large"]);
    } else {
      setOptions(["Single", "Set of 6", "Set of 12"]);
    }
  }, [productType, shape]);

  const onChange = (e, v) => {
    props.onChange(v);
  };

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onChange}
      options={options}
      renderInput={(params) => <TextField required={props.required} variant="outlined" {...params} label={props.label} />}
      disableClearable
      freeSolo
      blurOnSelect={true}
    />
  );
}
