import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import bkstApi from "../../common/component/api";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import ChatCanvas from "../../common/component/chat/ChatCanvas";
import NewMessage from "../../common/component/chat/NewMessage";

export default function OrderChat(props) {
  const { orderId } = props;
  const ref = useRef(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(window.location.hash.includes("chat"));
  const [chat, setChat] = useState(props.chat || []);

  useEffect(() => {
    if (open) {
      bkstApi(`/my/order/${orderId}/chat`).then((res) => {
        setChat(res.data.chat);
      });
    }
  }, [open]);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ block: "end" });
    }
  }, [chat]);

  const handleNewMsg = (msg) => {
    const allMsgs = [...chat, msg];
    setChat(allMsgs);
  };

  return (
    <span>
      <IconButton onClick={(e) => setOpen(true)}>
        <SmsOutlinedIcon />
      </IconButton>
      <Dialog fullScreen={fullScreen} fullWidth maxWidth={fullScreen ? false : "sm"} open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ margin: "0" }}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Contact {props.name}</Box>
            <Box>
              <IconButton color="inherit" size="small" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className="chat-bg" style={{ minHeight: "50vh", padding: "0", margin: "0", display: "flex" }}>
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
            <ChatCanvas chat={chat} uid={props.uid} />
            {/* <Button fullWidth color="primary" variant="text" onClick={() => setOpen(false)}>
              VIEW ORDER
            </Button>
            <NewMessage handleNewMsg={handleNewMsg} uploadFolder={`img/o/${orderId}`} postUrl={`/my/order/${orderId}/chat`} />
  <Box pt={2} ref={ref} />*/}
          </Box>
        </DialogContent>
      </Dialog>
    </span>
  );
}
