import React from "react";
import moment from "moment";
import QuoteItemV5 from "./QuoteItemV5";
import QuoteItemV5Direct from "./QuoteItemV5Direct";
import bkstApi from "../../common/component/api";
import SaasQuote from "./SaaSQuote";

export default function QuoteItem(props) {
  let { quote, request } = props;

  const addEvent = (eventType) => {
    bkstApi.post(`/request/${request.id}/quote/${quote.id}/event`, {
      eventType
    });
  };

  let { status, exdate } = props.quote;
  quote.expiration = exdate && exdate.includes("T") ? exdate : `${exdate}T23:59`;

  const daysToEvent = moment(request.date).diff(moment(), "days");
  let showDepositOption = request.cid && daysToEvent > 7;

  if (status === "active" && moment(quote.expiration).diff(moment(), "minutes") < 0) {
    quote.status = "expired";
  } else if (status === "block") {
    quote.status = "unavailable";
  }

  if (request.src === "direct") {
    if (quote.items) {
      return <SaasQuote request={request} quote={quote} />;
    } else {
      return <QuoteItemV5Direct request={request} quote={quote} addEvent={addEvent} showDepositOption={showDepositOption} />;
    }
  } else {
    return <QuoteItemV5 request={request} quote={quote} addEvent={addEvent} showDepositOption={showDepositOption} />;
  }
}
