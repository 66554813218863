import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { FROSTING } from "../../common/component/constants";

export default function FrostingAutocomplete(props) {
  const { productType, value } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (productType === "Cake" || productType === "Cupcakes") {
      setOptions(FROSTING);
    } else if (productType === "Cookies") {
      setOptions(["Fondant", "Royal Icing", "Sugar"]);
    } else if (productType === "Cake Pops") {
      setOptions(["Candy Melts", "Chocolate"]);
    }
  }, [productType]);

  const onChange = (e, v) => {
    props.onChange(v);
  };

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onChange}
      options={options}
      renderInput={(params) => <TextField required={props.required} variant="outlined" {...params} label={props.label} />}
      disableClearable
      freeSolo
      blurOnSelect={true}
    />
  );
}
