import React, { useState } from "react";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, RadioGroup } from "@mui/material";
import bkstApi from "../../common/component/api";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NotifyOption from "../../request/component/NotifyOption";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";
import { useNavigate } from "react-router";

export const options = [
  { code: "coc", desc: "Order confirmation" },
  { code: "bpr", desc: "Balance payment reminder" },
  { code: "bpf", desc: "Balance payment failed" },
  { code: "bpf2", desc: "2nd Balance payment failed" },
  { code: "bpcnf", desc: "Balance payment confirmation" },
  { code: "pndcxl", desc: "Pending CXL: No balance payment" },
  { code: "bpcxl", desc: "CXL: No balance payment" },
  { code: "crm", desc: "Reminder" },
  { code: "rv", desc: "Review" },
  { code: "bpc", desc: "Payment Transfer" },
  { code: "brv", desc: "Review posted" },
  { code: "1yr", desc: "1YR Remarketing" }
];

export default function OrderNotificationButton(props) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [code, setCode] = useState();
  const [trail, setTrail] = useState();
  const [order, setOrder] = useState();

  const [processing, setProcessing] = useState(false);

  const handleClick = () => {
    bkstApi(`/notify?reqId=${props.reqId}&orderId=${props.orderId}`).then((res) => {
      setTrail(res.data.trail);
      setOrder(res.data.order);
      setOpen(true);
    });
  };

  const notify = (confirm) => {
    setProcessing(true);
    bkstApi.post(`/order/${props.orderId}/notify`, { code, confirm }).then((res) => {
      handleClick();
      setProcessing(false);
      navigate(0);
    });
  };

  const isOptionEnabled = (code) => {
    if (order) {
      const daysToEvent = moment(order.date).diff(moment(), "days");
      console.log("days", daysToEvent);

      if (code === "coc") {
        return order.status === "pending" || order.status === "confirmed";
      } else if (code === "crm") {
        return order.status === "confirmed";
      } else if (code === "bpr") {
        return order.status === "confirmed" && +order.due > 0;
      } else if (code === "bpf") {
        return order.status === "confirmed" && +order.due > 0 && daysToEvent < 7;
      } else if (code === "bpf2") {
        return order.status === "confirmed" && +order.due > 0 && daysToEvent < 6;
      } else if (code === "bpcnf") {
        return order.status === "confirmed" && +order.due === 0;
      } else if (code === "bpndcxl") {
        return order.src === "direct" && daysToEvent < 5 && +order.due > 0;
      } else if (code === "bpcxl") {
        return order.src === "direct" && order.status === "canceled" && +order.due > 0;
      } else if (code === "rv") {
        return order.status === "fulfilled" && !order.reviewId;
      } else if (code === "brv") {
        return order.reviewId;
      } else if (code === "bpc") {
        return order.status === "fulfilled" && order.transferId;
      } else if (code === "1yr") {
        return order.status === "completed" && order.src === "mkt";
      }
    }
    return true;
  };

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={handleClick} disabled={props.disabled}>
        <NotificationsActiveOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>Send Notification</DialogTitle>
        <DialogContent>
          <RadioGroup aria-label="code" name="code" value={code} onChange={(e) => setCode(e.target.value)}>
            {options &&
              options.map((item) => {
                return (
                  <NotifyOption
                    key={item.code}
                    code={item.code}
                    label={item.desc}
                    trail={trail && trail[item.code]}
                    disabled={!isOptionEnabled(item.code)}
                  />
                );
              })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setOpen(false)} style={{ position: "absolute", top: 0, right: 0 }}>
            <HighlightOffIcon />
          </IconButton>
          <Button onClick={() => notify(true)} color="primary" variant="contained" disabled={!code}>
            Send
          </Button>
        </DialogActions>
        {processing && (
          <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Dialog>
    </Box>
  );
}
