import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import { SIZE_SERVINGS_MAP } from "../../common/component/constants";
import ProductTypeAutocomplete from "./ProductTypeAutocomplete";
import ProductShapeAutocomplete from "./ProductShapeAutocomplete";
import ProductSizeAutocomplete from "./ProductSizeAutocomplete";
import ProductServingsAutocomplete from "./ProductServingsAutocomplete";
import FrostingAutocomplete from "./FrostingAutocomplete";

export default function ProductAttributes(props) {
  const [productType, setProductType] = useState(props.value.productType || "Cake");
  const [shape, setShape] = useState(props.value.shape || "");
  const [size, setSize] = useState(props.value.size || "");
  const [frosting, setFrosting] = useState(props.value.frosting || "");
  const [servings, setServings] = useState(props.value.servings || "");

  const handleProductTypeChange = (val) => {
    setProductType(val);
    if (val === "Cake") {
      setShape("");
      setSize("");
    } else {
      setShape("Custom");
      setSize("Custom");
    }
    setServings("");
    setFrosting("");
  };

  const handleShapeChange = (val) => {
    setShape(val);
    setSize("");
    setServings("");
  };

  const handleSizeChange = (val) => {
    setSize(val);
    if (productType === "Cake") {
      setServings(SIZE_SERVINGS_MAP[val]);
    } else {
      setServings("");
    }
  };

  useEffect(() => {
    props.onChange({ productType, shape, size, servings, frosting });
  }, [productType, shape, size, servings, frosting]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <ProductTypeAutocomplete value={productType} label="Product Type" onChange={handleProductTypeChange} required={props.required || false} />
      </Grid>
      {productType === "Cake" && (
        <Grid item xs={6} sm={6}>
          <ProductShapeAutocomplete
            productType={productType}
            value={shape}
            label="Shape"
            onChange={handleShapeChange}
            required={props.required || false}
          />
        </Grid>
      )}
      {productType === "Cake" && (
        <Grid item xs={6} sm={6}>
          <ProductSizeAutocomplete
            productType={productType}
            shape={shape}
            value={size}
            label="Size"
            onChange={handleSizeChange}
            required={props.required || false}
          />
        </Grid>
      )}
      <Grid item xs={6} sm={6}>
        <ProductServingsAutocomplete
          productType={productType}
          value={servings}
          label={productType === "Cake" ? "Servings" : "Quantity"}
          onChange={(v) => setServings(v)}
          required={props.required || false}
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <FrostingAutocomplete
          productType={productType}
          value={frosting}
          label="Frosting"
          onChange={(v) => setFrosting(v)}
          required={props.required || false}
        />
      </Grid>
    </Grid>
  );
}
