import React from "react";
import { Chip } from "@mui/material";

class RequestStatus extends React.Component {
  getColorByStatus(status) {
    if (status === "new") {
      return "#3498db";
    } else if (status === "pending" || status === "notified") {
      return "#e67e22";
    } else if (status === "closed") {
      return "#636e72";
    }
  }

  getTitle(status) {
    return status.split("-").pop();
  }

  render() {
    return (
      <Chip
        size="small"
        label={this.getTitle(this.props.status)}
        style={{ backgroundColor: this.getColorByStatus(this.props.status), color: "#ffffff" }}
      />
    );
  }
}
export default RequestStatus;
