import React, { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { formatPhoneNumber } from "./util";
import ClickToCopy from "./ClickToCopy";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

export default function ContactDetailsButton(props) {
  const { name, phone, email } = props;
  const [open, setOpen] = useState(false);

  return (
    <span>
      <IconButton onClick={() => setOpen(true)}>
        <PermIdentityOutlinedIcon style={{ borderBottom: props.verified ? "2px solid #00b894" : "inherit" }} />
      </IconButton>
      <Dialog onClose={() => setOpen(false)} open={open} maxWidth="xs" fullWidth>
        <DialogTitle>
          {`Contact ${name}`}{" "}
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box py={4} align="center">
            <Box>
              <Box>
                <Button
                  fullWidth
                  size="large"
                  href={`tel:${phone}`}
                  variant="contained"
                  color="secondary"
                  startIcon={<PhoneOutlinedIcon />}
                  style={{ marginRight: "15px" }}
                  disableElevation
                  onClick={() => setOpen(false)}
                >
                  Call
                </Button>
              </Box>
              <Box mt={0.5}>
                <Typography variant="overline" color="textSecondary">
                  {formatPhoneNumber(phone)}
                </Typography>
                <ClickToCopy icon={ContentCopyOutlinedIcon} text={phone} />
              </Box>
            </Box>
            {email && (
              <Box mt={2}>
                <Button
                  fullWidth
                  size="large"
                  href={`mailto:${email}`}
                  variant="outlined"
                  color="secondary"
                  startIcon={<EmailOutlinedIcon />}
                  disableElevation
                  onClick={() => setOpen(false)}
                >
                  Email
                </Button>
                <Box mt={0.5}>
                  <Typography variant="overline" color="textSecondary">
                    {email}
                    <ClickToCopy icon={ContentCopyOutlinedIcon} text={email} />
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </span>
  );
}
