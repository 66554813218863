import React, { useState, useEffect } from "react";
import { Badge, Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import ChatWidget from "../../common/component/chat/ChatWidget";
import CloseIcon from "@mui/icons-material/Close";
import bkstApi from "../../common/component/api";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function AdminChatWithBaker(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [chat, setChat] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      bkstApi(`/baker/${props.bakerId}/chat`).then((res) => {
        setChat(res.data.chat);
        setLoading(false);
      });
    }
  }, [open]);

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={() => setOpen(true)}>
        <SmsOutlinedIcon />
      </IconButton>
      <Dialog fullScreen={fullScreen} open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ margin: "0" }}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Contact {props.name}</Box>
            <Box>
              <IconButton color="inherit" size="small" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {!loading && <ChatWidget uid="bkst" uploadFolder={`img/b/${props.bakerId}`} postUrl={`/baker/${props.bakerId}/send-sms`} chat={chat} />}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
