import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Select,
  Typography,
  Alert
} from "@mui/material";
import bkstApi from "../../common/component/api";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import Images from "../../common/component/Images";
import { Link as RouterLink } from "react-router-dom";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import currency from "currency.js";
import CloseIcon from "@mui/icons-material/Close";
import { getAmountWithTxnFee } from "../../common/component/util";
import RequestHeader from "./RequestHeader";

export default function GenerateAutoQuotesV3(props) {
  const { request, bakerId } = props;
  const [open, setOpen] = useState(false);

  const [data, setData] = useState("");

  const [productType, setProductType] = useState("Cake");
  const [productSubtype, setProductSubtype] = useState("");

  const [references, setReferences] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setLoading(true);
      bkstApi
        .post(`/request/${request.id}/quote/model`, {
          quotes: [{ id: bakerId }]
        })
        .then((res) => {
          setData(res.data.data);
          setLoading(false);
        });
    }
  }, [open]);

  const addItem = (product, level) => {
    let variant = { size: { size: product.size } };
    if (product.shape) {
      variant.size.shape = product.shape;
    }
    if (product.servings) {
      variant.size.servings = product.servings;
    }
    if (product.frosting) {
      variant.frosting = { name: product.frosting };
    }

    let item = {
      images: request.pics || [],
      customization: {},
      tags: [],
      description: request.details ? `We can accommodate your request "${request.details}"` : "",
      variant,
      price: level.amount,
      quantity: 1,
      includeFees: true,
      total: getAmountWithTxnFee(level.amount),
      title: productType,
      type: productType
    };

    props.onChange(item);
  };

  let productTypes = [];
  for (const acctId in data) {
    const acctModel = data[acctId].model;
    if (acctModel) {
      productTypes.push(...Object.keys(acctModel));
    }
  }
  productTypes = [...new Set(productTypes)].sort();

  let productSubtypes = [];
  for (const acctId in data) {
    const acctModel = data[acctId].model;
    if (acctModel) {
      const acctModelSubtypes = acctModel[productType]?.type;
      if (acctModelSubtypes) {
        productSubtypes.push(...Object.keys(acctModelSubtypes));
      }
    }
  }
  productSubtypes = [...new Set(productSubtypes)].sort();

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={() => setOpen(true)} disabled={props.disabled}>
        <AttachMoneyOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="md">
        <DialogTitle>
          AI Quote
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon sx={{ color: "#999" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <LinearProgress />
          ) : (
            <Box>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <RequestHeader request={request} />
                  </Grid>
                  <Grid item xs={6}>
                    <Box mb={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="productType-label">Product Type</InputLabel>
                        <Select
                          value={productType}
                          label="Product Type"
                          id="productType"
                          name="productType"
                          onChange={(e) => setProductType(e.target.value)}
                        >
                          {productTypes.map((i) => (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="productSubtype-label">Product Subtype</InputLabel>
                      <Select
                        value={productSubtype}
                        label="Product Subtype"
                        id="productSubtype"
                        name="productSubtype"
                        onChange={(e) => setProductSubtype(e.target.value)}
                      >
                        {productSubtypes.map((i) => (
                          <MenuItem key={i} value={i}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              {data[bakerId]?.alerts?.notes && (
                <Box my={2}>
                  <Alert severity="info">{data[bakerId]?.alerts?.notes}</Alert>
                </Box>
              )}
              <Box>
                <Box py={2} sx={{ display: "flex", gap: "15px", flexWrap: "wrap" }}>
                  {data[bakerId]?.model?.[productType]?.type?.[productSubtype]?.map((o) => {
                    return (
                      <Box
                        px={0.5}
                        sx={{
                          border: "1px solid #EEE",
                          width: "300px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          flexGrow: 1,
                          textAlign: "center",
                          backgroundColor: "#FAFAFA"
                        }}
                      >
                        <Box py={2}>
                          <Typography variant="body2">
                            {o.product && `${o.product.frosting}, ${o.product.shape} ${o.product.size} (${o.product.servings} servings)`}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "5px", justifyContent: "space-between" }}>
                          {o.pricing.levels.map((l, idx) => (
                            <Box>
                              <Link
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  addItem(o.product, l);
                                  setOpen(false);
                                }}
                              >
                                <Chip label={idx + 1} />
                              </Link>
                              <Box>
                                <Typography variant="overline">{currency(l.amount, { precision: 0 }).format()}</Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Box px={2} py={4}>
                  {references.map((o) => {
                    return (
                      <Box mb={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={3} sm={2}>
                            <Images pics={o.pics} />
                          </Grid>
                          <Grid item xs={6} sm={8}>
                            <Typography variant="h6">
                              {currency(o.quote.quote).format()} {!o.quote.resTs && <AutoAwesomeOutlinedIcon sx={{ color: "#999" }} />}
                            </Typography>
                            <Link key={o.id} underline="none" component={RouterLink} to={`/request/${o.id}`} href="#">
                              <Typography variant="overline" color="textSecondary">
                                {o.title}
                              </Typography>
                            </Link>
                            <Typography variant="caption" display="block">
                              {o.quote.frosting} &middot; {o.quote.shape} {o.quote.size} &middot; {o.quote.servings}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {o.quote.note}
                            </Typography>
                            <Box>{o.quote.tags && o.quote.tags.map((e, idx) => <Chip size="small" label={e} />)}</Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
