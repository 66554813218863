import React, { useState } from "react";
import { Button, Box, Collapse, Link } from "@mui/material";

export default function ShowMore(props) {
  const { openCta, closeCta, fullWidth } = props;
  const [show, setShow] = useState(false);

  return (
    <>
      {!show && (
        <Link variant="body2" onClick={() => setShow(true)} sx={{ cursor: "pointer" }}>
          {openCta || "show more.."}
        </Link>
      )}
      {show && (
        <Link variant="body2" color="secondary" onClick={() => setShow(false)} sx={{ cursor: "pointer" }}>
          {closeCta || "show less.."}
        </Link>
      )}
      <Collapse in={show}>
        <Box px={2} py={2} sx={{ borderLeft: "5px solid #ECECEC" }}>
          {props.children}
        </Box>
      </Collapse>
    </>
  );
}
