import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from "@mui/material";
import OrderSummary from "../component/OrderSummary";
import bkstApi from "../../common/component/api";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { getQueryParam } from "../../common/component/util";

export default function OrderSearch() {
  const [searchBy, setSearchBy] = useState("due");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");
  const [status, setStatus] = useState("open");
  const [proxyNumber, setProxyNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const [showBakerSummary, setShowBakerSummary] = useState(false);
  const [orderSummaryByBaker, setOrderSummaryByBaker] = useState({});

  useEffect(() => {
    const proxyNumber = getQueryParam(window.location.search, "proxyNumber");
    if (proxyNumber) {
      setSearchBy("proxyNumber");
      setProxyNumber(proxyNumber);
      searchByProxyNumber(proxyNumber);
    }
  }, []);

  useEffect(() => {
    let summary = {};
    for (const o of results) {
      if (o.src === "mkt") {
        let bakerOrderSummary = summary[o.baker.id];
        if (bakerOrderSummary) {
          summary[o.baker.id].orders = +bakerOrderSummary.orders + 1;
          summary[o.baker.id].sales = +bakerOrderSummary.sales + +o.payment.total;
        } else {
          summary[o.baker.id] = { biz: o.baker.biz, orders: 1, sales: o.payment.total };
        }
      }
    }
    setOrderSummaryByBaker(summary);
  }, [results]);

  const download = (e, filename, url) => {
    e.preventDefault();
    const decodedUrl = decodeURI(url);
    console.log(decodedUrl);

    fetch(decodedUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style.display = "none";

        if (filename && filename.length) a.download = filename;
        document.body.appendChild(a);
        a.click();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchByProxyNumber = (number) => {
    setLoading(true);
    setFromDt("");
    setToDt("");

    bkstApi(`/admin/order?proxyNumber=${encodeURIComponent(number)}`).then((res) => {
      let arr = res.data;
      if (!status || status === "open") {
        arr = arr.filter((o) => o.status !== "completed");
      } else if (status === "closed") {
        arr = arr.filter((o) => o.status === "completed");
      }

      arr.sort((o1, o2) => (o1.fulfillment.date > o2.fulfillment.date ? 1 : -1));
      setResults(arr);
      setLoading(false);
    });
  };

  const search = (e) => {
    e.preventDefault();

    if (searchBy === "proxyNumber") {
      searchByProxyNumber(proxyNumber);
    } else {
      setLoading(true);
      setProxyNumber("");
      setStatus("open");

      const fromTs = moment(fromDt).startOf("day").valueOf();
      const toTs = moment(toDt).endOf("day").valueOf();

      bkstApi(`/admin/order?searchBy=${searchBy}&fromDt=${fromDt}&toDt=${toDt}&fromTs=${fromTs}&toTs=${toTs}`).then((res) => {
        setResults(res.data);
        setLoading(false);
      });
    }
  };

  const finalPics = [];
  const rows = [];
  for (const o of results) {
    rows.push(
      <Box key={o.id} mb={3}>
        <Link underline="none" target="_blank" to={`/order/${o.id}`} component={RouterLink}>
          <OrderSummary order={o} />
        </Link>
      </Box>
    );

    if (o.finalPics) {
      for (const pic of o.finalPics) {
        finalPics.push(
          <Grid item xs={3}>
            <img
              style={{ width: "100%", cursor: "pointer" }}
              src={pic}
              onClick={(e) => download(e, `${o.baker.biz}___${o.details.title}___${o.fulfillment.date}`, pic)}
            />
          </Grid>
        );
      }
    }
  }

  const searchBtn = (
    <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
      <SearchIcon />
    </Button>
  );

  return (
    <Container maxWidth="sm">
      <Box my={5}>
        <Typography variant="overline">Search Orders</Typography>
        <Paper>
          <Box px={3} py={3}>
            <Box pb={2} align="center">
              <FormControl component="fieldset">
                <RadioGroup row value={searchBy} name="searchBy" onChange={(e) => setSearchBy(e.target.value)}>
                  <FormControlLabel value="due" control={<Radio required={true} />} label="Order Due" />
                  <FormControlLabel value="placed" control={<Radio required={true} />} label="Order Placed" />
                  <FormControlLabel value="proxyNumber" control={<Radio required={true} />} label="Proxy Number" />
                </RadioGroup>
              </FormControl>
            </Box>
            <form onSubmit={search}>
              <Box py={2} align="center">
                {(searchBy === "due" || searchBy === "placed") && (
                  <Box>
                    <TextField
                      value={fromDt}
                      variant="outlined"
                      id="fromDt"
                      label="From Date"
                      name="fromDt"
                      onChange={(e) => setFromDt(e.target.value)}
                      type="date"
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      value={toDt}
                      variant="outlined"
                      type="date"
                      id="toDt"
                      label="To Date"
                      name="toDt"
                      onChange={(e) => setToDt(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                    {searchBtn}
                  </Box>
                )}
                {searchBy === "proxyNumber" && (
                  <Box>
                    <TextField
                      value={proxyNumber}
                      variant="outlined"
                      id="proxyNumber"
                      label="Proxy Number"
                      name="proxyNumber"
                      onChange={(e) => setProxyNumber(e.target.value)}
                    />
                    <FormControl variant="outlined">
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select
                        value={status}
                        labelId="status-label"
                        label="Status"
                        id="status"
                        name="status"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                        <MenuItem value="all">All</MenuItem>
                      </Select>
                    </FormControl>
                    {searchBtn}
                  </Box>
                )}
              </Box>
            </form>
          </Box>
        </Paper>
        {loading && <LinearProgress />}
      </Box>
      {results.length > 0 && (
        <Box align="center" mb={4}>
          <Button size="small" variant="text" color="secondary" onClick={() => setShowBakerSummary(true)} fullWidth>
            Baker Order Summary
          </Button>
        </Box>
      )}
      {rows}
      {finalPics.length > 0 && (
        <Box mt={10}>
          <Typography variant="h6" gutterBottom>
            Final pics
          </Typography>
          <Grid container>{finalPics}</Grid>
        </Box>
      )}
      <Dialog fullWidth maxWidth="sm" open={showBakerSummary} onClose={() => setShowBakerSummary(false)}>
        <DialogTitle>JSON</DialogTitle>
        <DialogContent>
          <Box>
            <pre>{JSON.stringify(orderSummaryByBaker, null, 2)}</pre>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  );
}
