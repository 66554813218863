import React from "react";
import { Box, Chip, Typography } from "@mui/material";
import HTMLViewer from "../HTMLViewer";
import { generateSizeSummary, normalizeFlavor } from "../util";

export default function LineItemDetails(props) {
  const { item } = props;

  const flavor = normalizeFlavor(item.variant?.flavor);

  return (
    <Box>
      {item.variant?.size && (
        <Box my={0.5}>
          <Typography variant="overline" color="textSecondary">
            SIZE
          </Typography>
          <Typography variant="body2">{generateSizeSummary(item.variant.size)}</Typography>
        </Box>
      )}
      {item.variant?.frosting?.name && (
        <Box my={0.5}>
          <Typography variant="overline" color="textSecondary">
            FROSTING
          </Typography>
          <Typography variant="body2">{item.variant.frosting.name}</Typography>
        </Box>
      )}
      {flavor && (
        <Box>
          {flavor.name && (
            <Box my={0.5}>
              <Typography variant="overline" color="textSecondary">
                FLAVOR
              </Typography>
              <Typography variant="body2">{flavor.name}</Typography>
            </Box>
          )}
          {flavor.filling && (
            <Box my={0.5}>
              <Typography variant="overline" color="textSecondary">
                FILLING
              </Typography>
              <Typography variant="body2">{flavor.filling}</Typography>
            </Box>
          )}
        </Box>
      )}
      {item.variant && Object.entries(item.variant).map(([key, value]) => {
        if (["size", "flavor", "frosting", "add-on"].includes(key)) {
          return <Box />;
        } else {
          return (
            <Box my={0.5}>
              <Typography variant="overline" color="textSecondary">
                {key}
              </Typography>
              <Typography variant="body2">{value?.name}</Typography>
            </Box>
          );
        }
      })}
      {item.variant?.["add-on"] && (
        <Box my={0.5}>
          <Typography variant="overline" color="textSecondary">
            Add-Ons
          </Typography>
          <Typography variant="body2">{item.variant?.[`add-on`].map((o) => o.name).join(", ")}</Typography>
        </Box>
      )}
      {item.customization?.inscription?.text && (
        <Box my={0.5}>
          <Typography variant="overline" color="textSecondary">
            Inscription
          </Typography>
          <Typography variant="body2">{item.customization.inscription?.text}</Typography>
        </Box>
      )}
      {item.tags && item.tags.length > 0 && (
        <Box my={0.5}>
          <Typography variant="overline" color="textSecondary" display="block">
            TAGS
          </Typography>
          {item.tags.map((e, idx) => (
            <Chip key={idx} size="small" label={e} />
          ))}
        </Box>
      )}
      {item.description && (
        <Box my={0.5}>
          <Typography variant="overline" color="textSecondary" display="block">
            NOTES
          </Typography>
          <HTMLViewer content={item.description} style={{ fontSize: ".85rem" }} />
        </Box>
      )}
    </Box>
  );
}
