import React from "react";
import { Autocomplete, Box, CircularProgress, InputAdornment, TextField, Typography } from "@mui/material";
import bkstApi from "../../common/component/api";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import moment from "moment";

export default function QuoteSearch(props) {
  const [open, setOpen] = React.useState(false);
  const [q, setQ] = React.useState("");
  const [timerId, setTimerId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const callApi = () => {
    bkstApi(`/admin/search/quote?q=${props.q} ${q}`).then((res) => {
      const results = res.data;
      setOptions(results);
      setLoading(false);
    });
  };

  const throttle = function (delay) {
    // If setTimeout is already scheduled, no need to do anything
    if (timerId) {
      return;
    }

    // Schedule a setTimeout after delay seconds
    const tid = setTimeout(function () {
      callApi();
      // Once setTimeout function execution is finished, timerId = undefined so that in <br>
      // the next scroll event function execution can be scheduled by the setTimeout
      setTimerId("");
    }, delay);
    setTimerId(tid);
  };

  React.useEffect(() => {
    if (q.length > 0) {
      setLoading(true);
      throttle(400);
    } else {
      setOptions([]);
    }
  }, [q]);

  return (
    <Autocomplete
      id="quote-search"
      freeSolo
      open={open}
      noOptionsText={"No matches found"}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      style={{ width: "250px" }}
      filterOptions={(x) => x}
      getOptionSelected={(option, value) => option.bizName === value.bizName}
      getOptionLabel={(option) => option.title}
      renderOption={(props, o, state) => (
        <a key={o.objectID} target="_blank" href={`/request/${o.objectID.split("-")[0]}`} style={{ textDecoration: "none", color: "inherit" }}>
          <Box p={1} sx={{ borderBottom: "1px solid #DDD" }}>
            <Typography variant="body1" display="inline">{`$${o.quote}`}</Typography>
            {o.ts && (
              <Typography variant="caption" color="textSecondary" sx={{ paddingLeft: "5px" }}>
                {`${moment(o.ts).fromNow()}`}
              </Typography>
            )}
            <Typography variant="caption" display="block" color="textSecondary">
              {`${o.title} · ${o.shape} ${o.size} · ${o.servings} servings`}
            </Typography>
          </Box>
        </a>
      )}
      options={options}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          value={q}
          variant="outlined"
          size="small"
          onChange={(e) => setQ(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon style={{ color: "#888" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
}
