import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/component/api";
import SearchIcon from "@mui/icons-material/Search";
import { Link as RouterLink } from "react-router-dom";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import IconText from "../../common/component/IconText";
import moment from "moment";
import Images from "../../common/component/Images";
import { getQueryParam } from "../../common/component/util";

export default function RequestSearch() {
  const [searchBy, setSearchBy] = useState("eventDt");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [bakerId, setBakerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState();

  useEffect(() => {
    const p = getQueryParam(window.location.search, "phone");
    if (p) {
      setSearchBy("phone");
      setPhone(p);
    }
  }, []);

  const submit = (e) => {
    e.preventDefault();
    search();
  };

  const search = () => {
    setLoading(true);

    bkstApi(`/requests/?by=${searchBy}&fromDt=${fromDt}&toDt=${toDt}&email=${email}&phone=${phone}&bakerId=${bakerId}`).then((res) => {
      setResults(res.data.requests);
      setLoading(false);
    });
  };

  const rows = [];
  if (results) {
    for (const req of results) {
      rows.push(
        <Box mb={4} key={req.id}>
          <Paper elevation={0} style={{ backgroundColor: "#ECECEC" }}>
            <Box fontSize={16} px={2} py={2}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Images pics={req.pics || [req.pic1, req.pic2, req.pic3].filter((i) => i)} />
                </Grid>
                <Grid item xs={9}>
                  <Box mb={1}>
                    <Typography variant="body1" display="inline">
                      {req.title}
                    </Typography>
                    {req.src === "direct" && <Chip label="direct" size="small" />}
                    {req.type && <Chip label={req.type} size="small" />}
                    {req.orderId && <Chip label="order" size="small" style={{ color: "#fff", backgroundColor: "#1abc9c" }} />}
                  </Box>
                  <Box style={{ color: "#555" }}>
                    <IconText icon={EventOutlinedIcon} text={`${moment(req.date).format("MMM DD")} ${req.time ? `[${req.time}]` : ""} `} />
                    <IconText icon={CakeOutlinedIcon} text={`${req.servings} servings`} />
                    {req.location && <IconText icon={PinDropOutlinedIcon} text={req.location} />}
                    <IconText icon={AccessTimeIcon} text={moment(req.ts).fromNow()} />
                  </Box>
                </Grid>
              </Grid>
              <Box align="right">
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  key={req.id}
                  target="_blank"
                  rel="noreferrer"
                  to={`/request/${req.id}`}
                  style={{ textDecoration: "none" }}
                  component={RouterLink}
                >
                  View
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      );
    }
  }

  return (
    <Container maxWidth="md">
      <Box my={3}>
        <Typography variant="overline">SEARCH REQUESTS</Typography>
        <Paper>
          <Box p={3}>
            <Box pb={3} align="center">
              <FormControl component="fieldset">
                <RadioGroup row value={searchBy} name="searchBy" onChange={(e) => setSearchBy(e.target.value)}>
                  <FormControlLabel value="eventDt" control={<Radio required={true} />} label="Event Date" />
                  <FormControlLabel value="reqDt" control={<Radio required={true} />} label="Request Date" />
                  <FormControlLabel value="email" control={<Radio required={true} />} label="Email" />
                  <FormControlLabel value="phone" control={<Radio required={true} />} label="Phone" />
                  <FormControlLabel value="bakerId" control={<Radio required={true} />} label="BakerId" />
                </RadioGroup>
              </FormControl>
            </Box>
            <form onSubmit={submit}>
              <Box px={2} py={4} style={{ backgroundColor: "#f7f7f7" }} align="center">
                <Grid container spacing={3} style={{ justifyContent: "center" }}>
                  {(searchBy === "eventDt" || searchBy === "reqDt") && (
                    <Grid item xs={12} sm={5}>
                      <TextField
                        value={fromDt}
                        variant="outlined"
                        id="fromDt"
                        label="From Date"
                        name="fromDt"
                        onChange={(e) => setFromDt(e.target.value)}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                  )}

                  {(searchBy === "eventDt" || searchBy === "reqDt") && (
                    <Grid item xs={12} sm={5}>
                      <TextField
                        value={toDt}
                        variant="outlined"
                        type="date"
                        id="toDt"
                        label="To Date"
                        name="toDt"
                        onChange={(e) => setToDt(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {searchBy === "email" && (
                    <Grid item xs={12} sm={10}>
                      <TextField
                        value={email}
                        name="email"
                        variant="outlined"
                        id="email"
                        label="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {searchBy === "phone" && (
                    <Grid item xs={12} sm={10}>
                      <TextField
                        value={phone}
                        name="phone"
                        variant="outlined"
                        id="phone"
                        label="Phone"
                        onChange={(e) => setPhone(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {searchBy === "bakerId" && (
                    <Grid item xs={12} sm={10}>
                      <TextField
                        value={bakerId}
                        name="bakerId"
                        variant="outlined"
                        id="bakerId"
                        label="BakerId"
                        onChange={(e) => setBakerId(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={2}>
                    <Button variant="contained" color="primary" type="submit" style={{ minHeight: "56px" }} fullWidth>
                      <SearchIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Paper>
        {loading && <LinearProgress />}
      </Box>
      {rows}
    </Container>
  );
}
