import React, { useState } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import ReviewEntry from "./ReviewEntry";

export default function ReviewAdd(props) {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Box align="center" my={4}>
        <Tooltip title="Add Review">
          <Button disableElevation onClick={() => setOpen(true)} color="primary" variant="text" fullWidth>
            Add Review
          </Button>
        </Tooltip>
      </Box>
      <ReviewEntry data={{}} baker={props.baker} open={open} setOpen={setOpen} onDone={props.onDone} />
    </Box>
  );
}
