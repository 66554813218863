import React, { useState, useEffect } from "react";
import { Badge, Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { getQueryParam } from "../../common/component/util";
import bkstApi from "../../common/component/api";
import ChatWidget from "../../common/component/chat/ChatWidget";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function AdminChat(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(getQueryParam(window.location.search, "to") === props.quoteId);
  const [chat, setChat] = useState(props.chat);

  const [templates, setTemplates] = useState();

  useEffect(() => {
    if (open) {
      bkstApi(`/request/${props.reqId}/quote/${props.quoteId}/chat`).then((res) => {
        setChat(res.data.chat);
      });

      if (!templates) {
        bkstApi(`/admin/template/rchat`).then((res) => {
          const templates = res.data.sort((o1, o2) => (o1.name > o2.name ? 1 : -1));
          setTemplates(templates);
        });
      }
    }
  }, [open]);

  return (
    <Box display="inline">
      <Badge color="secondary" badgeContent={(chat && Object.keys(chat).length) || 0} style={{ marginRight: "10px" }}>
        <Button size="small" variant={props.variant || "outlined"} color={props.color || "secondary"} onClick={(e) => setOpen(true)}>
          Chat
        </Button>
      </Badge>
      <Dialog fullScreen={fullScreen} open={open} onClose={() => setOpen(false)}>
        <DialogTitle style={{ margin: "0" }}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Contact {props.name}</Box>
            <Box>
              <IconButton color="inherit" size="small" onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent className="chat-bg" style={{ minHeight: "50vh", padding: "0", margin: "0", display: "flex" }}>
          <ChatWidget
            uid={props.quoteId}
            uploadFolder={`img/r/${props.reqId}`}
            postUrl={`/admin/requests/${props.reqId}/quotes/${props.quoteId}/chat`}
            chat={chat}
            templates={templates}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
