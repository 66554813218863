import React, { useState } from "react";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, RadioGroup } from "@mui/material";
import bkstApi from "../../common/component/api";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NotifyOption from "./NotifyOption";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const options = [
  { code: "cq1", desc: "Quotes" },
  { code: "cq2", desc: "New quotes" },
  { code: "cq3", desc: "Expiring quotes" },
  { code: "rfbk", desc: "Feedback" },
  { code: "fout", desc: "Final outreach" }
];

export default function RequestNotificationButton(props) {
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState();
  const [request, setRequest] = useState();
  const [trail, setTrail] = useState();
  const [error, setError] = useState("");

  const handleClick = () => {
    bkstApi(`/notify?reqId=${props.reqId}`).then((res) => {
      setTrail(res.data.trail);
      setRequest(res.data.request);
      setOpen(true);
    });
  };

  const notify = (confirm) => {
    bkstApi
      .post(`/request/${props.reqId}/notify`, { code, confirm })
      .then((res) => {
        if (confirm) {
          setOpen(false);
        }
      })
      .catch((err) => {
        setError(err.response && err.response.data && err.response.data.msg);
      });
  };

  const isOptionDisabled = (code) => {
    if (request) {
      if (request.bakerId && (code === "cq2" || code === "rfbk")) {
        return true;
      } else if (code === "cq1") {
        return request.status === "new" || request.status === "pending" ? false : true;
      } else if (code === "cq2" || code === "cq3" || code === "rfbk") {
        return request.status === "notified" ? false : true;
      } else if (code === "fout") {
        return (request.status !== "closed" && request.src === 'direct');
      }
    }
    return true;
  };

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={handleClick} disabled={props.disabled}>
        <NotificationsActiveOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>Send Notification</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <RadioGroup aria-label="code" name="code" value={code} onChange={(e) => setCode(e.target.value)}>
            {options &&
              options.map((item) => {
                return (
                  <NotifyOption
                    key={item.code}
                    code={item.code}
                    label={item.desc}
                    trail={trail && trail[item.code]}
                    notify={notify}
                    disabled={isOptionDisabled(item.code)}
                  />
                );
              })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setOpen(false)} style={{ position: "absolute", top: 0, right: 0 }}>
            <HighlightOffIcon />
          </IconButton>
          <Button onClick={() => notify(true)} color="primary" variant="contained" disabled={!code}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
