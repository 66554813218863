import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Grid, Paper, Skeleton, Typography } from "@mui/material";
import IconText from "../../common/component/IconText";
import bkstApi from "../../common/component/api";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";
import QuoteFooterAction from "./QuoteFooterAction";
import QuoteFooterInfo from "./QuoteFooterInfo";
import StarIcon from "@mui/icons-material/Star";
import QuoteDescriptionV2 from "./QuoteDescriptionV2";
import AdminChat from "./AdminChat";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

export default function QuoteItemV5Direct(props) {
  const [baker, setBaker] = useState();
  const [photos, setPhotos] = useState();
  const [reviews, setReviews] = useState([]);

  const { request, quote, addEvent } = props;
  const status = props.quote.status;

  useEffect(() => {
    bkstApi(`/baker/${props.quote.id}/biz-profile`).then((res) => {
      setBaker(res.data.baker);
      setPhotos(res.data.photos);
      setReviews(res.data.reviews);
    });
  }, []);

  return (
    <Paper variant="outlined">
      <Box px={2} py={2}>
        <Box>
          <Grid container spacing={2}>
            <Grid item>
              {baker ? (
                <Avatar alt="" src={`${BAKER_PIC_FOLDER}/${baker.id}/logo400.webp`} style={{ width: "75px", height: "75px" }} />
              ) : (
                <Skeleton variant="circle" width={75} height={75} />
              )}
            </Grid>
            <Grid item>
              {baker ? (
                <Box>
                  <Typography variant="body1">{baker ? `${baker.name}` : <Skeleton />}</Typography>
                  <Box>
                    <Box style={{ color: "#777", marginTop: "3px" }}>
                      {Boolean(Number(baker.orders)) ? (
                        <Grid container>
                          <Grid item>
                            <StarIcon style={{ fontSize: "1.2rem", color: "#78374a", marginRight: ".4rem" }} />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" display="inline" style={{ color: "#000" }}>
                              {Number(baker.rating) > 0 ? `${Number(baker.rating).toFixed(1)}` : "New here!"}&nbsp;
                            </Typography>
                            <Typography variant="body2" display="inline">
                              {`(${baker.orders} orders)`}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <IconText variant="body2" icon={SentimentSatisfiedOutlinedIcon} text="New here!" />
                      )}
                      <Typography style={{ display: "flex", alignItems: "center", fontSize: ".875em", color: "#777" }}>
                        <LocationOnOutlinedIcon style={{ fontSize: "1.4em", color: "#777", marginRight: ".4rem" }} />
                        {`${baker.location.city}, ${baker.location.state} (${quote.baker.distance} mi)`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={100} />
                  <Skeleton variant="text" width={100} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box py={1} mx={1} style={{ display: "flex", justifyContent: "space-evenly", borderTop: "1px solid #EEE", borderBottom: "1px solid #EEE" }}>
        <Button
          size="small"
          variant="text"
          color="primary"
          href={`${process.env.REACT_APP_PUBLIC_VIEW_BASE_URL}/requests/${request.id}`}
          target="_blank"
        >
          Photos
        </Button>
        <Button
          size="small"
          variant="text"
          color="primary"
          href={`${process.env.REACT_APP_PUBLIC_VIEW_BASE_URL}/requests/${request.id}`}
          target="_blank"
        >
          Flavors
        </Button>
        <Button
          size="small"
          variant="text"
          color="primary"
          href={`${process.env.REACT_APP_PUBLIC_VIEW_BASE_URL}/requests/${request.id}`}
          target="_blank"
        >
          Reviews
        </Button>

        <AdminChat reqId={request.id} quoteId={quote.id} name={request.firstName} chat={quote.chat} color="primary" variant="text" />
      </Box>
      <Box mx={2} my={2}>
        <QuoteDescriptionV2 status={status} quote={quote} request={request} addEvent={addEvent} />
      </Box>
      {(status === "active" || status === "expired" || status === "rbook" || status === "paid") && (
        <Box align="center" my={2}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={6}>
              <QuoteFooterInfo status={status} expiration={quote.expiration} quote={quote} />
            </Grid>
            <Grid item sm={6} xs={6}>
              <QuoteFooterAction
                reqId={request.id}
                status={status}
                expiration={quote.expiration}
                quote={quote}
                addEvent={addEvent}
                paymentDueFactor={props.showDepositOption ? 0.5 : 1}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Paper>
  );
}
