import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import ChatCanvas from "./ChatCanvas";
import NewMessage from "./NewMessage";

export default function ChatWidget(props) {
  const ref = useRef(null);
  const { postUrl, templates, uid, uploadFolder } = props;

  const [chat, setChat] = useState(props.chat || []);

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ block: "end" });
    }
  }, [chat, templates]);

  const handleNewMsg = (msg) => {
    const allMsgs = [...chat, msg];
    setChat(allMsgs);
  };

  let footer = <NewMessage uid={uid} handleNewMsg={handleNewMsg} uploadFolder={uploadFolder} postUrl={postUrl} templates={templates} />;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
      <ChatCanvas chat={chat} uid={props.uid} />
      {footer}
      <Box ref={ref} />
    </Box>
  );
}
