import React from "react";
import { Box, Chip, IconButton, Paper, Typography } from "@mui/material";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import DataObjectIcon from "@mui/icons-material/DataObject";
import ClickToCopy from "../../common/component/ClickToCopy";
import PicUpload from "../../common/component/PicUpload";
import bkstApi from "../../common/component/api";

export default function BakerStaff(props) {
  const { acctId, staff } = props;

  const sortStaff = (o1, o2) => {
    let rank = o2.role?.localeCompare(o1.role);
    if (rank === 0) {
      rank = o2.firstName.localeCompare(o1.firstName);
    }
    return rank;
  };

  const setPhoto = (uid, photo) => {
    bkstApi.put(`/admin/site/${acctId}/staff/${uid}`, { photo }).then((res) => {
      window.location.reload();
    });
  };

  return (
    <Box>
      {staff.sort(sortStaff).map((o) => {
        return (
          <Box mb={2}>
            <Paper elevation={0}>
              <Box p={2} sx={{ display: "flex", gap: "10px" }}>
                <PicUpload
                  id={`photo-${o.uid}`}
                  onChange={(url) => setPhoto(o.uid, url)}
                  folder={`img/tmp`}
                  required
                  src={o.photo}
                  width="75px"
                  height="75px"
                />
                <Box>
                  <Typography variant="h6">
                    {o.firstName} {o.lastName}
                  </Typography>
                  <Chip size="small" label={o.role} />
                  <Box mt={1}>
                    <ClickToCopy icon={FingerprintIcon} text={o.uid} />
                    <IconButton
                      size="small"
                      component="a"
                      href={`https://console.firebase.google.com/project/bakestreet-f55a0/firestore/data/~2Fbaker~2F${acctId}~2Fstaff~2F${o.uid}`}
                    >
                      <DataObjectIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        );
      })}
    </Box>
  );
}
