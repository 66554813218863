import React, { useState, useEffect } from "react";
import bkstApi from "../../common/component/api";
import { Autocomplete, TextField } from "@mui/material";

export default function ProductTagsAutocomplete(props) {
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    bkstApi("/tags/product").then((res) => {
      setAllTags(res.data.tags);
    });
  }, []);

  const onChange = (event, val) => {
    props.onChange(val || "");
  };

  return (
    <Autocomplete
      value={props.tags}
      onChange={onChange}
      options={allTags}
      filterSelectedOptions
      renderInput={(params) => <TextField {...params} variant="outlined" label={props.label || "Tags"} placeholder="Tags" />}
      //freeSolo
      multiple={Boolean(props.multiple)}
      blurOnSelect
    />
  );
}
