import React, { useState, useEffect } from "react";
import { Box, Button, Container, FormControl, Grid, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import bkstApi from "../../common/component/api";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import BDatePicker from "../../common/component/BDatePicker";
import moment from "moment";

export default function OrderPaymentEntry(props) {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [order, setOrder] = useState("");

  const [delivery, setDelivery] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [paid, setPaid] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [tip, setTip] = useState(0);
  const [fee, setFee] = useState(0);
  const [dueDate, setDueDate] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/admin/order/${orderId}`)
      .then((res) => {
        setOrder(res.data);

        const orderPayment = res.data.payment;

        setDelivery(orderPayment.delivery);
        setDiscount(orderPayment.discount);
        setPaid(orderPayment.paid);
        setSubtotal(orderPayment.subtotal);
        setTip(orderPayment.tip);
        setFee(orderPayment.fee);
        setDueDate(orderPayment.dueDate || "");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [orderId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      delivery,
      discount,
      paid,
      subtotal,
      tip,
      fee,
      dueDate
    };

    bkstApi.put(`/admin/order/${orderId}/payment`, payload).then((res) => {
      navigate(`/order/${orderId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    console.log('MAX DATE', moment(order.fulfillment.date).subtract(1, "days").format("YYYY-MM-DD"));
    return (
      <Container maxWidth="sm">
        <Box pt={3}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="overline">ORDER PAYMENT</Typography>
            </Grid>
          </Grid>
          <Paper>
            <Box p={4}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item sm={4}>
                    <TextField
                      id="subtotal"
                      type="number"
                      value={subtotal}
                      label="Subtotal"
                      name="subtotal"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setSubtotal(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      id="delivery"
                      type="number"
                      value={delivery}
                      label="Delivery"
                      name="delivery"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setDelivery(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      id="tip"
                      type="number"
                      value={tip}
                      label="Tip"
                      name="tip"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setTip(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      id="fee"
                      type="number"
                      value={fee}
                      label="Service Fee"
                      name="fee"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setFee(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      id="discount"
                      type="number"
                      value={discount}
                      label="Discount"
                      name="discount"
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setDiscount(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TextField
                      id="paid"
                      type="number"
                      value={paid}
                      label="Paid"
                      name="paid"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setPaid(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <BDatePicker
                      label="Balance Due Date"
                      value={dueDate || ""}
                      onChange={(date) => setDueDate(date)}
                      disablePast
                      maxDate={moment(order.fulfillment.date).subtract(1, "days").toDate()}
                      required
                      disabled={+order.payment.due === 0}
                      autoOk
                    />
                  </Grid>
                  <Grid item sm={12} align="right">
                    <Button variant="text" onClick={(e) => navigate(-1)} style={{ marginRight: "1rem" }}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }
}
