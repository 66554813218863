import React from "react";
import { IconButton, Tooltip } from "@mui/material";

export default function ClickToCopy(props) {
  const Icon = props.icon;

  return (
    <Tooltip title={props.text}>
      <IconButton
        size="small"
        target="_blank"
        onClick={(e) => {
          e.stopPropagation();
          navigator.clipboard.writeText(props.text);
        }}
        disabled={!props.text}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
}
