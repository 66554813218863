import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import LineItem from "./LineItemV2";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import LineItemEntryPopup from "./LineItemEntryPopupV2";

export default function LineItems(props) {
  const { items } = props;

  return (
    <Box>
      <Paper elevation={0}>
        {(!items || items.length === 0) && (
          <Box p={2} py={4} sx={{ textAlign: "center" }}>
            <ShoppingBagOutlinedIcon sx={{ fontSize: "3rem", color: "#999" }} />
            <Typography variant="h5" gutterBottom>
              No items yet!
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Click the button below to add items
            </Typography>
          </Box>
        )}
        {items.map((o, idx) => (
          <Box p={2} key={idx}>
            <Box sx={{ display: "flex", gap: "3rem", alignItems: "flex-start" }}>
              <LineItem item={o} view="min" />
              <LineItemEntryPopup
                item={o}
                onChange={(o) => {
                  let arr = [...items];

                  if (!o) {
                    // delete
                    arr.splice(idx, 1);
                  } else {
                    arr[idx] = o;
                  }

                  props.onChange(arr);
                }}
              />
            </Box>
          </Box>
        ))}
      </Paper>
      <Box>
        <LineItemEntryPopup
          idx={items.length}
          mode="add"
          onChange={(o) => {
            let arr = [...items];
            arr.push(o);
            props.onChange(arr);
          }}
        />
      </Box>
    </Box>
  );
}
