import React, { useState, useEffect } from "react";
import { Box, Button, Container, Grid, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../common/component/api";
import ProductDetail from "../component/ProductDetail";
import ProductTagsAutocomplete from "../component/ProductTagsAutocomplete";

export default function ProductSearch(props) {
  const [tag, setTag] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);

  const bakerId = props.bakerId || "";

  useEffect(() => {
    search();
  }, [tag]);

  const search = (e) => {
    setLoading(true);
    setResults([]);
    bkstApi(`/admin/photo?tag=${tag}&bakerId=${bakerId}`).then((res) => {
      setLoading(false);
      setResults(res.data.products);
    });
  };

  return (
    <Box>
      <Container maxWidth="md">
        <Box py={2}>
          <form>
            <Box align="center">
              {!props.bakerId && (
                <Box my={4}>
                  <Typography variant="h3">Search by Tag</Typography>
                </Box>
              )}
              <ProductTagsAutocomplete tags={tag} onChange={(val) => setTag(val)} label="Filter by Tag" />
            </Box>
          </form>
          {loading && <LinearProgress />}
        </Box>
        <Grid container spacing={2}>
          {results.map((value, index) => {
            return (
              <Grid item sm={4} key={index}>
                <ProductDetail product={value} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
}
