import React from "react";
import { Box, Grid } from "@mui/material";
import FlavorVariantEntry from "../variant/FlavorVariantEntry";
import FrostingAutocomplete from "../../../product/component/FrostingAutocomplete";
import ProductShapeAutocomplete from "../../../product/component/ProductShapeAutocomplete";
import ProductSizeAutocomplete from "../../../product/component/ProductSizeAutocomplete";
import ProductServingsAutocomplete from "../../../product/component/ProductServingsAutocomplete";

export default function ItemVariantEntryV2(props) {
  const { productType, variant } = props;

  return (
    <Box>
      {productType === "Cake" && (
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <ProductShapeAutocomplete
                productType={productType}
                label="Shape"
                value={variant?.size?.shape}
                onChange={(val) => {
                  props.onChange({ ...variant, size: { ...variant?.size, shape: val } });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ProductSizeAutocomplete
                productType={productType}
                shape={variant?.size?.shape}
                label="Size"
                value={variant?.size?.size}
                onChange={(val) => {
                  let o = { ...variant?.size, size: val, servings: "" };
                  // see if we can auto-populate servings
                  if (productType === "Cake") {
                    /*if (user.config?.sizeChart) {
                      const match = user.config.sizeChart["Cake"]?.[variant?.size?.shape]?.filter((o) => o.size === val);
                      if (match?.length > 0) {
                        o.servings = match[0].servings;
                      }
                    }*/
                  }
                  props.onChange({ ...variant, size: o });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ProductServingsAutocomplete
                productType={productType}
                label={"Servings"}
                value={variant?.size?.servings}
                onChange={(val) => props.onChange({ ...variant, size: { ...variant?.size, servings: val } })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FrostingAutocomplete
                productType={productType}
                value={variant.frosting?.name}
                label="Frosting"
                onChange={(val) => props.onChange({ ...variant, frosting: { name: val } })}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Box>
        <FlavorVariantEntry productType={productType} value={variant.flavor} onChange={(val) => props.onChange({ ...variant, flavor: [val] })} />
      </Box>
    </Box>
  );
}
