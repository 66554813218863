import { Box, Typography } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";

export default function QuillRTE(props) {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }]
    ],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = ["bold", "italic", "underline", "list", "bullet"];

  const onChange = (html) => {
    props.onChange(html.replace("<p><br></p>", ""));
  };

  return (
    <Box>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        defaultValue={props.value}
        onChange={onChange}
        placeholder={props.placeholder || "Enter text here.."}
      />
      <Typography variant="caption" color="textSecondary">
        {props.helperText || ""}
      </Typography>
    </Box>
  );
}
