import React from "react";
import { Box, Grid } from "@mui/material";
import bkstApi from "../../common/component/api";
import FlavorInputList from "../../common/component/FlavorInputList";

export default function AdminBakerEditFlavors(props) {
  const submit = (payload) => {
    bkstApi.put(`/admin/bakers/${props.bakerId}`, { public: payload }).then((res) => {});
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <FlavorInputList type="Flavor" value={props.data.flavors} onChange={(data) => submit({ flavors: data })} />
        </Grid>
        <Grid item sm={12} xs={12}>
          <FlavorInputList type="Filling" value={props.data.fillings} onChange={(data) => submit({ fillings: data })} />
        </Grid>
      </Grid>
    </Box>
  );
}
