import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { SERVINGS } from "../../common/component/constants";

export default function ProductServingsAutocomplete(props) {
  const { productType, value } = props;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (productType === "Cake") {
      setOptions(SERVINGS);
    } else {
      setOptions(["1 dozen", "2 dozen", "3 dozen", "4 dozen", "5 dozen"]);
    }
  }, [productType]);

  const onChange = (e, v) => {
    props.onChange(v);
  };

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      onInputChange={onChange}
      options={options}
      renderInput={(params) => <TextField required={props.required} variant="outlined" {...params} label={props.label} />}
      disableClearable
      freeSolo
      blurOnSelect={true}
    />
  );
}
