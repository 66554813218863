import React, { useContext, useState, useEffect } from "react";
import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { normalizeFlavor } from "../util";

export default function FlavorVariantEntry(props) {
  let { productType, value } = props;

  value = normalizeFlavor(value);

  const [flavor, setFlavor] = useState(value?.name || "");
  const [filling, setFilling] = useState(value?.filling || "");

  useEffect(() => {
    let payload = {};
    if (flavor) {
      payload.name = flavor;
    }
    if (filling) {
      payload.filling = filling;
    }
    props.onChange(payload);
  }, [flavor, filling]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Autocomplete
            value={flavor || ""}
            onChange={(e, val) => setFlavor(val || "")}
            options={[]}
            renderInput={(params) => <TextField variant="outlined" {...params} label="Flavor" />}
            freeSolo
            blurOnSelect={true}
            autoSelect
          />
        </Grid>
        {productType === "Cake" && (
          <Grid item sm={6} xs={12}>
            <Autocomplete
              value={filling || ""}
              onChange={(e, val) => setFilling(val || "")}
              options={[]}
              renderInput={(params) => <TextField variant="outlined" {...params} label="Filling" />}
              freeSolo
              blurOnSelect={true}
              autoSelect
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
