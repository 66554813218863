import React, { useState } from "react";
import { Avatar, Box, Grid, Link, Paper, Typography } from "@mui/material";
import IconText from "../../common/component/IconText";
import { EventOutlined } from "@mui/icons-material";
import moment from "moment";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import { BAKER_PIC_FOLDER, bakeStreetSquareLogo } from "../../common/component/constants";
import { Link as RouterLink } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import Images from "../../common/component/Images";

export default function RequestSummary(props) {
  const { req } = props;

  // hide dev env test reqs in prod
  const [hide, setHide] = useState(false);

  const onClose = () => {
    setHide(true);
  };

  if (hide) {
    return <Box />;
  } else {
    return (
      <Paper elevation={0} style={{ marginBottom: "2rem" }}>
        <Link component={RouterLink} to={`/request/${props.req.id}`} underline="none" color="inherit">
          <Box px={2} pt={2} pb={1}>
            <Grid container spacing={2}>
              <Grid item sm={3} xs={3}>
                <Images pics={props.req.pics} />
              </Grid>
              <Grid item sm={9} xs={9}>
                <Typography variant="h6" style={{ display: "flex", alignItems: "center" }} gutterBottom>
                  {props.req.title}
                  {!req.pv && <PhoneDisabledIcon style={{ fontSize: "1.3em", color: "#e74c3c", marginLeft: "3px" }} />}
                </Typography>
                <Box style={{ color: "#888" }}>
                  <IconText icon={EventOutlined} text={`${moment(props.req.date).format("MMM DD")}`} />
                  {/*<IconText icon={PinDropOutlinedIcon} text={props.req.location} />*/}
                  <IconText icon={CakeOutlinedIcon} text={`${props.req.servings} servings`} />
                  <IconText icon={LocalAtmOutlinedIcon} text={`$${props.req.budget} Budget`} />
                  {/*props.req.notifyTs && (
                    <IconText variant="caption" icon={NotificationsActiveOutlinedIcon} text={`${moment(props.req.notifyTs).fromNow()}`} />
                  )*/}
                  {/*props.req.chatTs && <IconText variant="caption" icon={SmsOutlinedIcon} text={`${moment(props.req.chatTs).fromNow()}`} />*/}
                  {/*props.req.viewTs && <IconText variant="caption" icon={VisibilityOutlinedIcon} text={`${moment(props.req.viewTs).fromNow()}`} />*/}
                </Box>
              </Grid>
            </Grid>
            <Box mt={1}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                  <Typography variant="caption" color="textSecondary" style={{ display: "flex", alignItems: "center" }}>
                    <AccessTimeIcon style={{ fontSize: "1.3em", marginRight: ".3rem" }} />
                    {moment(req.ts).fromNow()}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                    {req.src === "mkt" ? (
                      <img src={bakeStreetSquareLogo} style={{ width: "24px", height: "24px" }} />
                    ) : (
                      <Avatar src={`${BAKER_PIC_FOLDER}/${req.bakerId}/logo400.webp`} style={{ width: "24px", height: "24px" }} />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Link>
        {/* <Box p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Box>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="overline">
                {props.req.name}
                <Tooltip title={props.req.email}>
                  <IconButton
                    target="_blank"
                    onClick={() => {
                      navigator.clipboard.writeText(props.req.email);
                    }}
                    disabled={!props.req.email}
                    size="small"
                  >
                    <AlternateEmail />
                  </IconButton>
                </Tooltip>
                <ClickToCall phone={props.req.phone} />
              </Typography>
            </Grid>
            <Grid item xs={4} align="right">
              <Grid container spacing={1} alignItems="center" style={{ justifyContent: "flex-end" }}>
                <Grid item>
                  {props.req.bakerId && (
                    <Avatar alt="" src={`${BAKER_PIC_FOLDER}/${props.req.bakerId}/photo400.webp`} style={{ width: "24px", height: "24px" }} />
                  )}
                </Grid>
                <Grid item>
                  <CloseRequest reqId={props.req.id} title={props.req.title} onClose={onClose} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
                  </Box>*/}
      </Paper>
    );
  }
}
