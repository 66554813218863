import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import BakerStatus from "../../baker/component/BakerStatus";
import bkstApi from "../../common/component/api";
import { useParams, Link as RouterLink } from "react-router-dom";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";
import moment from "moment";

export default function AddQuote(props) {
  const {reqId} = useParams();

  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState("");
  const [radius, setRadius] = useState(15);

  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(radius);
  }, [radius]);

  const columns = [
    {
      field: "id",
      headerName: "Add",
      width: 75,
      renderCell: (params) => (
        <Box style={{ lineHeight: "1" }} align="center">
          <img alt="" src={`${BAKER_PIC_FOLDER}/${params.value}/photo400.webp`} style={{ width: "100%", display: "block" }}></img>
          <Button size="small" disableElevation variant="contained" color="secondary" onClick={() => addQuote(params.value)} fullWidth>
            Add
          </Button>
        </Box>
      )
    },
    {
      field: "baker",
      headerName: "Baker",
      width: 300,
      valueGetter: ({ value }) => `${value.name}|${value.bizName}|${value.status}|${value.location.city}|${value.location.state}`,
      renderCell: (params) => {
        return (
          <Box style={{ lineHeight: "1" }}>
            <Typography variant="body2" display="block">
              {params.row.baker.name} [{params.row.baker.bizName}]
            </Typography>
            <Typography
              color="textSecondary"
              variant="caption"
              display="block"
            >{`${params.row.baker.location.city}, ${params.row.baker.location.state}`}</Typography>
            <Typography color="textSecondary" variant="caption" display="block">{`${params.row.baker.orders} orders ${
              params.row.baker.lo ? `(recent: ${moment(params.row.baker.lo).fromNow()})` : ""
            }`}</Typography>
            <BakerStatus status={params.row.baker.status} />
          </Box>
        );
      }
    },
    {
      field: "distance",
      headerName: "Distance",
      width: 150,
      renderCell: (params) => {
        return `${params.value} mi`;
      }
    }
  ];

  const getData = (radius) => {
    setProcessing(true);

    bkstApi(`/admin/requests/${reqId}/nearby-bakers?radius=${radius}`).then((res) => {
      var translatedData = Object.entries(res.data).map(([key, value]) => {
        return {
          id: value.id,
          baker: value,
          distance: value.distance
        };
      });
      setRows(translatedData);
      setProcessing(false);
    });
  };

  const addQuote = (bakerId) => {
    var payload = {
      bakerId,
      status: "pending"
    };

    bkstApi
      .post(`/requests/${reqId}/quotes`, payload)
      .then((res) => {
        console.log("Success:", res.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  if (processing) {
    return <LinearProgress />;
  } else {
    return (
      <Box pt={3} pb={5}>
        <Container maxWidth="sm">
          <Box mb={1}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4} align="left">
                <Autocomplete
                  value={filter}
                  onChange={(e, v) => setFilter(v)}
                  onInputChange={(e, v) => setFilter(v)}
                  options={["offboard", "onboard", "quote"]}
                  renderInput={(params) => <TextField {...params} label="Filter" />}
                  disableClearable
                  freeSolo
                />
              </Grid>
              <Grid item xs={8} align="right">
                <FormControl>
                  <InputLabel id="radius-label">Radius</InputLabel>
                  <Select value={radius} labelId="radius-label" label="Radius" id="radius" name="radius" onChange={(e) => setRadius(e.target.value)}>
                    <MenuItem value="15">15 mi</MenuItem>
                    <MenuItem value="30">25 mi</MenuItem>
                    <MenuItem value="50">50 mi</MenuItem>
                    <MenuItem value="100">100 mi</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <div style={{ height: "70vh", width: "100%" }}>
            <DataGrid
              disableColumnFilter
              rows={rows}
              columns={columns}
              pageSize={50}
              rowHeight={125}
              filterModel={{
                items: [{ field: "baker", operator: "contains", value: filter }]
              }}
            />
          </div>
          <Box mt={2} align="right">
            <Button variant="contained" color="primary" component={RouterLink} to={`/request/${reqId}`} size="large">
              Done
            </Button>
          </Box>
        </Container>
      </Box>
    );
  }
}
