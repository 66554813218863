import React, { useState } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "./api";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import SendIcon from "@mui/icons-material/Send";

const templates = [
  { name: "describe", text: "What is this?" },
  { name: "complexity", text: "On a scale of 1 to 5, how complex is this cake decoration?" },
  {
    name: "json",
    text: "Create a json with a summary of this image. Include things like a title and description of the cake, a list of colors, a list of distinct objects, characters, theme, shape, tiers and frosting of the cake"
  },
  {
    name: "tags",
    text: `This should be an image of a bakery product like cake, cupcake, etc. We need help identifying the item and tagging with key attributes so it easier to find using a tag-based search. Create a json using the following schema: 
{ type: (e.g. cake, cupcake, cakepop, etc),
occasion: [an array of occasions for which this is appropriate - e.g. birthday, baby shower, wedding, graduation, etc],
celebrant: [an array of age groups for whom this is appropriate - baby, toddler, little kid, big kid, adult]
theme: [an array of unique themes of this design], 
object: [an array of distinct objects], 
character: [an array of characters], 
shape: (e.g. round, heart, sculpted, etc. Only applicable if item is cake), 
tier: the number of tiers of cake (e.g. 1, 2 3, etc. Only applicable if item is cake), 
color: [an array of the main colors], 
icing: (e.g. buttercream, fondant, etc. Only applicable if item is cake) 
}`
  }
];

export default function Gemini(props) {
  const [open, setOpen] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const sendToGemini = (e) => {
    e.preventDefault();

    setLoading(true);
    bkstApi.post(`/gemini`, { img: props.img, prompt }).then((res) => {
      setResponse(res.data.response);

      setPrompt("");
      setLoading(false);
    });
  };

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={() => setOpen(true)}>
        <TipsAndUpdatesOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="md">
        <form onSubmit={sendToGemini}>
          <DialogTitle>Gemini</DialogTitle>
          {loading && <LinearProgress />}
          <DialogContent>
            <Box pt={2}>
              <Grid container spacing={4}>
                <Grid item xs={5}>
                  <img src={props.img} style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={7}>
                  <Box px={2} pt={2} pb={1} mb={4} sx={{ backgroundColor: "#ECECEC" }}>
                    <TextField
                      id="prompt"
                      value={prompt}
                      name="prompt"
                      variant="outlined"
                      rows={3}
                      fullWidth
                      required
                      autoFocus
                      placeholder="Type something"
                      onChange={(e) => setPrompt(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton size="small" color="primary" type="submit" variant="contained" disabled={prompt.length === 0}>
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          paddingRight: 0
                        },
                        backgroundColor: "#FFFFFF"
                      }}
                    />
                    <Box pt={0.5}>
                      {templates.map((t) => (
                        <Chip
                          key={t.name}
                          size="small"
                          label={t.name}
                          component="a"
                          onClick={() => setPrompt(t.text)}
                          clickable
                          sx={{ marginRight: "3px" }}
                        />
                      ))}
                    </Box>
                  </Box>
                  <Box sx={{ backgroundColor: "#FFFFFF" }}>
                    <Typography variant="body2">{response}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </Box>
  );
}
