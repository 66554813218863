export const timeSlots = [
  "8am - 9am",
  "9am - 10am",
  "10am - 11am",
  "11am - Noon",
  "Noon - 1pm",
  "1pm - 2pm",
  "2pm - 3pm",
  "3pm - 4pm",
  "4pm - 5pm",
  "5pm - 6pm",
  "6pm - 7pm",
  "7pm - 8pm"
];
export const occasions = ["Anniversary", "Birthday", "Baby Shower", "Engagement", "Wedding", "Other"];
export const cakeBudget = ["60", "100", "150", "200", "300", "500", "500+"];
export const otherBudget = ["36 per dozen", "48 per dozen", "60 per dozen", "72 per dozen", "84 per dozen", "84+ per dozen"];
export const placeholderImg =
  "https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fplaceholder.png?alt=media&token=6d84b036-2520-41bc-bfa9-2ba6866e108c";
export const bakeStreetLogo = "https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/logo/logo.png";
export const bakeStreetSquareLogo =
  "https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Flogo%2Flogo_sq.svg?alt=media&token=1025e184-2f37-464e-8332-e17322088223";
export const spinnerImg =
  "https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fspin.svg?alt=media&token=bf6baa18-0a42-4e42-8347-9e2ba3b8be3a";
export const REVIEWS = [
  {
    img: "/img%2Fo%2Frv%2F12.jpg?alt=media&token=8fa63883-a427-47a6-a45e-480008f13fc1",
    rating: "5.0",
    text: "This was the best Pride Cake ever. I highly recommend BakeStreet and a huge shout out to Johneris. She kept me up to date with the process excellent communication skills. Great Job!",
    name: "Bambi"
  },
  {
    img: "/img/o/rv/burrito.jpg",
    rating: "5.0",
    text: "Melissa is a true artist - we were simply amazed by her creation. My boyfriend’s cake was a realistic Chipotle burrito and we are all still not over how delicious it was. We are so grateful for Melissa’s attention to detail and plan on working with her again in the near future!!!",
    name: "Mariana"
  },
  {
    img: "/img/o/rv/alice.jpeg",
    rating: "5.0",
    text: "Everyone loved the cake. Design was beautifully done! VERY IMPRESSED. The topsy turvy top very stable. It was bigger than expected for a very decent price. EVERYTHING was edible definitely considering buying more cakes from here :heart:",
    name: "Krissy"
  },
  {
    img: "/img/o/rv/blue-floral.jpg",
    rating: "5.0",
    text: "This was such a beautiful cake. It was impossible to find a bakery in NYC that could pull off a beautiful, custom cake within our tight deadline until we found Bakestreet. The service was amazing and I will definitely be using them again!",
    name: "Hannah"
  },
  {
    img: "/img/o/rv/shower.jpeg",
    rating: "5.0",
    text: "Nathalie was awesome! The cake looked exactly as requested with very little direction from me. It was absolutely delicious and was such a hit at my babyshower! The communication was also prompt and professional. Our cake arrived on time and intact in spite of the big snowstorm that was the day before!",
    name: "Chantal"
  },
  {
    img: "/img%2Fo%2Frv%2F51.jpg?alt=media&token=88ce977b-4cd0-48b2-80d2-77aa6b40e460",
    rating: "4.5",
    text: "This was my 1st time using BakeStreet & the cake artist. BOTH exceeded my expectations. I loved how hands on Bakestreet was in making sure the Baker & I was on the same page. The Cake was beautiful and tasted amazing. However there was a misunderstanding with delivery, but BakeStreet took accountability and fixed the issue. Over all I would use BOTH again.",
    name: "Robyn"
  },
  {
    img: "/img%2Fo%2Frv%2F81.jpg?alt=media&token=142af60b-adb6-475d-b229-da91d2087645",
    rating: "5.0",
    text: "It was my daughter's 16th and we wanted something which is beautiful with in minion theme. I had an idea in mind and Keerthi converted that into cake. Love working with her.",
    name: "Michele"
  }
];
export const BAKER_PIC_FOLDER = "https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/b";
export const STORAGE_BUCKET = "https://storage.googleapis.com/bakestreet-f55a0.appspot.com";
export const IMG_PLACEHOLDER = "/images/placeholder.png";

export const IMG_CELEBRATE =
  "https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Forder%2Fparty.svg?alt=media&token=29ab0b62-8645-48ac-bc26-76bc87a4c5ae";
export const FEATURED_BAKERS = [
  {
    img: "images/bg/artist/Melissa3.png",
    name: "Melissa"
  },
  {
    img: "images/bg/artist/Lisa3.png",
    name: "Lisa"
  },
  {
    img: "images/bg/artist/Alexey3.png",
    name: "Alexey"
  },
  {
    img: "images/bg/artist/Paty3.png",
    name: "Paty"
  },
  {
    img: "images/bg/artist/Natali3.png",
    name: "Natali"
  }
];
export const HOW_IT_WORKS = [
  {
    img: "images/page/home/feedback.svg",
    title: "Review your quote",
    desc: "Your cake artist has been thoroughly vetted by BakeStreet. See authentic photos of their past work. Read verified reviews. Browse flavor options. Chat, if you have any questions."
  },
  {
    img: "images/page/home/confirmed.svg",
    title: "Book with confidence",
    desc: "BakeStreet protects you against cancelation, no-show & incomplete orders by your cake artist. Your payment is held securely & your cake artist is paid only after your order is complete."
  },
  {
    img: "images/page/home/chat.svg",
    title: "Continue making updates",
    desc: "Each cake is made to order, so you can make changes even after booking. Upgrade to a larger cake. Add cookies, cakepops & cupcakes. Change colors, flavors & inscription."
  }
];

export const PRODUCT_TYPES = [
  "Cake",
  "Cake Bite",
  "Cake Jar",
  "Cake Pop",
  "Cakesicle",
  "Cookie",
  "Cupcake",
  "Dessert",
  "Donut",
  "Macaron",
  "Pie",
  "Pretzel",
  "Pretzel Rod",
  "Pudding",
  "Rice Krispie Treat",
  "Shooter",
  "Whoopie Pie",
  "Other"
];
export const PRODUCT_TYPES_V2 = [
  "Cake",
  "Cake Bite",
  "Cake Jar",
  "Cake Pop",
  "Cakesicle",
  "Cookie",
  "Cupcake",
  "Dessert",
  "Donut",
  "Macaron",
  "Pie",
  "Pretzel",
  "Pretzel Rod",
  "Pudding",
  "Rice Krispie Treat",
  "Shooter",
  "Whoopie Pie",
  "Other"
];

export const PRODUCT_SUBTYPES = [
  "1-Tier",
  "2-Tier",
  "3-Tier",
  "4-Tier",
  "5-Tier",
  "6-Tier",
  "Heart",
  "Letter",
  "Number",
  "Photo",
  "Sculpted",
  "Sheet"
];

export const SHAPE = ["1-Tier", "2-Tier", "3-Tier", "4-Tier", "5-Tier", "6-Tier", "Custom", "Heart", "Sheet"];

export const SERVINGS = ["8-10", "12-15", "15-20", "20-25", "25-30", "35-40", "40-45", "55-60", "65-70", "80-100"];

export const FROSTING = [
  "Buttercream",
  "Cream cheese",
  "Cream-Top",
  "Fondant",
  "Ganache",
  "Italian meringue (suspiro)",
  "Marzipan",
  "Modeling chocolate",
  "Swiss meringue",
  "Whipped cream",
  "Whipped cream cheese"
];

export const ONE_TIER_SIZES = ["6 inch", "7 inch", "8 inch", "9 inch", "10 inch"];
export const TWO_TIER_SIZES = ["6 inch / 4 inch", "7 inch / 5 inch", "8 inch / 6 inch", "9 inch / 6 inch", "9 inch / 7 inch", "10 inch / 8 inch"];
export const THREE_TIER_SIZES = [
  "8 inch / 6 inch / 4 inch",
  "9 inch / 7 inch / 5 inch",
  "10 inch / 8 inch / 6 inch",
  "11 inch / 9 inch / 7 inch",
  "12 inch / 10 inch / 8 inch"
];
export const FOUR_TIER_SIZES = ["10 inch / 8 inch / 6 inch / 4 inch", "11 inch / 9 inch / 7 inch / 5 inch", "12 inch / 10 inch / 8 inch / 6 inch"];
export const FIVE_TIER_SIZES = ["12 inch / 10 inch / 8 inch / 6 inch / 4 inch", "14 inch / 12 inch / 10 inch / 8 inch / 6 inch"];
export const SIX_TIER_SIZES = ["14 inch / 12 inch / 10 inch / 8 inch / 6 inch / 4 inch", "16 inch / 14 inch / 12 inch / 10 inch / 8 inch / 6 inch"];
export const SHEET_SIZES = ["Quarter Sheet: 9 inch x 13 inch", "Half Sheet: 13 inch x 18 inch", "Full Sheet: 18 inch x 26 inch"];
export const CUSTOM_SIZES = ["3D: 8 inch x 6 inch", "3D: 10 inch x 6 inch"];
export const HEART_SIZES = ["6 inch", "7 inch", "8 inch"];

export const SHAPE_SIZE_MAP = {
  "1-Tier": ONE_TIER_SIZES,
  "2-Tier": TWO_TIER_SIZES,
  "3-Tier": THREE_TIER_SIZES,
  "4-Tier": FOUR_TIER_SIZES,
  "5-Tier": FIVE_TIER_SIZES,
  "6-Tier": SIX_TIER_SIZES,
  Custom: CUSTOM_SIZES,
  Heart: HEART_SIZES,
  Sheet: SHEET_SIZES
};

// roughly 15 cu in per serving
export const SIZE_SERVINGS_MAP = {
  "4 inch": "5-6",
  "5 inch": "6-8",
  "6 inch": "10-12",
  "7 inch": "14-16",
  "8 inch": "18-22",
  "9 inch": "28-32",
  "10 inch": "38-42",
  "12 inch": "55-60",
  "14 inch": "75-80",
  "6 inch / 4 inch": "15-20",
  "7 inch / 5 inch": "20-25",
  "8 inch / 6 inch": "30-35",
  "9 inch / 6 inch": "40-45",
  "9 inch / 7 inch": "45-50",
  "10 inch / 8 inch": "55-65",
  "8 inch / 6 inch / 4 inch": "35-40",
  "9 inch / 7 inch / 5 inch": "50-55",
  "10 inch / 8 inch / 6 inch": "65-75",
  "11 inch / 9 inch / 7 inch": "85-100",
  "12 inch / 10 inch / 8 inch": "110-125",
  "10 inch / 8 inch / 6 inch / 4 inch": "70-80",
  "11 inch / 9 inch / 7 inch / 5 inch": "90-105",
  "12 inch / 10 inch / 8 inch / 6 inch": "120-135",
  "12 inch / 10 inch / 8 inch / 6 inch / 4 inch": "125-140",
  "14 inch / 12 inch / 10 inch / 8 inch / 6 inch": "195-215",
  "14 inch / 12 inch / 10 inch / 8 inch / 6 inch / 4 inch": "200-220",
  "16 inch / 14 inch / 12 inch / 10 inch / 8 inch / 6 inch": "210-250"
};

export const QUOTE_TAGS = [
  "3D Caricature (edible)",
  "3D Figure (toy)",
  "3D Figure (edible)",
  "3D Figure (non-edible)",
  "Candy",
  "Chocolate Shards",
  "Cut-Outs (edible)",
  "Cut-Outs (non-edible)",
  "Decoration (edible)",
  "Decoration (non-edible)",
  "Drip",
  "Feather",
  "Flowers (dried)",
  "Flowers (edible)",
  "Flowers (fresh)",
  "Flowers (silk)",
  "Fruits (fresh)",
  "Geode",
  "Lace",
  "Liquor bottle (mini)",
  "Macarons",
  "Pampas",
  "Photo (edible)",
  "Rosettes",
  "Ruffles",
  "Splash",
  "Stencil",
  "Topper (edible)",
  "Topper (non-edible)"
];

export const TIME_WINDOWS = [
  "8am - 9am",
  "9am - 10am",
  "10am - 11am",
  "11am - Noon",
  "Noon - 1pm",
  "1pm - 2pm",
  "2pm - 3pm",
  "3pm - 4pm",
  "4pm - 5pm",
  "5pm - 6pm",
  "6pm - 7pm",
  "7pm - 8pm"
];

export const ALL_SIZES = ONE_TIER_SIZES.concat(TWO_TIER_SIZES).concat(THREE_TIER_SIZES).concat(SHEET_SIZES).concat(CUSTOM_SIZES);

export const THEME = {
  palette: {
    primary: {
      main: "#78374a"
    },
    secondary: {
      main: "#818181"
    },
    background: {
      default: "#F7F7F7"
    },
    error: {
      main: "#d63031"
    }
  },
  typography: {
    h1: {
      fontSize: "2.25rem",
      fontWeight: "600"
    },
    h2: {
      fontSize: "2.0rem",
      fontWeight: "600"
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: "600"
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: "600"
    },
    h5: {
      fontSize: "1.2rem",
      fontWeight: "600"
    },
    h6: {
      fontSize: "1rem"
    },
    subtitle1: {
      fontSize: "1.1rem"
    },
    overline: {
      lineHeight: "2"
    }
  },
  components: {
    MuiAccordionSummary: {
      content: {
        "&$expanded": {
          paddingBottom: "10px",
          borderBottom: "1px solid #DDD"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          margin: "4px"
        },
        outlinedSecondary: {
          borderColor: "#DDD"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#f7f7f7",
          borderBottom: "1px solid #eee",
          marginBottom: "10px"
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "10px",
          border: "1px solid #eee",
          marginTop: "15px"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "40px"
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          color: "#2980b9"
        },
        filledSuccess: {
          color: "#fff",
          backgroundColor: "#10ac84"
        },
        filledWarning: {
          backgroundColor: "#ff9f43"
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: "600"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedSizeSmall: {
          fontSize: ".75rem"
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          width: "300px",
          maxWidth: "300px",
          paddingTop: "20px"
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        sizeSmall: {
          fontSize: "1rem"
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: 70
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: ".75rem"
        }
      }
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 0.3
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: {
          display: "none"
        }
      }
    }
    /*MuiFormGroup: {
      styleOverrides: {
        root: {
          '& .MuiFormControlLabel-asterisk': {
            color: "#green"
          },
        }
      }
    }*/
  }
};

export const REFUND_POLICY =
  "All custom cakes and treats sold on BakeStreet are handcrafted, made-to-order and perishable. Each product is hand-made and some design variation should be expected. We ask that you closely inspect your order during pickup or delivery. If your order is damaged, delayed (more than 60 minutes beyond delivery window) or missing key details, do not accept it and notify your cake artist or call BakeStreet support at +1 (877) 755-2253 immediately. We will work with you to address your concerns or offer an appropriate refund based on the extent of damage, delay & error. Once you accept your order and take possession, the sale is final and we will no longer accept returns or offer refunds for any issues not previously reported. You are solely responsible for any damage to the items thereafter. Custom cakes and treats are very fragile and damage can happen if not handled properly during transportation & setup. Damage may also happen due to exposure to excessive heat & humidity, which may result in the appearance of cracks, smudges, melted frosting, colors changes and other structural & cosmetic issues. We cannot be held liable for any damage when the items are in your possession. Furthermore, due to the highly subjective nature of taste, we are unable to offer any refund if you find the taste unsatisfactory.";

export const ORDER_PROGRESS_STEPS = ["baking", "icing", "decorating", "review"];
