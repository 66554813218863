import React, { useState, useEffect, useRef } from "react";
import { Badge, Box, Dialog, DialogActions, DialogContent, DialogTitle, Fab, IconButton } from "@mui/material";
import { getQueryParam } from "../../common/component/util";
import bkstApi from "../../common/component/api";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ChatCanvas from "../../common/component/chat/ChatCanvas";
import { useNavigate } from "react-router";
import NewMessage from "../../common/component/chat/NewMessage";
import ChatHeader from "./ChatHeader";

const MKT_TEMPLATES = [
  {
    name: "mismatch",
    text: "The inspiration design you sent is a 2-tier cake that serves 25-30 guests. Since you need fewer servings, would you prefer a one-tier cake in the same design instead?"
  },
  {
    name: "photo",
    text: "Are you looking for a round cake with an edible photo printed on top of it?"
  },
  {
    name: "review",
    text: "Just checking-in to see if you got a chance to review your quote. Let us know if you have any questions."
  },
  {
    name: "avail",
    text: "We have very limited availability for your event date. If you're interested, please order online to secure your date. After ordering, you'll be able to enter your preferred flavor, filling & inscription. Close this chat window & click the BOOK button to order now."
  },
  {
    name: "expired",
    text: "Just noticed that your quote is now expired & wanted to let you know that we still have some availability for your event date. Let us know if you have any questions or if you’re interested in ordering."
  },
  {
    name: "reopen",
    text: "We just got a notification that you are still interested. We have limited availability for your event date, but we might be able to squeeze you in. Let us know if we can answer any questions or if you would like to book?"
  },
  {
    name: "budget",
    text: "Just wanted you to know that your quote is based on the inspiration photo and number of servings you provided, but we can always pick a simpler design or reduce the number of servings to try and get closer to your budget. Let us know if you're interested."
  },
  {
    name: "leakage",
    text: "Sorry, we have to abide by BakeStreet’s policy to keep our conversation online. Once you order, BakeStreet will connect us & we’ll be able to call each other. Happy to answer any questions or concerns you may have."
  }
];

const SAAS_TEMPLATES = [
  {
    name: "review",
    text: "Just checking-in to see if you got a chance to review your quote. Let us know if you have any questions."
  },
  {
    name: "photo",
    text: "Are you looking for a round cake with an edible photo printed on top of it?"
  },
  {
    name: "avail",
    text: "We have limited availability for your event date. If you're interested, please order online to secure your date. Close this chat window to view your quote. Scroll down to select flavor, filling and an optional inscription. Finally, click the button to proceed to checkout where you'll be able to choose pickup/delivery options and pay to confirm your order."
  },
  {
    name: "expired",
    text: "Just noticed that your quote is now expired & wanted to let you know that we still have some availability for your event date. Let us know if you have any questions or if you’re interested in ordering."
  },
  {
    name: "budget",
    text: "Just wanted you to know that your quote is based on the inspiration photo and number of servings you provided, but we can always pick a simpler design or reduce the number of servings to try and get closer to your budget. Let us know if you're interested."
  },
  {
    name: "3ds",
    text: "We checked your payment attempt and it appears your payment failed because your card issuer was unable to confirm your identity. This can sometimes happen when you use your card from an unknown device or location. When this happens, your card issuer sends a text message with an authentication code to the phone number of the card holder. You’ll need to enter this code to complete your payment. If you’re using someone else’s card like a family member, then you’ll need to ask them for the authentication code. Alternatively, you can use ApplePay or GooglePay to complete your payment. "
  }
];

export default function Chat(props) {
  const { request, quote } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const ref = useRef(null);

  const [open, setOpen] = useState(getQueryParam(window.location.search, "to") === quote.id);
  const [chat, setChat] = useState(quote.chat);

  useEffect(() => {
    if (open) {
      bkstApi(`/request/${request.id}/quote/${quote.id}/chat`).then((res) => {
        setChat(res.data.chat);
      });
    }
  }, [open]);

  const handleNewMsg = (msg) => {
    const allMsgs = [...chat, msg];
    setChat(allMsgs);
  };

  return (
    <Box>
      <Fab color="secondary" sx={{ position: "fixed", bottom: "20px", right: "20px" }} onClick={(e) => setOpen(true)}>
        <Badge color="primary" badgeContent={(chat && Object.keys(chat).length) || 0}>
          <ChatBubbleOutlineIcon />
        </Badge>
      </Fab>
      <Dialog fullScreen={fullScreen} open={open} onClose={() => setOpen(false)}>
        <DialogTitle
          style={{ backgroundColor: "#ECECEC", margin: "0", paddingLeft: "10px", paddingRight: "5px", paddingTop: "15px", paddingBottom: "10px" }}
        >
          <Box display="flex" alignItems="top">
            <ChatHeader request={request} quote={quote} status={props.status} />
          </Box>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon sx={{ color: "#999" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent className="chat-bg" style={{ minHeight: "50vh", padding: "0", margin: "0", display: "flex" }}>
          <Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
            <ChatCanvas chat={chat} uid={quote.id} />
            <Box textAlign="center" ref={ref}></Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ margin: "0", padding: "0" }}>
          <Box sx={{ flexGrow: "1" }}>
            <NewMessage
              uid={quote.id}
              handleNewMsg={handleNewMsg}
              uploadFolder={`img/r/${request.id}`}
              postUrl={`/admin/requests/${request.id}/quotes/${quote.id}/chat`}
              templates={request.src === "mkt" ? MKT_TEMPLATES : SAAS_TEMPLATES}
            />
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
