import React from "react";
import { Box, Paper } from "@mui/material";
import HTMLViewer from "../../common/component/HTMLViewer";

export default function KitchenInstructions(props) {
  const { data } = props;

  return (
    <Paper elevation={0}>
      <Box p={2}>
        <HTMLViewer content={data?.notes || "-"} style={{ fontSize: ".85rem" }} />
      </Box>
    </Paper>
  );
}
