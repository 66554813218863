import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import BakerStatus from "../component/BakerStatus";
import bkstApi from "../../common/component/api";
import { Link as RouterLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";

const columns = [
  {
    field: "thumbnail",
    headerName: "Pic",
    width: 100,
    renderCell: (params) => <img alt="" src={params.value} style={{ width: "50px", height: "50px" }}/>,
  },
  { field: "biz", headerName: "Business Name", width: 200 },
  {
    field: "view",
    headerName: "+",
    width: 60,
    renderCell: (params) => (
      <Link target="_blank" rel="noreferrer" to={params.value} component={RouterLink}>
        view
      </Link>
    ),
  },
  { field: "city", headerName: "City", width: 150 },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => <BakerStatus status={params.value} />,
  },
  {
    field: "orders",
    headerName: "Orders",
    width: 130,
  },
  {
    field: "rating",
    headerName: "Rating",
    width: 130,
  },
];

export default function Bakers() {
  const [state, setState] = useState("");
  const [status, setStatus] = useState("");
  const [results, setResults] = useState([]);

  const search = (e) => {
    e.preventDefault();

    bkstApi(`/admin/bakers?state=${state}&status=${status}`).then((res) => {
      var translatedData = Object.entries(res.data).map(([key, value]) => {
        return {
          id: value.id,
          thumbnail: `${BAKER_PIC_FOLDER}/${value.id}/photo400.webp`,
          biz: value.bizName,
          city: value.location && value.location.city || '',
          state: value.location && value.location.state || '',
          status: value.status,
          orders: value.orders,
          rating: value.rating,
          view: `/baker/${value.id}`,
        };
      });
      setResults(translatedData);
    });
  };

  return (
    <div>
      <Container maxWidth="md">
        <Box my={3}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="overline">SEARCH BAKERS</Typography>
            </Grid>
          </Grid>
          <Paper>
            <Box p={6} align="center">
              <form onSubmit={search}>
                <FormControl variant="outlined" style={{ minWidth: "200px" }}>
                  <InputLabel id="state-label">State</InputLabel>
                  <Select labelId="state-label" label="State" value={state} id="state" name="state" onChange={(e) => setState(e.target.value)}>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="CA">CA</MenuItem>
                    <MenuItem value="CO">CO</MenuItem>
                    <MenuItem value="CT">CT</MenuItem>
                    <MenuItem value="NJ">NJ</MenuItem>
                    <MenuItem value="NY">NY</MenuItem>
                    <MenuItem value="UT">UT</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="outlined" style={{ minWidth: "200px", textAlign: "left" }}>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select labelId="status-label" label="Status" value={status} id="status" name="status" onChange={(e) => setStatus(e.target.value)}>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="scout">Scout</MenuItem>
                    <MenuItem value="new">New</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="quote">Quote</MenuItem>
                    <MenuItem value="onboard">Onboard</MenuItem>
                    <MenuItem value="offboard">Offboard</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
                <Button variant="contained" color="primary" type="submit" style={{ minHeight: "56px" }}>
                  <SearchIcon />
                </Button>
              </form>
            </Box>
          </Paper>
          {results.length > 0 && (
            <Box mt={5} style={{ height: 1400, width: "100%" }}>
              <DataGrid rows={results} columns={columns} pageSize={50} />
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
}
