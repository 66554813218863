import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import bkstApi from "../../common/component/api";
import CloseIcon from "@mui/icons-material/Close";

export default function CloseRequest(props) {
  const [open, setOpen] = useState(false);
  const [nogo, setNogo] = useState("");

  const updateStatus = () => {
    bkstApi
      .put(`/requests/${props.reqId}`, { status: "closed", nogo })
      .then((res) => {
        setOpen(false);
        props.onClose();
      })
      .catch((error) => {
        console.error("Failure");
      });
  };

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={() => setOpen(true)} size="small">
        <CloseIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>Close - {props.title}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography color="textSecondary" gutterBottom>
              Why are we closing this request?
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="nogo-label">Reason</InputLabel>
              <Select value={nogo} labelId="nogo-label" label="Nogo Reason" id="nogo" name="nogo" onChange={(e) => setNogo(e.target.value)}>
                <MenuItem value="budget">Budget</MenuItem>
                <MenuItem value="coverage">Coverage</MenuItem>
                <MenuItem value="duplicate">Duplicate</MenuItem>
                <MenuItem value="timing">Last Minute</MenuItem>
                <MenuItem value="stop">Stop</MenuItem>
                <MenuItem value="trust">Trust</MenuItem>
                <MenuItem value="unknown">Unknown</MenuItem>
                <MenuItem value="unreachable">Unreachable</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box align="right">
            <Button onClick={() => setOpen(false)} color="primary">
              Close
            </Button>
            <Button variant="contained" onClick={updateStatus} color="primary" style={{ marginLeft: "2rem" }}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
