import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/component/api";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";
import currency from "currency.js";
import { getDefaultQuoteExpiration, getAmountWithTxnFee } from "../../common/component/util";
import TextWithReadMore from "../../common/component/TextWithReadMore";
import moment from "moment";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";

export default function AutoQuote(props) {
  const { req, quote, productType, productSubtype, data, specs, tags } = props;

  const [variant, setVariant] = useState("");
  const [pricingVariant, setPricingVariant] = useState("");

  const [estimate, setEstimate] = useState("");
  const [includeFees, setIncludeFees] = useState(true);

  const [option, setOption] = useState("fee");
  const [delivery, setDelivery] = useState("");
  const [note, setNote] = useState(req.details ? `We can accommodate your request "${req.details}"` : "");

  /*useEffect(() => {
    if (variant) {
      let price = +variant.pricing.floor;
      if (specs) {
        const decorPricing = getDecorPricing(specs, data.model[productType].decor, variant.pricing.floor);
        console.log("decorPricing", decorPricing);
        price += decorPricing;
      }
      setEstimate(price);
    }
  }, [specs, variant]);*/

  useEffect(() => {
    let price = 0;
    if (variant && pricingVariant) {
      price = Number(pricingVariant.amount);

      if (+pricingVariant.variance) {
        const min = Number(-pricingVariant.variance);
        const max = Number(pricingVariant.variance);
        const rand = Number((Math.random() * (max - min) + min).toFixed(2));
        console.log(min, max, rand, Math.ceil(price * rand));

        price = price + Math.ceil(price * rand);
      }

      // For free delivery, add delivery fee to estimate
      if (option === "free" && delivery) {
        price = price + Number(delivery);
      }
    }
    setEstimate(price);
  }, [variant, pricingVariant, delivery]);

  /*const getDecorPricing = (specs, model, floorPrice) => {
    let price = 0;
    for (const spec of specs) {
      const cat = spec.split(".").shift();
      const value = spec.split(".").pop();
      console.log("processing", cat, value);

      if (cat === "surface") {
        const pct = model.surface[value];
        console.log("pct", pct);
        price += floorPrice * (+pct / 100);
      } else {
        const surcharge = model[cat][value];
        console.log("surcharage", surcharge);
        price += +surcharge;
      }
    }

    return price;
  };*/

  const handleSubmit = (e) => {
    e.preventDefault();

    var payload = {
      quote: estimate,
      option,
      delivery,
      exdate: getDefaultQuoteExpiration(req.date),
      productType,
      shape: `${variant.product.tier ? `${variant.product.tier}-Tier ` : ""}${variant.product.shape}`,
      size: variant.product.size,
      servings: variant.product.servings,
      frosting: variant.product.frosting,
      status: "active",
      specs,
      tags,
      note,
      includeFees
    };

    // remove delivery fee, since its been added to estimate
    if (option === "free") {
      payload.delivery = 0;
    }

    if (includeFees) {
      payload.quote = getAmountWithTxnFee(estimate);
      payload.delivery = getAmountWithTxnFee(payload.delivery);
    }

    bkstApi.put(`/requests/${req.id}/quotes/${quote.id}`, payload).then((res) => {});
  };

  let alert = "";
  if (req.bakerId == "m7w2nldh2VlpJijvH2tC") {
    const ooo = ["2023-12-23", "2023-12-24", "2023-12-25"];
    const noDelivery = [
      "2023-12-11",
      "2023-12-12",
      "2023-12-13",
      "2023-12-14",
      "2023-12-15",
      "2023-12-16",
      "2023-12-17",
      "2023-12-18",
      "2023-12-19",
      "2023-12-20",
      "2023-12-21",
      "2023-12-22",
      "2023-12-23",
      "2023-12-24"
    ];

    if (ooo.includes(moment(req.date).format("YYYY-MM-DD"))) {
      alert += "DON'T SEND QUOTES FOR THIS DATE!!!";
    }

    if (noDelivery.includes(moment(req.date).format("YYYY-MM-DD"))) {
      alert += "NO DELIVERY!!!";
    }
  }

  if (quote.quote) {
    alert += " !!! Quote Already Sent !!!";
  }

  return (
    <Box mb={4} p={2} key={quote.id} sx={{ border: "1px solid #EEE" }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={4} alignItems={"center"}>
              <Grid item xs={12}>
                <Box mb={1} sx={{ display: "flex", gap: "15px", alignItems: "center", justifyContent: "center" }}>
                  <Avatar src={`${BAKER_PIC_FOLDER}/${quote.id}/photo400.webp`} />
                  <Typography variant="h3">
                    {currency(estimate).format()}
                    {quote.orig && (
                      <small style={{ color: "red", fontSize: "13px", marginLeft: "5px", textDecoration: "line-through" }}>
                        {currency(quote.orig, { precision: 0 }).format()}
                      </small>
                    )}
                  </Typography>
                </Box>
                <TextWithReadMore data={data.alerts.notes} limit={200} />
              </Grid>
              {alert && (
                <Grid item sm={12} xs={12}>
                  <Alert severity="error">{alert}</Alert>
                </Grid>
              )}
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  value={variant}
                  onChange={(e, val) => setVariant(val)}
                  getOptionLabel={(option) =>
                    option &&
                    option.product &&
                    `${option.product.frosting}, ${option.product.shape} ${option.product.size} (${option.product.servings} servings)`
                  }
                  options={data.model[productType]?.type[productSubtype] || []}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Variant" />}
                  disableClearable
                  isOptionEqualToValue={() => false}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Autocomplete
                  value={pricingVariant}
                  onChange={(e, val) => setPricingVariant(val)}
                  getOptionLabel={(option) => (option.amount && currency(option.amount).format()) || ""}
                  options={variant.pricing?.levels || []}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Pricing Variant" />}
                  disableClearable
                  isOptionEqualToValue={() => false}
                  disabled={!variant}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" required fullWidth>
                  <InputLabel id="option-label">Fulfillment</InputLabel>
                  <Select
                    value={option}
                    labelId="option-label"
                    label="Fulfillment"
                    id="option"
                    name="option"
                    onChange={(e) => setOption(e.target.value)}
                  >
                    <MenuItem value="free">Free delivery</MenuItem>
                    <MenuItem value="fee">Delivery fee extra</MenuItem>
                    <MenuItem value="no">No delivery</MenuItem>
                  </Select>
                  <FormHelperText>{req.location}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="delivery"
                  value={delivery}
                  fullWidth
                  id="delivery"
                  label="Delivery"
                  onChange={(e) => setDelivery(e.target.value)}
                  onWheel={(e) => {
                    e.target && e.target.blur();
                  }}
                  type="number"
                  required={option === "fee"}
                  helperText={`${quote.baker?.distance} miles`}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={note}
                  variant="outlined"
                  fullWidth
                  id="note"
                  label="Note"
                  name="note"
                  multiline
                  rows={1}
                  onChange={(e) => setNote(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  className="includeFees"
                  control={<Checkbox checked={includeFees} onChange={(e) => setIncludeFees(e.target.checked)} name="consent" color="secondary" />}
                  label="Include transaction fees"
                />
                {includeFees && +estimate > 0 && (
                  <Grid item xs={12}>
                    <Box p={1} sx={{ backgroundColor: "#e5f6fd", color: "#2980b9" }}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography variant="h6">
                            {currency(estimate).format()} &#8674; {currency(getAmountWithTxnFee(estimate)).format()}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} align="right">
                          {option === "fee" && (
                            <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em", justifyContent: "flex-end" }}>
                              <LocalShippingOutlinedIcon style={{ fontSize: "1.1rem", marginRight: "3px" }} />
                              {currency(delivery).format()} &#8674; {currency(getAmountWithTxnFee(delivery)).format()}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth type="submit" disabled={!pricingVariant} variant="outlined">
                  ADD
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
