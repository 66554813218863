import React from "react";
import { Box, Link, Typography } from "@mui/material";
import currency from "currency.js";
import { Link as RouterLink } from "react-router-dom";

export default function CustomerStats(props) {
  const { orders, reqs } = props.stats;
  return (
    <Box>
      {orders && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "3px", alignItems: "center" }}>
          <Typography variant="caption">{`${orders.past.length + orders.open.length} orders. ${currency(orders.spend, {
            precision: 0
          }).format()}. `}</Typography>
          {orders.past.length > 0 && (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "3px", alignItems: "center" }}>
              <Typography variant="caption" color="textSecondary">
                Past:{" "}
              </Typography>
              {orders.past.map((o, idx) => (
                <Link key={idx} component={RouterLink} to={`/order/${o}`} style={{ fontSize: "10px", marginRight: "5px" }}>
                  {++idx}
                </Link>
              ))}
            </Box>
          )}
          {orders.open.length > 0 && (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "3px", alignItems: "center" }}>
              <Typography variant="caption" color="textSecondary">
                Open:{" "}
              </Typography>
              {orders.open.map((o, idx) => (
                <Link key={idx} component={RouterLink} to={`/order/${o}`} style={{ fontSize: "10px", marginRight: "5px" }}>
                  {++idx}
                </Link>
              ))}
            </Box>
          )}
        </Box>
      )}
      {reqs && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "3px", alignItems: "center" }}>
          <Typography variant="caption">{`${reqs.past.length + reqs.open.length} requests. `}</Typography>
          {reqs.past.length > 0 && (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "3px", alignItems: "center" }}>
              <Typography variant="caption" color="textSecondary">
                Past:{" "}
              </Typography>
              {reqs.past.map((o, idx) => (
                <Link key={idx} component={RouterLink} to={`/request/${o}`} style={{ fontSize: "10px" }}>
                  {++idx}
                </Link>
              ))}
            </Box>
          )}
          {reqs.open.length > 0 && (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "3px", alignItems: "center" }}>
              <Typography variant="caption" color="textSecondary">
                Open:{" "}
              </Typography>
              {reqs.open.map((o, idx) => (
                <Link key={idx} component={RouterLink} to={`/request/${o}`} style={{ fontSize: "10px" }}>
                  {++idx}
                </Link>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
