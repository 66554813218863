import React, { useState, useEffect } from "react";
import { Avatar, Box, Button, Container, Divider, Grid, InputAdornment, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import bkstApi from "../../common/component/api";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";

export default function BakerPricingModel(props) {
  const { bakerId } = useParams();
  const navigate = useNavigate();

  const [floorFondant, setFloorFondant] = useState("");
  const [floorButtercream, setFloorButtercream] = useState("");

  const [fondantBasePrice, setFondantBasePrice] = useState("");
  const [buttercreamBasePrice, setButtercreamBasePrice] = useState("");

  // 3D Figurine
  const [sm3dFig, setSm3dFig] = useState("");
  const [md3dFig, setMd3dFig] = useState("");
  const [lg3dFig, setLg3dFig] = useState("");

  // 2D surface decor
  const [lowCxSurface, setLowCxSurface] = useState("");
  const [mdCxSurface, setMdCxSurface] = useState("");
  const [hiCxSurface, setHiCxSurface] = useState("");

  // Flowers
  const [freshFlowers, setFreshFlowers] = useState("");
  const [silkFlowers, setSilkFlowers] = useState("");
  const [sugarFlowers, setSugarFlowers] = useState("");

  // Spheres
  const [edSpheres, setEdSpheres] = useState("");
  const [noedSpheres, setNoedSpheres] = useState("");

  // Liquor
  const [miniLiquorBottle, setMiniLiquorBottle] = useState("");

  // Topper
  const [cardstockTopper, setCardstockTopper] = useState("");
  const [acrylicTopper, setAcrylicTopper] = useState("");
  const [rhinestoneTopper, setRhinestoneTopper] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/baker/${bakerId}/config/pricing`)
      .then((res) => {
        if (res.data && res.data.config) {
          const cakePricing = res.data.config.cake;
          if (cakePricing) {
            setFondantBasePrice(cakePricing.basePricing.fondant);
            setButtercreamBasePrice(cakePricing.basePricing.buttercream);

            const floor = cakePricing.basePricing.floor;
            if (floor) {
              setFloorFondant(floor.fondant);
              setFloorButtercream(floor.buttercream);
            }

            const decor = cakePricing.decor;
            if (decor) {
              const flowers = decor.flowers;
              if (flowers) {
                setFreshFlowers(flowers.fresh);
                setSilkFlowers(flowers.silk);
                setSugarFlowers(flowers.sugar);
              }

              const figurine = decor.figurine;
              if (figurine) {
                setSm3dFig(figurine.sm);
                setMd3dFig(figurine.md);
                setLg3dFig(figurine.lg);
              }

              const liquorBottle = decor.liquorBottle;
              if (liquorBottle) {
                setMiniLiquorBottle(liquorBottle.mini);
              }

              const spheres = decor.spheres;
              if (spheres) {
                setEdSpheres(spheres.edible);
                setNoedSpheres(spheres.nonEdible);
              }

              const surface = decor.surface;
              if (surface) {
                setLowCxSurface(surface.lowComplexity);
                setMdCxSurface(surface.medComplexity);
                setHiCxSurface(surface.highComplexity);
              }

              const topper = decor.topper;
              if (topper) {
                setCardstockTopper(topper.cardstock);
                setAcrylicTopper(topper.acrylic);
                setRhinestoneTopper(topper.rhinestone);
              }
            }
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const cake = {
      basePricing: { buttercream: buttercreamBasePrice, fondant: fondantBasePrice, floor: { fondant: floorFondant, buttercream: floorButtercream } },
      decor: {
        flowers: { fresh: freshFlowers, silk: silkFlowers, sugar: sugarFlowers },
        figurine: { sm: sm3dFig, md: md3dFig, lg: lg3dFig },
        liquorBottle: { mini: miniLiquorBottle },
        spheres: { edible: edSpheres, nonEdible: noedSpheres },
        surface: { lowComplexity: lowCxSurface, medComplexity: mdCxSurface, highComplexity: hiCxSurface },
        topper: { cardstock: cardstockTopper, acrylic: acrylicTopper, rhinestone: rhinestoneTopper }
      }
    };

    bkstApi.put(`/baker/${bakerId}/config/pricing`, { cake }).then((res) => {
      navigate(`/baker/${bakerId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box>
          <Box align="center" my={5}>
            <Avatar src={`${BAKER_PIC_FOLDER}/${bakerId}/photo400.webp`} style={{ width: "75px", height: "auto" }} />
            <Typography variant="h4">Pricing Model</Typography>
          </Box>
          <Paper>
            <Box p={3}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="textSecondary">
                      Base Pricing
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="fondantBasePrice"
                      value={fondantBasePrice}
                      label="Fondant / Serving"
                      name="fondantBasePrice"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setFondantBasePrice(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="floorFondant"
                      value={floorFondant}
                      label="Fondant Floor"
                      name="floorFondant"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setFloorFondant(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={4}>
                    <TextField
                      id="buttercreamBasePrice"
                      value={buttercreamBasePrice}
                      label="Buttercream / Serving"
                      name="buttercreamBasePrice"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setButtercreamBasePrice(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="floorButtercream"
                      value={floorButtercream}
                      label="Buttercream Floor"
                      name="floorButtercream"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setFloorButtercream(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box my={4}>
                      <Divider />
                    </Box>
                    <Box mb={2}>
                      <Typography variant="h6" color="textSecondary">
                        Surface Decoration
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        Enter surcharge as a percentage of base pricing, based on surface decoration complexity
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="lowCxSurface"
                      value={lowCxSurface}
                      label="Low Complexity"
                      name="lowCxSurface"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setLowCxSurface(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="mdCxSurface"
                      value={mdCxSurface}
                      label="Medium Complexity"
                      name="mdCxSurface"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setMdCxSurface(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="hiCxSurface"
                      value={hiCxSurface}
                      label="High Complexity"
                      name="hiCxSurface"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setHiCxSurface(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="start">%</InputAdornment>
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box my={4}>
                      <Divider />
                    </Box>
                    <Typography variant="h6" color="textSecondary">
                      Figurines
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="sm3dFig"
                      value={sm3dFig}
                      label="Small"
                      name="sm3dFig"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setSm3dFig(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="md3dFig"
                      value={md3dFig}
                      label="Medium"
                      name="md3dFig"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setMd3dFig(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="lg3dFig"
                      value={lg3dFig}
                      label="Large"
                      name="lg3dFig"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setLg3dFig(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box my={4}>
                      <Divider />
                    </Box>
                    <Typography variant="h6" color="textSecondary">
                      Flowers
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="freshFlowers"
                      value={freshFlowers}
                      label="Fresh"
                      name="freshFlowers"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setFreshFlowers(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="silkFlowers"
                      value={silkFlowers}
                      label="Silk"
                      name="silkFlowers"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setSilkFlowers(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="sugarFlowers"
                      value={sugarFlowers}
                      label="Sugar"
                      name="sugarFlowers"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setSugarFlowers(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box my={4}>
                      <Divider />
                    </Box>
                    <Typography variant="h6" color="textSecondary">
                      Toppers
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="cardstockTopper"
                      value={cardstockTopper}
                      label="Cardstock"
                      name="cardstockTopper"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setCardstockTopper(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="acrylicTopper"
                      value={acrylicTopper}
                      label="Acrylic"
                      name="acrylicTopper"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setAcrylicTopper(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="rhinestoneTopper"
                      value={rhinestoneTopper}
                      label="Rhinestone"
                      name="rhinestoneTopper"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setRhinestoneTopper(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box my={4}>
                      <Divider />
                    </Box>
                    <Typography variant="h6" color="textSecondary">
                      Spheres
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="edSpheres"
                      value={edSpheres}
                      label="Edible"
                      name="edSpheres"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setEdSpheres(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="noedSpheres"
                      value={noedSpheres}
                      label="Non-Edible"
                      name="noedSpheres"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setNoedSpheres(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box my={4}>
                      <Divider />
                    </Box>
                    <Typography variant="h6" color="textSecondary">
                      Liquor Bottle
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="miniLiquorBottle"
                      value={miniLiquorBottle}
                      label="Mini"
                      name="miniLiquorBottle"
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(e) => setMiniLiquorBottle(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} align="right">
                    <Button type="submit" variant="contained" color="primary">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </Container>
    );
  }
}
