import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, RadioGroup } from "@mui/material";
import bkstApi from "../../common/component/api";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import NotifyOption from "../../request/component/NotifyOption";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export const options = [{ code: "bqr1", desc: "Request quote" }];

export default function QuoteNotificationButton(props) {
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState("bqr1");
  const [quote, setQuote] = useState();
  const [trail, setTrail] = useState();

  const handleClick = () => {
    bkstApi(`/notify?reqId=${props.reqId}&quoteId=${props.quoteId}`).then((res) => {
      setTrail(res.data.trail);
      setQuote(res.data.quote);
      setOpen(true);
    });
  };

  const notify = (confirm) => {
    bkstApi.post(`/request/${props.reqId}/quote/${props.quoteId}/notify`, { code, confirm }).then((res) => {
      if (confirm) {
        setOpen(false);
      }
    });
  };

  const isOptionDisabled = (code) => {
    if (quote) {
      if (!quote.resTs) {
        return false;
      }
    }
    return true;
  };

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={handleClick} disabled={props.disabled}>
        <NotificationsActiveOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>Send Notification</DialogTitle>
        <DialogContent>
          <RadioGroup aria-label="code" name="code" value={code} onChange={(e) => setCode(e.target.value)}>
            {options &&
              options.map((item) => {
                return (
                  <NotifyOption
                    key={item.code}
                    code={item.code}
                    label={item.desc}
                    trail={trail && trail[item.code]}
                    disabled={isOptionDisabled(item.code)}
                  />
                );
              })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setOpen(false)} style={{ position: "absolute", top: 0, right: 0 }}>
            <CancelOutlinedIcon />
          </IconButton>
          <Button onClick={() => notify(true)} color="primary" variant="contained" style={{ marginRight: "10px" }} disabled={!code}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
