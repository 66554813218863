import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../../common/component/api";

export default function ReviewEntry(props) {
  const [mode, setMode] = useState(props.data.id ? "edit" : "add");
  const [name, setName] = useState(props.data.name);
  const [source, setSource] = useState(props.data.source || "bkst");
  const [decoration, setDecoration] = useState(props.data.decoration || 0);
  const [taste, setTaste] = useState(props.data.taste || 0);
  const [communication, setCommunication] = useState(props.data.communication || 0);
  const [fulfillment, setFulfillment] = useState(props.data.fulfillment || 0);
  const [text, setText] = useState(props.data.text || "");

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = { name, source, decoration, taste, communication, fulfillment, text };

    if (mode === "add") {
      bkstApi.post(`/admin/baker/${props.baker.id}/review`, payload).then((res) => {
        props.setOpen(false);
        props.onDone(res.data);
      });
    } else if (mode === "edit") {
      bkstApi.put(`/baker/${props.baker.id}/review/${props.data.id}`, payload).then((res) => {
        props.setOpen(false);
        props.onDone(res.data);
      });
    }
  };

  return (
    <Box display="inline" style={props.style}>
      <Dialog fullWidth={true} maxWidth="sm" open={props.open} onClose={() => props.setOpen(false)}>
        <DialogTitle id="alert-dialog-title">
          <Typography>Add Review</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Decoration
                  </Typography>
                  <Rating name="decoration" value={Number(decoration)} size="small" onChange={(e) => setDecoration(e.target.value)} />
                  <Typography variant="subtitle2" gutterBottom>
                    Taste
                  </Typography>
                  <Rating name="taste" value={Number(taste)} size="small" onChange={(e) => setTaste(e.target.value)} />
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Communication
                  </Typography>
                  <Rating name="communication" value={Number(communication)} size="small" onChange={(e) => setCommunication(e.target.value)} />
                  <Typography variant="subtitle2" gutterBottom>
                    Pickup/Delivery
                  </Typography>
                  <Rating name="fulfillment" value={Number(fulfillment)} size="small" onChange={(e) => setFulfillment(e.target.value)} />
                </Box>
              </Grid>
              <Grid item sm={12}>
                <TextField
                  id="text"
                  value={text}
                  label="Text"
                  name="text"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  required
                  onChange={(e) => setText(e.target.value)}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  id="name"
                  value={name}
                  label="Name"
                  name="name"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item sm={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="source-label">Source</InputLabel>
                  <Select value={source} labelId="source-label" label="Source" id="source" name="source" onChange={(e) => setSource(e.target.value)}>
                    <MenuItem value="bkst">BakeStreet</MenuItem>
                    <MenuItem value="fb">Facebook</MenuItem>
                    <MenuItem value="goog">Google</MenuItem>
                    <MenuItem value="yelp">Yelp</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12} align="right">
                <Button type="submit" variant="contained" color="primary">
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
