import React from "react";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { InputAdornment } from "@mui/material";

export default function BDateTimePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDateTimePicker
        {...props}
        slotProps={{
          toolbar: { hidden: true },
          textField: {
            required: props.required,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarMonthOutlinedIcon />
                </InputAdornment>
              )
            }
          }
        }}
        views={["day", "hours", "minutes"]}
        format="MMM d, y h:m a"
        ampmInClock={true}
        value={props.value ? new Date(props.value) : null}
        onChange={(value) => props.onChange(value)}
      />
    </LocalizationProvider>
  );
}
