import React, { useEffect, useState } from "react";
import { Box, Chip, Container, Link, LinearProgress } from "@mui/material";
import bkstApi from "../../common/component/api";
import QuoteSummary from "../../quote/component/QuoteSummary";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const FILTERS = ["active", "pending", "recentlyClosed"];

export default function BakerQuotes(props) {
  const { bakerId } = useParams();

  const [quotes, setQuotes] = useState([]);
  const [filter, setFilter] = useState("active");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    bkstApi.get(`/baker/${bakerId}/quote?filter=${filter}`).then((res) => {
      let data = res.data.quotes;
      if (filter === "pending") {
        data.sort(triage);
      } else {
        data.sort((q1, q2) => (q1.date < q2.date ? -1 : 1));
      }
      
      setQuotes(data);
      setLoading(false);
    });
  }, [filter]);

  const triage = (q1, q2) => {
    const q1Score = q1.servings / moment(q1.date).diff(moment(), "days").toFixed(2);
    const q2Score = q2.servings / moment(q2.date).diff(moment(), "days").toFixed(2);

    return q1Score > q2Score ? -1 : 1;
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box my={6} align="center">
          {FILTERS.map((o, idx) => {
            return (
              <Chip
                key={idx}
                size="medium"
                color={filter === o ? "primary" : "secondary"}
                variant="outlined"
                label={o}
                clickable
                component={Link}
                onClick={() => setFilter(o)}
              />
            );
          })}
        </Box>
        {quotes.map((request) => {
          return (
            <Link key={request.id} underline="none" component={RouterLink} to={`/request/${request.id}`} href="#">
              <QuoteSummary request={request} />
            </Link>
          );
        })}
      </Container>
    );
  }
}
