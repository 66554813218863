import React, { useEffect, useState } from "react";
import { Avatar, Box, Container, Chip, Grid, LinearProgress, Link, Typography } from "@mui/material";
import bkstApi from "../../common/component/api";
import RequestSummary from "../component/RequestSummary";
import { Link as RouterLink } from "react-router-dom";
import { STORAGE_BUCKET } from "../../common/component/constants";

const FILTERS = ["pending", "active"];

export default function RequestDashboard(props) {
  const [filter, setFilter] = useState("pending");

  const [leads, setLeads] = useState([]);
  const [summary, setSummary] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, [filter]);

  const triage = (o1, o2) => {
    if (o1.src !== o2.src) {
      return o1.src === "mkt" ? -1 : 1;
    } else {
      //const o1Score = o1.servings / moment(o1.date).diff(moment(), "days").toFixed(2);
      //const o2Score = o2.servings / moment(o2.date).diff(moment(), "days").toFixed(2);

      console.log("compare", o1.ts._seconds, o2.ts._seconds);
      return o1.ts > o2.ts ? -1 : 1;
    }
  };

  const getData = () => {
    setLoading(true);

    bkstApi.get(`/admin/request?filter=${filter}`).then((res) => {
      let data = res.data.filter((o) => !(o.phone === "+16177716516" && process.env.NODE_ENV === "production"));
      data.sort(triage);
      setLeads(data);

      let [mkt, saas] = [0, 0];
      let summaryByBaker = {};
      if (data) {
        for (const o of data) {
          if (o.src === "mkt") {
            mkt++;
          } else {
            saas++;

            let smry = summaryByBaker[o.bakerId] || { count: 0, late: 0, invoice: 0 };
            let isLate = Date.now() - o.ts > 86400000;
            summaryByBaker[o.bakerId] = { count: +smry.count + 1, late: isLate ? +smry.late + 1 : +smry.late, invoice: o.type === 'invoice' ? +smry.invoice + 1 : +smry.invoice };
          }
        }
      }
      setSummary({
        mkt,
        saas,
        leaderboard: Object.entries(summaryByBaker)
          .map(([k, v]) => {
            return {
              id: k,
              ...v
            };
          })
          .sort((o1, o2) => +o2.count - +o1.count)
      });

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box my={2}>
        <Container maxWidth="sm">
          <Box my={4} align="center">
            <Typography variant="h1" gutterBottom>
              Quotes
            </Typography>
            {FILTERS.map((o, idx) => {
              return (
                <Chip
                  key={idx}
                  size="medium"
                  color={filter === o ? "primary" : "secondary"}
                  variant="outlined"
                  label={o}
                  clickable
                  component={Link}
                  onClick={() => setFilter(o)}
                />
              );
            })}
          </Box>
          {summary && (
            <Box my={2} p={2} style={{ backgroundColor: "#EEE" }} align="center">
              <Grid container>
                <Grid item xs={6} align="center">
                  <Typography variant="overline" color="textSecondary">
                    MKT
                  </Typography>
                  <Typography variant="h4">{summary.mkt}</Typography>
                </Grid>
                <Grid item xs={6} align="center">
                  <Typography variant="overline" color="textSecondary">
                    SAAS
                  </Typography>
                  <Typography variant="h4">{summary.saas}</Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box my={2} sx={{ display: "flex", gap: "15px", overflowX: "auto", maxWidth: "85vw" }}>
            {summary.leaderboard.map((o) => (
              <Box py={2} sx={{ textAlign: "center" }}>
                <Link component={RouterLink} to={`/baker/${o.id}`}>
                  <Avatar src={`${STORAGE_BUCKET}/img/b/${o.id}/logo400.webp`} />
                </Link>
                <Typography display="block" variant="overline">
                  {o.count}
                </Typography>
                { filter === 'pending' && <Typography color="textSecondary" display="block" sx={{ lineHeight: "0.6" }}  variant="overline">({o.late})</Typography> }
                { filter === 'active' && <Typography color="textSecondary" display="block" sx={{ lineHeight: "0.6", fontSize: "9px" }}>{o.invoice} INV</Typography> }

              </Box>
            ))}
          </Box>
          {leads.map((request) => {
            return <RequestSummary key={request.id} req={request} />;
          })}
        </Container>
      </Box>
    );
  }
}
