import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Container, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../common/component/api";
import { formatPhoneNumber } from "../../common/component/util";

export default function Customer(props) {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi(`/admin/customer/${customerId}`).then((res) => {
      setCustomer(res.data.customer);
      setLoading(false);
    });
  }, [customerId]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box my={4}>
          <Box align="center">
            <Typography variant="h2" gutterBottom>{`${customer.firstName} ${customer.lastName}`}</Typography>
            <Typography variant="body1" color="textSecondary">{formatPhoneNumber(customer.phone)} &#9679; {customer.email}</Typography>
          </Box>
        </Box>
        <Box>
          <Button
            disableElevation
            color="secondary"
            variant="contained"
            fullWidth
            target="_blank"
            href={`https://console.firebase.google.com/project/bakestreet-f55a0/firestore/data/~2Fcustomer~2F${customerId}`}
          >
            View Customer in DB
          </Button>
        </Box>
      </Container>
    );
  }
}
