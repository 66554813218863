import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import moment from "moment";
import ExpandableImage from "../ExpandableImage";
import ErrorIcon from "@mui/icons-material/Error";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Linkify from "react-linkify";
import { bakeStreetSquareLogo } from "../constants";

export default function ChatBubble(props) {
  const { chat, uid } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  let style = {
    padding: "7px 10px 5px 10px",
    marginBottom: "1rem",
    maxWidth: isMobile ? "320px" : "480px",
    display: "inline-block",
    wordWrap: "break-word"
  };

  let justify = "flex-start";
  let avatar = "";

  if (props.chat.by === "cust") {
    style.backgroundColor = "#ececec";
  } else {
    style.backgroundColor = "#ceb4c1";
    justify = "flex-end";

    if (props.chat.uid) {
      avatar = (
        <img
          style={{ width: "32px", height: "32px", borderRadius: "16px", marginRight: "5px" }}
          src={`https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${props.chat.uid}.webp`}
        />
      );
    } else if (props.chat.by === "bkst") {
      avatar = <img style={{ width: "32px", height: "32px", borderRadius: "16px", marginRight: "5px" }} src={bakeStreetSquareLogo} />;
    }
  }

  return (
    <Box style={{ display: "flex", justifyContent: justify }}>
      {avatar}
      <Paper elevation={0} style={style}>
        {props.chat.pics &&
          props.chat.pics.map((pic, idx) => {
            return (
              <Box key={idx}>
                <ExpandableImage key={idx} src={pic} style={{ height: "125px", borderRadius: "5px" }} />
              </Box>
            );
          })}
        <Linkify sx={{ color: "red" }}>
          <Typography variant="body2">{props.chat.msg}</Typography>
        </Linkify>
        <Typography variant="caption" color="textSecondary" style={{ float: "right", marginLeft: "2rem", display: "flex", alignItems: "center" }}>
          {moment(props.chat.ts).format("h:mm A")}{" "}
          {chat.status && chat.status !== "delivered" && chat.status !== "completed" && (
            <ErrorIcon style={{ fontSize: "1.3em", color: "#e74c3c", marginLeft: "3px" }} />
          )}
        </Typography>
      </Paper>
    </Box>
  );
}
