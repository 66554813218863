import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

const MIN = 0;
const MAX = 5;
const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);

export default function ReviewRatingBar(props) {
  const value = Number(props.value);
  let color = "#00b894";
  if (value <= 2) {
    color = "#e17055";
  } else if (value === 3) {
    color = "#00cec9";
  }

  return (
    <div
      sx={{
        height: 5,
        borderRadius: 3
      }}
    >
      <LinearProgress
        color="primary"
        variant="determinate"
        value={normalise(Number(props.value))}
        sx={{
          backgroundColor: "#DDD",
          "& .MuiLinearProgress-bar": {
            backgroundColor: color
          }
        }}
      />
    </div>
  );
}
