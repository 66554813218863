import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Select,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/component/api";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import Images from "../../common/component/Images";
import { QUOTE_TAGS } from "../../common/component/constants";
import { Link as RouterLink } from "react-router-dom";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import currency from "currency.js";
import RequestHeader from "./RequestHeader";
import AutoQuote from "./AutoQuote";

export default function GenerateAutoQuotesV2(props) {
  const { req } = props;
  const [open, setOpen] = useState(false);

  const [data, setData] = useState("");

  const [productType, setProductType] = useState("Cake");
  const [productSubtype, setProductSubtype] = useState("");

  const [tags, setTags] = useState([]);
  const [specs, setSpecs] = useState([]);

  const [references, setReferences] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      setLoading(true);
      bkstApi
        .post(`/request/${req.id}/quote/model`, {
          quotes: req.quotes.map((q) => {
            return { id: q.id, orig: q.quote, distance: q.baker.distance };
          })
        })
        .then((res) => {
          setData(res.data.data);
          setLoading(false);
        });
    }
  }, [open]);

  let productTypes = [];
  for (const acctId in data) {
    const acctModel = data[acctId].model;
    if (acctModel) {
      productTypes.push(...Object.keys(acctModel));
    }
  }
  productTypes = [...new Set(productTypes)].sort();

  let productSubtypes = [];
  for (const acctId in data) {
    const acctModel = data[acctId].model;
    if (acctModel) {
      const acctModelSubtypes = acctModel[productType]?.type;
      if (acctModelSubtypes) {
        productSubtypes.push(...Object.keys(acctModelSubtypes));
      }
    }
  }
  productSubtypes = [...new Set(productSubtypes)].sort();

  return (
    <Box display="inline">
      <IconButton variant="outlined" onClick={() => setOpen(true)} disabled={props.disabled}>
        <AttachMoneyOutlinedIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="md">
        <DialogTitle>Auto Quotes</DialogTitle>
        <DialogContent>
          {loading ? (
            <LinearProgress />
          ) : (
            <Box>
              <Box my={4}>
                <Paper variant="outlined">
                  <Box p={2}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <RequestHeader request={req} />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="productType-label">Product Type</InputLabel>
                              <Select
                                value={productType}
                                label="Product Type"
                                id="productType"
                                name="productType"
                                onChange={(e) => setProductType(e.target.value)}
                              >
                                {productTypes.map((i) => (
                                  <MenuItem key={i} value={i}>
                                    {i}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="productSubtype-label">Product Subtype</InputLabel>
                              <Select
                                value={productSubtype}
                                label="Product Subtype"
                                id="productSubtype"
                                name="productSubtype"
                                onChange={(e) => setProductSubtype(e.target.value)}
                              >
                                {productSubtypes.map((i) => (
                                  <MenuItem key={i} value={i}>
                                    {i}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          {/* <Grid item sm={12}>
                        <Autocomplete
                          value={specs}
                          onChange={(e, val) => setSpecs(val)}
                          options={[
                            "surface.lowComplexity",
                            "surface.medComplexity",
                            "surface.highComplexity",
                            "figurine.sm",
                            "figurine.md",
                            "figurine.lg",
                            "flowers.fresh",
                            "flowers.silk",
                            "flowers.sugar",
                            "liquorBottle.mini",
                            "spheres.edible",
                            "spheres.nonEdible",
                            "topper.acrylic",
                            "topper.cardstock",
                            "topper.rhinestone"
                          ]}
                          renderInput={(params) => <TextField {...params} variant="outlined" label="Specs" />}
                          disableClearable
                          multiple
                          isOptionEqualToValue={() => false}
                        />
                        </Grid> */}
                          <Grid item xs={12}>
                            <Autocomplete
                              value={tags}
                              onChange={(e, v) => setTags(v)}
                              options={QUOTE_TAGS}
                              filterSelectedOptions
                              renderInput={(params) => <TextField variant="outlined" {...params} label="Tags" />}
                              multiple
                              freeSolo
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Box>
            </Box>
          )}
          {data && req.quotes.length > 0 && (
            <Box>
              {req.quotes.map((q) => {
                return data[q.id].model ? (
                  <AutoQuote
                    key={q.id}
                    req={req}
                    quote={q}
                    data={data[q.id]}
                    productType={productType}
                    productSubtype={productSubtype}
                    specs={specs}
                    tags={tags}
                  />
                ) : (
                  <Box />
                );
              })}
              <Box px={2} py={4}>
                {references.map((o) => {
                  return (
                    <Box mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={3} sm={2}>
                          <Images pics={o.pics} />
                        </Grid>
                        <Grid item xs={6} sm={8}>
                          <Typography variant="h6">
                            {currency(o.quote.quote).format()} {!o.quote.resTs && <AutoAwesomeOutlinedIcon sx={{ color: "#999" }} />}
                          </Typography>
                          <Link key={o.id} underline="none" component={RouterLink} to={`/request/${o.id}`} href="#">
                            <Typography variant="overline" color="textSecondary">
                              {o.title}
                            </Typography>
                          </Link>
                          <Typography variant="caption" display="block">
                            {o.quote.frosting} &middot; {o.quote.shape} {o.quote.size} &middot; {o.quote.servings}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {o.quote.note}
                          </Typography>
                          <Box>{o.quote.tags && o.quote.tags.map((e, idx) => <Chip size="small" label={e} />)}</Box>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => window.location.reload()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
